import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteForever from '@material-ui/icons/DeleteForever';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import Pause from '@material-ui/icons/PauseCircleFilledOutlined';
import Play from '@material-ui/icons/PlayCircleFilledOutlined';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import withStyles from '@material-ui/core/styles/withStyles';
import { LocationOn } from '@material-ui/icons';

import { formatDateTimeForDisplay } from '../../../../constants/DateUtil';
import doctorQueueSchema from './DoctorQueueTableSchema';
import MaterialTable from '../../../../components/MaterialTableV2/MaterialTable';
import {
    apiCatchBlockFunction,
    isArrayValidAndNotEmpty,
    isValidDate,
    reprocessJson,
    isValidFunction,
} from '../../../../constants/CommonUtil';
import API from '../../../../constants/api';
import { errorMessage, successMessage } from '../../../../redux/modules/message/message-actions';
import { displayWarning } from '../../../../redux/modules/warningDialog/warningDialog-actions';
import { checkIfPrivilegeExistsForUser } from '../../../../constants/privilegeChecker';
import { statusOfDoctors } from '../../../QueueNodeDisplayContainer/QueueUtils';
import { hideSpinner, showSpinner } from '../../../../redux/modules/spinner/spinner';
import BlinkText from '../../../../components/BlinkText/BlinkText';
import AdjustQueueTimeForm from './AdjustQueueTimeForm';
import UpdateRoomNumberForm from './UpdateRoomNumberForm';
import { getStringFromObject } from '../../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../../constants/nullCheckUtils';

const styles = theme => ({
    header: {
        background: theme.palette.primaryBackground,
    },
    details: {
        background: '#ffffff',
    },
    summary: {
        color: '#ffffff',
    },
});

class DoctorQueueTableDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: true,
            showAdjustQueueTimeDialog: false,
            showRoomNumberDialog: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.forceExpand !== this.props.forceExpand) {
            this.onExpand(null, true);
        }
    }

    onPauseOrPlayQueue = async () => {
        const {
            queue,
            dispatch,
            fetchDoctorQueue,
        } = this.props;
        let newDoctorStatus = statusOfDoctors.IN;
        if (queue.doctorStatus === statusOfDoctors.IN) {
            newDoctorStatus = statusOfDoctors.OUT;
        }
        try {
            dispatch(showSpinner());
            await axios.get(`${API.QUEUES.CHANGE_DOCTOR_STATUS}${queue.uuid}&doctorStatus=${newDoctorStatus}`);
            dispatch(hideSpinner());
            dispatch(
                successMessage(`Queue ${newDoctorStatus === statusOfDoctors.OUT ? 'Paused' : 'Resumed'} successfully`),
            );
            fetchDoctorQueue();
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
    };

    onDeleteQueue = async (event) => {
        try {
            event.stopPropagation();
            const {
                dispatch,
                queue: { uuid, nodes },
            } = this.props;
            let canDelete = true;
            if (isArrayValidAndNotEmpty(nodes)) {
                const filteredNodes = nodes.filter(aNode => (
                    (!['UNUSED', 'NOT_ALLOCATED'].includes(aNode.consultationStatus))
                ));
                if (isArrayValidAndNotEmpty(filteredNodes)) {
                    canDelete = false;
                }
            }
            if (!canDelete) {
                dispatch(errorMessage('Queue cannot be deleted as there are used tokens'));
                return;
            }
            dispatch(
                displayWarning(
                    'Are you sure that you want to delete this queue? This operation is irreversable.',
                    async () => {
                        try {
                            await axios.get(`${API.QUEUES.DELETE_QUEUE}${uuid}`);
                            this.props.fetchDoctorQueue();
                        } catch (e2) {
                            apiCatchBlockFunction(e2, dispatch);
                        }
                    },
                ),
            );
        } catch (e) {
            apiCatchBlockFunction(e, this.props.dispatch);
        }
    };

    onExpand = (e, expanded) => {
        this.setState({
            expanded,
        });
    };

    toggleAdjustQueueTimeDialog = () => {
        this.setState(prevState => ({
            showAdjustQueueTimeDialog: !prevState.showAdjustQueueTimeDialog,
        }));
    }

    adjustQueueTimeClick = (event) => {
        if (isValidFunction(event.stopPropagation)) {
            event.stopPropagation();
        }
        this.toggleAdjustQueueTimeDialog();
    }

    toggleShowRoomNumberDialog = () => {
        this.setState(prevState => ({
            showRoomNumberDialog: !prevState.showRoomNumberDialog,
        }));
    }

    openRoomNumberDialog = (event) => {
        if (isValidFunction(event.stopPropagation)) {
            event.stopPropagation();
        }
        this.toggleShowRoomNumberDialog();
    }

    render() {
        const {
            expanded,
            showAdjustQueueTimeDialog,
            showRoomNumberDialog,
        } = this.state;
        const {
            classes,
            queue,
            frontDeskSupervisorPrivilege,
            loading,
            onRowButtonClick,
            index,
            tableSchema,
            recentQueue,
            onRowClick,
            appConfiguration,
            colorSchemaForOPDQueue,
            fetchDoctorQueue,
            queueExtendPrivilege,
            updateRoomNumberForQueuePrivilege,
        } = this.props;
        const {
            endTime,
            roomNumber,
            floorNumber,
        } = queue;
        let showPausePlayButton = false;
        if (
            isValidDate(new Date(endTime)) &&
            checkIfPrivilegeExistsForUser([appConfiguration.pauseQueuePrivilege])
        ) {
            // console.log('asdjas0djas', isDateBefore(new Date(endTime), new Date()));
            // showPausePlayButton = !isDateBefore(new Date(endTime), new Date());
            showPausePlayButton = true;
        }
        console.log('asoasd-0asd-0sadi', endTime, showPausePlayButton, new Date(endTime));
        const nodes = getStringFromObject('nodes', queue) || [];
        const schemaOfTable = isObjectValidAndNotEmpty(tableSchema) ?
            tableSchema :
            reprocessJson(appConfiguration, doctorQueueSchema(frontDeskSupervisorPrivilege));
        const tableEl = (
            <MaterialTable
                // eslint-disable-next-line
                key={queue.startTime}
                schema={schemaOfTable}
                uniqueKey="tokenNumber"
                totalElements={nodes.length}
                noPagination
                handleRowButtonClick={onRowButtonClick}
                data={nodes}
                isLoading={loading}
                colorSchemaForOPDQueue={colorSchemaForOPDQueue}
            />
        );
        const queueUuid = getStringFromObject('uuid', queue);
        if (loading) {
            return tableEl;
        }
        return (
            <React.Fragment>
                <ExpansionPanel className={classes.header} expanded={expanded} onChange={this.onExpand}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon test-id="expand-icon" />}
                        classes={{
                            expanded: classes.summary,
                            content: classes.summary,
                        }}
                    >
                        <Grid container spacing={16}>
                            <Grid item sm={7} md={7} lg={7} className="flexChildren">
                                {formatDateTimeForDisplay(new Date(queue.startTime))} &nbsp; - &nbsp;
                                {formatDateTimeForDisplay(new Date(queue.endTime))}
                                {
                                    showPausePlayButton &&
                                    <Tooltip
                                        title={`${queue.doctorStatus === statusOfDoctors.IN ? 'Pause' : 'Resume'} Queue`}
                                        onClick={this.onPauseOrPlayQueue}
                                        className="cursor-pointer ml-1"
                                    >
                                        {
                                            queue.doctorStatus === statusOfDoctors.IN ?
                                                <Pause /> :
                                                <Play />
                                        }
                                    </Tooltip>
                                }
                            </Grid>
                            <Grid item sm={2} md={2} lg={2} className="flexChildren">
                                {
                                    queue.doctorStatus === statusOfDoctors.OUT &&
                                    <BlinkText
                                        text="Queue Paused"
                                        style={{ color: '#FFEA00', fontWeight: 550, fontSize: '1rem' }}
                                    />
                                }
                            </Grid>
                            <Grid item sm={3} md={3} lg={3} className="flexChildren justify-content-right">
                                <div className="mr-1">
                                    {
                                        checkIfPrivilegeExistsForUser(updateRoomNumberForQueuePrivilege) &&
                                        !recentQueue &&
                                        <Tooltip title="Update Room number">
                                            <LocationOn
                                                test-id={`update-room-number-${index}`}
                                                onClick={this.openRoomNumberDialog}
                                            />
                                        </Tooltip>
                                    }
                                </div>
                                <div className="mr-1">
                                    {
                                        checkIfPrivilegeExistsForUser([queueExtendPrivilege]) &&
                                        !recentQueue &&
                                        <Tooltip title="Adjust Timing">
                                            <BuildRoundedIcon
                                                test-id={`adjust-timing-queue-${index}`}
                                                onClick={this.adjustQueueTimeClick}
                                            />
                                        </Tooltip>
                                    }
                                </div>
                                {
                                    checkIfPrivilegeExistsForUser([frontDeskSupervisorPrivilege]) &&
                                !recentQueue &&
                                <DeleteForever test-id={`delete-queue-${index}`} onClick={this.onDeleteQueue} />
                                }
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.details}>
                        <MaterialTable
                        // eslint-disable-next-line
                            key={queue.startTime}
                            schema={schemaOfTable}
                            uniqueKey="tokenNumber"
                            totalElements={queue.nodes.length}
                            noPagination
                            onRowClick={onRowClick}
                            handleRowButtonClick={onRowButtonClick}
                            data={queue.nodes}
                            isLoading={loading}
                            colorSchemaForOPDQueue={colorSchemaForOPDQueue}
                            recentQueue={recentQueue}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {
                    showAdjustQueueTimeDialog && queueUuid && (
                        <AdjustQueueTimeForm
                            onClose={this.toggleAdjustQueueTimeDialog}
                            queueUuid={queueUuid}
                            fetchDoctorQueue={fetchDoctorQueue}
                        />
                    )
                }
                {
                    showRoomNumberDialog && queueUuid && (
                        <UpdateRoomNumberForm
                            onClose={this.toggleShowRoomNumberDialog}
                            queueUuid={queueUuid}
                            roomNumber={roomNumber}
                            floorNumber={floorNumber}
                            fetchDoctorQueue={fetchDoctorQueue}
                        />
                    )
                }
            </React.Fragment>
        );
    }
}

DoctorQueueTableDisplay.propTypes = {
    classes: PropTypes.object,
    queue: PropTypes.object,
    tableSchema: PropTypes.object,
    appConfiguration: PropTypes.object,
    fetchDoctorQueue: PropTypes.func,
    onRowClick: PropTypes.func,
    dispatch: PropTypes.func,
    frontDeskSupervisorPrivilege: PropTypes.string,
    loading: PropTypes.bool,
    forceExpand: PropTypes.bool,
    index: PropTypes.number,
    recentQueue: PropTypes.bool,
    onRowButtonClick: PropTypes.func.isRequired,
    colorSchemaForOPDQueue: PropTypes.object,
    queueExtendPrivilege: PropTypes.string,
    updateRoomNumberForQueuePrivilege: PropTypes.array,
};

DoctorQueueTableDisplay.defaultProps = {
    classes: {},
    queue: {},
    tableSchema: {},
    appConfiguration: {},
    fetchDoctorQueue: () => {},
    onRowClick: () => {},
    dispatch: () => {},
    frontDeskSupervisorPrivilege: '',
    loading: false,
    forceExpand: false,
    index: null,
    recentQueue: false,
    colorSchemaForOPDQueue: {},
    queueExtendPrivilege: '',
    updateRoomNumberForQueuePrivilege: [],
};

const mapStateToProps = state => ({
    appConfiguration: state.appConfiguration,
    colorSchemaForOPDQueue: getStringFromObject('appConfiguration.colorSchemaForDoctorsOPDQueueRow', state, []),
    queueExtendPrivilege: getStringFromObject('appConfiguration.queueExtendPrivilege', state),
    updateRoomNumberForQueuePrivilege: getStringFromObject('appConfiguration.updateRoomNumberForQueuePrivilege', state),
});

export default withStyles(styles)(connect(mapStateToProps)(DoctorQueueTableDisplay));

