import React from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/v4';
import deepEqual from 'react-fast-compare';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import MaterialTableHead from './MaterialTableHead';
import MaterialTableRow from './MaterialTableRow';
import { PAGE, ROWS_PER_PAGE, SIZE } from '../../constants/constants';
import {
    getLoadingSkeletonsForTable, isValidFunction,
} from '../../constants/CommonUtil';
import SeekableTablePagination from './CustomTablePagination/SeekableTablePagination';
import { getStringFromObject } from '../../constants/lodashUtils';

function desc(a, b, orderBy) {
    if (getStringFromObject(orderBy, b) < getStringFromObject(orderBy, a)) {
        return -1;
    }
    if (getStringFromObject(orderBy, a) < getStringFromObject(orderBy, b)) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const styles = () => ({
    root: {
        width: '100%',
    },
    table: {},
});

class MaterialTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            order: props.sortOrder || 'asc',
            orderBy: props.sortCol || '',
            selected: [],
            expandedRowIndex: null,
            pge: this.props.page,
            sze: this.props.size,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (!deepEqual(this.props.data, nextProps.data) && !nextProps.keepExpandedOnRowValueChange) {
            this.setState({
                expandedRowIndex: null,
            });
        }
        console.log('jkhlkjdfahlkjsdhfakjsf', this.props, nextProps);
        if (!deepEqual(nextProps.lastUpdated, this.props.lastUpdated)) {
            this.setState({ selected: [] });
        }
        if (!deepEqual(nextProps.selectedRows, this.props.selectedRows)) {
            this.setState({ selected: nextProps.selectedRows });
        }
        if (!(
            deepEqual(nextProps.sortCol, this.props.sortCol) || deepEqual(nextProps.sortOrder, this.props.sortOrder)
        )) {
            this.setState({ order: nextProps.sortOrder, orderBy: nextProps.sortCol });
        }
        if (!deepEqual(nextProps.page, this.props.page) || !deepEqual(nextProps.size, this.props.size)) {
            this.setState({ pge: nextProps.page, sze: nextProps.size });
        }
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        const { onSelect } = this.props;
        onSelect([], []);
        this.setState({ selected: [] });
        let order = 'desc';
        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        this.setState({ order, orderBy });
        console.log('sad0ap9djoasdad', event, property);
        this.props.handleSort(order, orderBy);
    };

    handleSelectAllClick = (event) => {
        const {
            onSelect,
            data,
            schema,
            uniqueKey,
        } = this.props;
        if (schema.hideCheckBoxIcon) {
            return;
        }
        let selected = [];
        let dataSelected = [];
        if (event.target.checked) {
            selected = data.map((n, index) => index);
            dataSelected = data.map(n => getStringFromObject(uniqueKey, n, null));
        }
        this.setState({ selected }, () => onSelect(selected, dataSelected));
    };

    handleClick = (event, id, data) => {
        console.log('asd-9asids0ad', id, data);
        const {
            onSelect, schema,
        } = this.props;
        const selected = [...this.state.selected];
        const orgIndex = id;
        const selectedIndex = selected.indexOf(orgIndex);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, orgIndex);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        if (schema.hideCheckBoxIcon) {
            onSelect(newSelected);
            return;
        }
        this.setState({
            selected: newSelected,
        }, () => {
            console.log('asd-0au-d0apsjodasdd', newSelected, data);
            onSelect(newSelected, data);
        });
    };

    handleRowClick = (event, id, uniqueUuid, rowValue) => {
        const {
            onRowClick,
        } = this.props;
        if (isValidFunction(onRowClick)) {
            onRowClick(event, id, uniqueUuid, rowValue);
        }
        if (this.props.isExpandable) {
            this.handleRowExpand(id, this.handleRowExpand);
        }
    };

    handleChangePage = (event, page) => {
        const { onPageChange } = this.props;
        this.setState({ pge: page });
        const { sze } = this.state;
        onPageChange(page, sze);
    };

    handleChangeRowsPerPage = (event) => {
        const { pge } = this.state;
        this.setState({ sze: event.target.value });
        this.props.onPageChange(pge, event.target.value);
    };

    isSelected = (id) => {
        const { selected } = this.state;
        console.log('asd9a-s9uda-s0d', id, selected);
        const orgIndex = id;
        return selected.indexOf(orgIndex) !== -1;
    };

    handleRowExpand = (index) => {
        console.log('asdoadlmkada,. aasdasd', index);
        this.setState(prevState => ({
            expandedRowIndex: prevState.expandedRowIndex === index ? null : index,
        }), () => {
            this.props.onRowExpand(this.state.expandedRowIndex);
        });
    };

    render() {
        const {
            classes,
            schema,
            tableStyle,
            uniqueKey,
            errorIcon,
            warningIcon,
            rowsPerPageOptions,
            data,
            page,
            size,
            totalElements,
            isExpandable,
            noHeader,
            handleIconAction,
            getExpandableComponent,
            noPagination,
            paperStyle,
            expandOnRowClick,
            alwaysExpand,
            getExtraRows,
            handleSelectAction,
            retired,
            retiredValue,
            isLoading,
            handleRowButtonClick,
            retiredColor,
            first,
            last,
            seekablePagination,
            colorSchemaForOPDQueue,
            stylizedHeaderColor,
            renderTotalRows,
            rowColorPickerProperty,
            formularyItemTypeKeyColorsMap,
            getRowStyle,
            recentQueue,
        } = this.props;

        const {
            order,
            orderBy,
            pge,
            sze,
            selected,
            expandedRowIndex,
        } = this.state;
        let headerRowStyle = { background: '#fafafa' };
        if (schema.headerRowStyle) {
            headerRowStyle = { ...schema.headerRowStyle };
        }
        const headerColStyle = { color: '#000', fontSize: '14px' };
        const emptyRows = size - Math.min(size, (data.length - page) * size);
        const tableCells = schema.tableCells || [];
        const paginationCompProps = {
            rowsPerPageOptions,
            component: 'div',
            count: totalElements,
            rowsPerPage: sze,
            page: pge,
            backIconButtonProps: {
                'aria-label': 'Previous Page',
            },
            nextIconButtonProps: {
                'aria-label': 'Next Page',
            },
            onChangePage: this.handleChangePage,
            onChangeRowsPerPage: this.handleChangeRowsPerPage,
            first,
            last,
        };
        const tableName = schema.name;
        console.log('data>>', data, stableSort(data, getSorting(order, orderBy)));
        return (
            <Paper className={classes.root} style={{ ...paperStyle }}>
                <div className={classes.tableWrapper}>
                    <Table
                        className={classes.table}
                        style={{ ...tableStyle, overflowY: 'none' }}
                        aria-labelledby="tableTitle"
                    >
                        {
                            (!noHeader) &&
                            <MaterialTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={this.handleSelectAllClick}
                                onRequestSort={this.handleRequestSort}
                                rowCount={data.length}
                                tableCells={tableCells}
                                hideCheckBoxIcon={schema.hideCheckBoxIcon || false}
                                headerRowStyle={headerRowStyle || {}}
                                headerColStyle={headerColStyle || {}}
                                isExpandable={isExpandable}
                                tableName={tableName}
                                stylizedHeaderColor={stylizedHeaderColor}
                            />
                        }
                        <TableBody style={{ cursor: 'pointer' }}>
                            {
                                isLoading &&
                                getLoadingSkeletonsForTable(size, tableCells.length, schema.name)
                            }
                            { !isLoading && stableSort(data, getSorting(order, orderBy))
                                .map((n, index) => {
                                    const isSelected = this.isSelected(index);
                                    return (
                                        <MaterialTableRow
                                            uniqueKey={uniqueKey}
                                            schema={schema}
                                            key={getStringFromObject(uniqueKey, n) || uuidv4()}
                                            tableCells={schema.tableCells}
                                            handleSelectAction={handleSelectAction}
                                            index={index}
                                            rowStyle={schema.rowStyle}
                                            emphasizeRow={schema.emphasizeIf}
                                            errorIcon={errorIcon}
                                            warningIcon={warningIcon}
                                            onRowClick={this.handleRowClick}
                                            onCheckBoxClick={this.handleClick}
                                            handleIconAction={handleIconAction}
                                            handleRowExpand={this.handleRowExpand}
                                            expandedRowIndex={expandedRowIndex}
                                            data={n}
                                            getExtraRows={getExtraRows}
                                            getExpandableComponent={getExpandableComponent}
                                            isExpandable={isExpandable}
                                            hideCheckBoxIcon={schema.hideCheckBoxIcon}
                                            isSelected={isSelected}
                                            expandOnRowClick={expandOnRowClick}
                                            retired={retired}
                                            retiredValue={retiredValue}
                                            retiredColor={retiredColor}
                                            alwaysExpand={alwaysExpand}
                                            handleRowButtonClick={handleRowButtonClick}
                                            tableName={tableName}
                                            colorSchemaForOPDQueue={colorSchemaForOPDQueue}
                                            rowColorPickerProperty={rowColorPickerProperty}
                                            formularyItemTypeKeyColorsMap={formularyItemTypeKeyColorsMap}
                                            getRowStyle={getRowStyle}
                                            recentQueue={recentQueue}
                                        />
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow id={`${schema.id}-empty-row`} style={{ height: 49 }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                            {
                                isValidFunction(renderTotalRows) && renderTotalRows()
                            }
                        </TableBody>
                    </Table>
                </div>
                {
                    (!noPagination) &&
                    (
                        seekablePagination ?
                            <SeekableTablePagination
                                {...paginationCompProps}
                                count={Number.POSITIVE_INFINITY}
                            /> :
                            <TablePagination
                                {...paginationCompProps}
                            />
                    )
                }
            </Paper>
        );
    }
}

MaterialTable.propTypes = {
    classes: PropTypes.object.isRequired,
    schema: PropTypes.object,
    onPageChange: PropTypes.func,
    selectedRows: PropTypes.array,
    handleIconAction: PropTypes.func,
    onSelect: PropTypes.func,
    data: PropTypes.array,
    tableStyle: PropTypes.object,
    paperStyle: PropTypes.object,
    onRowClick: PropTypes.func,
    handleSort: PropTypes.func,
    handleRowButtonClick: PropTypes.func,
    uniqueKey: PropTypes.string,
    errorIcon: PropTypes.node,
    warningIcon: PropTypes.node,
    rowsPerPageOptions: PropTypes.array,
    page: PropTypes.number,
    size: PropTypes.number,
    totalElements: PropTypes.number,
    lastUpdated: PropTypes.instanceOf(Date),
    getExpandableComponent: PropTypes.func,
    getExtraRows: PropTypes.func,
    onRowExpand: PropTypes.func,
    isExpandable: PropTypes.bool,
    noHeader: PropTypes.bool,
    noPagination: PropTypes.bool,
    expandOnRowClick: PropTypes.bool,
    handleSelectAction: PropTypes.func,
    retired: PropTypes.string,
    retiredValue: PropTypes.bool,
    alwaysExpand: PropTypes.bool,
    // for pagination
    first: PropTypes.bool,
    last: PropTypes.bool,
    retiredColor: PropTypes.string,
    // if the data is being loaded, we can show a skeleton loader for better ux
    isLoading: PropTypes.bool,
    seekablePagination: PropTypes.bool,
    keepExpandedOnRowValueChange: PropTypes.bool,
    sortOrder: PropTypes.string,
    sortCol: PropTypes.string,
    stylizedHeaderColor: PropTypes.string,
    rowColorPickerProperty: PropTypes.string,
    colorSchemaForOPDQueue: PropTypes.object,
    renderTotalRows: PropTypes.func,
    formularyItemTypeKeyColorsMap: PropTypes.object,
    getRowStyle: PropTypes.func,
    recentQueue: PropTypes.bool,
};

MaterialTable.defaultProps = {
    schema: {},
    onPageChange: () => {},
    onRowExpand: () => {},
    onSelect: () => {},
    handleSort: () => {},
    data: [],
    selectedRows: [],
    page: PAGE,
    size: SIZE,
    totalElements: 0,
    rowsPerPageOptions: ROWS_PER_PAGE,
    uniqueKey: 'uuid',
    tableStyle: {
        // minWidth: '1020px',
    },
    handleIconAction: () => {},
    handleSelectAction: () => {},
    onRowClick: () => {},
    handleRowButtonClick: () => {},
    errorIcon: <React.Fragment />,
    warningIcon: <React.Fragment />,
    getExpandableComponent: null,
    getExtraRows: () => null,
    isExpandable: false,
    noHeader: false,
    noPagination: false,
    expandOnRowClick: false,
    lastUpdated: null,
    paperStyle: {},
    retired: null,
    retiredValue: null,
    alwaysExpand: false,
    retiredColor: '#ff7d7d',
    isLoading: false,
    // for pagination
    first: false,
    last: false,
    keepExpandedOnRowValueChange: false,
    seekablePagination: false,
    sortOrder: '',
    sortCol: '',
    stylizedHeaderColor: '',
    // used in claim listing to show rows in different color based on a property in the row data
    rowColorPickerProperty: '',
    colorSchemaForOPDQueue: {},
    renderTotalRows: null,
    formularyItemTypeKeyColorsMap: {},
    getRowStyle: null,
    recentQueue: false,
};

export default withStyles(styles)(MaterialTable);
