import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import {
    CORPORATE_SALES_DELIVERY_PRIVILEGE,
    CORPORATE_SALES_PURCHASE_ORDER_PRIVILEGE,
    CORPORATE_SALES_QUOTATION_PRIVILEGE,
    corporateSalesDelivery,
    corporateSalesPurchaseOrder,
    corporateSalesQuotation,
    corporateSalesAccountInvoice,
    CORPORATE_SALES_ACCOUNT_INVOICE_PRIVILEGE,
} from '../../constants/constants';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import { regexMatcher } from '../../constants/StringSimilarity';
import { getStringFromObject } from '../../constants/lodashUtils';

const style = (theme) => ({
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '2px 6px',
    },
    appBar: {
        backgroundColor: 'white',
        width: '100%',
        minHeight: '4rem',
        boxShadow: 'none',
    },
    tabsIndicator: {
        background: 'linear-gradient(to right, #3A1AE4, #2FBDCC)',
    },
    tabsRoot: {
        borderBottom: '1px solid #40a9ff',
        color: '#7E7E7E',
    },
    tabRoot: {
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#1890ff',
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    tabSelected: {},
    label: {
        fontSize: '0.7rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        },
    },
});

const corporateSalesTabs = {
    QUOTATION: {
        value: 'quotation',
        label: 'Quotation',
        privilege: [CORPORATE_SALES_QUOTATION_PRIVILEGE],
    },
    PURCHASE_ORDER: {
        value: 'purchaseOrder',
        label: 'Purchase Order',
        privilege: [CORPORATE_SALES_PURCHASE_ORDER_PRIVILEGE],
    },
    DELIVERY_NOTES: {
        value: 'deliveryNotes',
        label: 'Delivery',
        privilege: [CORPORATE_SALES_DELIVERY_PRIVILEGE],
    },
    ACCOUNT_INVOICE: {
        value: 'accountInvoice',
        label: 'Account Invoice',
        privilege: [CORPORATE_SALES_ACCOUNT_INVOICE_PRIVILEGE],
    },
};

class CorporateSalesContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: this.changeTabForRoute(props),
        };
    }

    changeTabForRoute = (props) => {
        const route = getStringFromObject('history.location.pathname', props);
        const targetPatternObject = {
            '^/corporate-sales/quotation/.*$': 'quotation',
            '^/corporate-sales/delivery/.*$': 'delivery',
            '^/corporate-sales/purchase-order/.*$': 'purchase-order',
            '^/corporate-sales/account-invoice/.*$': 'accountInvoice',
        };
        const defaultBestMatch = regexMatcher(route, targetPatternObject);
        const defaultBestMatchTab = defaultBestMatch || corporateSalesTabs.QUOTATION.value;
        switch (route) {
            case corporateSalesQuotation: return corporateSalesTabs.QUOTATION.value;
            case corporateSalesDelivery: return corporateSalesTabs.DELIVERY_NOTES.value;
            case corporateSalesPurchaseOrder: return corporateSalesTabs.PURCHASE_ORDER.value;
            case corporateSalesAccountInvoice: return corporateSalesTabs.ACCOUNT_INVOICE.value;
            default: return defaultBestMatchTab;
        }
    };

    changeRouteForSelectedTab = (selected) => {
        let route = '';
        switch (selected) {
            case corporateSalesTabs.QUOTATION.value:
                route = corporateSalesQuotation;
                break;
            case corporateSalesTabs.DELIVERY_NOTES.value:
                route = corporateSalesDelivery;
                break;
            case corporateSalesTabs.PURCHASE_ORDER.value:
                route = corporateSalesPurchaseOrder;
                break;
            case corporateSalesTabs.ACCOUNT_INVOICE.value:
                route = corporateSalesAccountInvoice;
                break;
            default:
        }
        if (route) {
            this.props.history.push(route);
        }
    };

    handleChange = (event, selectedTab) => {
        this.setState({
            selectedTab,
        }, () => this.changeRouteForSelectedTab(selectedTab));
    };

    render() {
        const { selectedTab } = this.state;
        const { classes, children } = this.props;
        console.log('TestIsHere', selectedTab);
        return (
            <div style={{ background: '#fff', padding: '2rem' }}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item lg={8} md={8} sm={8}>
                        <AppBar position="static" className={classes.appBar}>
                            <Tabs
                                value={selectedTab}
                                onChange={this.handleChange}
                                fullWidth
                                classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                            >
                                {
                                    Object.values(corporateSalesTabs).map((aCorporateSalesTab) => (
                                        checkIfPrivilegeExistsForUser(aCorporateSalesTab.privilege)
                                        && (
                                            <Tab
                                                test-id={aCorporateSalesTab.value}
                                                key={aCorporateSalesTab.value}
                                                value={aCorporateSalesTab.value}
                                                label={aCorporateSalesTab.label}
                                                classes={{
                                                    root: classes.tabRoot,
                                                    selected: classes.tabSelected,
                                                    label: classes.label,
                                                }}
                                                disableRipple
                                            />
                                        )
                                    ))
                                }
                            </Tabs>
                        </AppBar>
                    </Grid>
                </Grid>
                {children}
            </div>
        );
    }
}

CorporateSalesContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    history: PropTypes.object.isRequired,
};

CorporateSalesContainer.defaultProps = {};

export default withStyles(style)(CorporateSalesContainer);
