import React, { Suspense } from 'react';
import { Route } from 'react-router';
import {
    corporateSalesDelivery,
    corporateSalesPurchaseOrder,
    corporateSalesQuotation,
    corporateSalesAccountInvoice,
} from '../constants/constants';
import PageLoader from '../components/PageLoader/PageLoader';
import CorporateSalesContainer from '../containers/CorporateSalesContainer/CorporateSalesContainer';

const CorporateSalesQuotationListPage = React.lazy(
    () => import('../pages/CorporateSalesQuotationListPage/CorporateSalesQuotationListPage'),
);

const CorporateSalesPurchaseOrder = React.lazy(
    () => import('../pages/CorporateSalesPurchaseOrderListPage/CorporateSalesPurchaseOrderListPage'),
);
const CorporateSalesDeliveryNotesListPage = React.lazy(
    () => import('../pages/CorporateSalesDeliveryNotesListPage/CorporateSalesDeliveryNotesListPage '),
);
const CorporateSalesAccountInvoiceListPage = React.lazy(
    () => import('../pages/CorporateSalesAccountInvoiceListPage/CorporateSalesAccountInvoiceListPage')
)
const CorporateSalesRoutes = (props) => (
    <CorporateSalesContainer {...props}>
        <Suspense fallback={<PageLoader />}>
            <Route path={corporateSalesQuotation} exact component={CorporateSalesQuotationListPage} />
            <Route exact path={corporateSalesPurchaseOrder} component={CorporateSalesPurchaseOrder} />
            <Route exact path={corporateSalesDelivery} component={CorporateSalesDeliveryNotesListPage} />
            <Route exact path={corporateSalesAccountInvoice} component={CorporateSalesAccountInvoiceListPage} />
        </Suspense>
    </CorporateSalesContainer>
);

export default CorporateSalesRoutes;
