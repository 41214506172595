/**
 * Created by vinay on 30/3/19.
 */

import cloneDeep from 'clone-deep';
import { isArrayValidAndNotEmpty, sortArrayOfObjectsByFieldValue } from '../constants/CommonUtil';
import { formatDate, getDateInYYYYMMDDFormat } from '../constants/DateUtil';
import { getStringFromObject } from '../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../constants/nullCheckUtils';

export const ACCOUNT_MOVE_STATE = {
    DRAFT: 'draft',
    POSTED: 'posted',
};

const ACCOUNT_MOVE_LINE_UI_OBJECT = {
    account: null, // uuid, string
    partner: null, // uuid, string
    credit: 0,
    debit: 0,
    type: '',
    narration: '',
    name: '',
};

export const ACCOUNT_MOVE_UI_OBJECT = {
    uuid: '',
    name: '',
    state: '',
    date: '',
    narration: '',
    journal: null,
    totalAmount: 0,
    moveLineDtos: [cloneDeep(ACCOUNT_MOVE_LINE_UI_OBJECT)],
};

export const getUiObjectFromAccountMove = (move) => {
    console.log('dkjasgdfajlgsdfjasdgf', move);
    const uiObject = cloneDeep(ACCOUNT_MOVE_UI_OBJECT);
    if (isObjectValidAndNotEmpty(move)) {
        uiObject.uuid = getStringFromObject('uuid', move);
        // backend does not send sub company details
        // uiObject.subCompany = {
        //     key: getStringFromObject('subCompany', move),
        //     value: getStringFromObject('subCompany', move),
        // };
        uiObject.name = getStringFromObject('name', move);
        uiObject.state = getStringFromObject('state', move);
        const dateInMillis = getStringFromObject('date', move, new Date());
        console.log('ashldjfhasjkldfh', dateInMillis);
        if (dateInMillis) {
            uiObject.date = formatDate(dateInMillis, 'yyyy-MM-dd');
        } else uiObject.date = getDateInYYYYMMDDFormat(new Date());
        uiObject.narration = getStringFromObject('narration', move);
        uiObject.journal = {
            id: getStringFromObject('journal.key', move, null),
            name: getStringFromObject('journal.value', move, ''),
        };
        uiObject.partner = {
            uuid: getStringFromObject('partner.key', move, null),
            name: getStringFromObject('partner.value', move, null),
        };
        uiObject.periodStatus = getStringFromObject('periodStatus', move);
        let uiLines = [];
        const moveLines = getStringFromObject('moveLineDtos', move, []);
        if (isArrayValidAndNotEmpty(moveLines)) {
            uiLines = moveLines.map((line, index) => ({
                account: getStringFromObject('account', line, null),
                type: getStringFromObject('accountType', line, null),
                partner: {
                    uuid: getStringFromObject('partner.key', line, null),
                    name: getStringFromObject('partner.value', line, ''),
                },
                credit: getStringFromObject('credit', line, 0),
                bankStatementLineUuid: getStringFromObject('bankStatementLineUuid', line, null),
                debit: getStringFromObject('debit', line, 0),
                narration: getStringFromObject('narration', line),
                name: getStringFromObject('name', line),
                uuid: getStringFromObject('uuid', line),
                sequence: line.sequence || index,
            }));
            uiLines = sortArrayOfObjectsByFieldValue(uiLines, 'sequence');
        }
        uiObject.moveLineDtos = uiLines;
    }
    console.log('dkjasgdfajlgsdfjasdgf', uiObject);
    return uiObject;
};

export const getAccountMoveFromUiObject = (uiObject) => {
    const move = {};
    if (isObjectValidAndNotEmpty(uiObject)) {
        move.uuid = getStringFromObject('uuid', uiObject);
        move.name = getStringFromObject('name', uiObject);
        move.state = ACCOUNT_MOVE_STATE.DRAFT;
        move.subCompany = getStringFromObject('subCompany.key', uiObject) || null;
        move.partner = getStringFromObject('partner.uuid', uiObject) ? {
            key: getStringFromObject('partner.uuid', uiObject) || null,
            value: getStringFromObject('partner.name', uiObject) || null,
        } : null;
        const dateString = getStringFromObject('date', uiObject);
        if (dateString) {
            move.date = new Date(dateString).getTime();
        }
        move.narration = getStringFromObject('narration', uiObject);
        const journalId = getStringFromObject('journal.id', uiObject, null);
        if (journalId) {
            move.journal = {
                key: getStringFromObject('journal.id', uiObject, null),
                value: getStringFromObject('journal.name', uiObject, ''),
            };
        }
        let lines = [];
        const uiLines = getStringFromObject('moveLineDtos', uiObject, []);
        if (isArrayValidAndNotEmpty(uiLines)) {
            lines = uiLines.map((line, index) => ({
                sequence: index + 1,
                account: getStringFromObject('account.key', line) ?
                    getStringFromObject('account', line, null) : null,
                // partner: getStringFromObject('partner.uuid', line) ?
                //     {
                //         key: getStringFromObject('partner.uuid', line, null),
                //         value: getStringFromObject('partner.name', line, ''),
                //     } : null,
                credit: getStringFromObject('credit', line, 0),
                debit: getStringFromObject('debit', line, 0),
                narration: getStringFromObject('narration', line),
                name: getStringFromObject('name', line),
                uuid: getStringFromObject('uuid', line),
            }));
        }
        move.moveLineDtos = lines;
    }
    return move;
};
