import React, { Suspense } from 'react';
import { Route } from 'react-router';
import GenericSettingContainer from '../containers/GenericSettingContainer/GenericSettingContainer';
import { addressDetailsSettings,
    hrDepartmentSettings, hrJobsSettings, shopAddressSettings } from '../constants/constants';
import PageLoader from '../components/PageLoader/PageLoader';


const hrDepartmentSettingsPage = React.lazy(() => import('../pages/OrganisationSettingsPage/HrDepartmentSettingsPage'));
const HrJobsSettingsPage = React.lazy(() => import('../pages/MiscSettingsPages/HrJobsSettingsPage'));
const AddressDetailspage = React.lazy(() => import('../pages/MiscSettingsPages/AddressDetailsPage'));
const ShopAddressPage = React.lazy(() => import('../pages/MiscSettingsPages/ShopAddressPage'));

const OrganisationSettingRoutes = props => (
    <GenericSettingContainer {...props}>
        <Suspense fallback={<PageLoader />}>
            <Route path={hrDepartmentSettings} exact component={hrDepartmentSettingsPage} />
            <Route path={hrJobsSettings} exact component={HrJobsSettingsPage} />
            <Route path={addressDetailsSettings} exact component={AddressDetailspage} />
            <Route path={shopAddressSettings} exact component={ShopAddressPage} />
        </Suspense>
    </GenericSettingContainer>
);

export default OrganisationSettingRoutes;
