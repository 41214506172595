import axios from 'axios';
import {
    apiCatchBlockFunction, getProfileImageUrl,
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../../constants/CommonUtil';
import API from '../../constants/api';
import { diffInDates, formatAMPM, getDateInDDMMMYYYYFormat, getFullAgeString } from '../../constants/DateUtil';
import { hideSpinner, showSpinner } from '../../redux/modules/spinner/spinner';
import { getStringFromObject } from '../../constants/lodashUtils';

const consultationStatuses = {
    WAITING: 'WAITING',
    GOING_ON: 'GOING_ON',
    DONE: 'DONE',
    Open: 'Open',
    All: 'All',
};

export const statusOfDoctors = {
    IN: 'IN',
    OUT: 'OUT',
};

export const patientVisitType = {
    NEW: 'NEW',
    RE_VISIT: 'REVISIT',
    FOLLOWUP: 'FOLLOWUP',
};

export const mapDtoToObject = (queueNode) => {
    let profileImage = getProfileImageUrl({
        birthDate: getStringFromObject('dateOfBirth', queueNode, new Date().getTime()),
        gender: getStringFromObject('gender', queueNode, 'M'),
    });
    if (queueNode.imageName) {
        profileImage = `${API.FILE.DOWNLOAD}${queueNode.imageName}`;
    }
    return {
        ...queueNode,
        patientName: queueNode.patientName,
        idNumber: queueNode.name,
        id: queueNode.id,
        appointTime: queueNode.time ? getDateInDDMMMYYYYFormat(new Date(queueNode.time)) : '',
        age: getFullAgeString(new Date(queueNode.dateOfBirth)),
        checkedInTime: getDateInDDMMMYYYYFormat(new Date(queueNode.checkInTime)),
        waiting: diffInDates(new Date(queueNode.billDate), new Date()),
        gender: queueNode.gender,
        dob: getDateInDDMMMYYYYFormat(new Date(queueNode.dateOfBirth)),
        payer: queueNode.payerType,
        providerUuid: queueNode.providerUuid,
        tokenType: queueNode.tokenType,
        patientUuid: queueNode.patientUuid,
        status: queueNode.slotType,
        patientId: queueNode.patientIdentifier,
        nationalId: queueNode.patientSecondaryIdentifier,
        patientVisitType: patientVisitType[queueNode.patientVisitType],
        profileImage,
        timeSlot: formatAMPM(new Date(queueNode.time)),
        nationality: getStringFromObject('nationality.value', queueNode) || '',
        payerName: getStringFromObject('payerName', queueNode, '') || '',
    };
};

export const filterTableData = (data, searchText) => {
    let val = searchText || '';
    if (isArrayValidAndNotEmpty(data) && val) {
        val = val.trim();
        let exactMatch = false;
        if (val.endsWith('@')) {
            val = val.substr(0, val.length - 1);
            exactMatch = true;
        }
        return data.filter((d) => {
            const nationalId = getStringFromObject('nationalId', d);
            const patientName = getStringFromObject('patientName', d);
            const patientId = getStringFromObject('patientId', d);
            const tokenNumber = `${getStringFromObject('tokenNumber', d)}`;
            if (exactMatch) {
                return nationalId === val || patientName === val || patientId === val || tokenNumber === val;
            }
            const valLower = val.toLowerCase();
            return (nationalId && Boolean(nationalId.toLowerCase().match(valLower))) ||
                (patientName && Boolean(patientName.toLowerCase().match(valLower))) ||
                (patientId && Boolean(patientId.toLowerCase().match(valLower))) ||
                (tokenNumber && Boolean(tokenNumber.toLowerCase().match(valLower)));
        });
    }
    return data;
};

export const getPotentialEpisodeOfCareDetails = async (
    patientUuid,
    appConfiguration,
    dispatcher,
    episodeOfCareUuid = '',
    tokenUuid = '',
    episodeProviderUuid = '',
) => {
    if ((appConfiguration.episodeStrictCheck === true || appConfiguration.episodeStrictCheck === 'true')) {
        const queryParams =
            `?patientUuid=${patientUuid}&episodeUuid=${episodeOfCareUuid || ''}&tokenUuid=${tokenUuid || ''}`;
        const response =
            await axios.get(`${API.EPISODE_OF_CARE.GET_EPISODE_DETAILS_FOR_CONS_CREATION}${queryParams}`);
        const episodeDetails = response.data;
        if (isArrayValidAndNotEmpty(episodeDetails)) {
            console.log('as9dua0sdu0asd', episodeDetails);
            if (episodeDetails.length === 1) {
                return {
                    eocUuid: getStringFromObject('[0].EPISODE_UUID', episodeDetails),
                    epUuid: getStringFromObject('[0].EPISODE_PROVIDER_UUID', episodeDetails),
                };
            }
        }
    }
    return {
        eocUuid: episodeOfCareUuid,
        epUuid: episodeProviderUuid,
    };
};

export const callTokenInQueue = async (
    tokenDetails,
    props,
    postCallTokenHandler,
    fetchDoctorQ = () => {},
    isPastQueue = false,
) => {
    console.log('as09sau09dsd', tokenDetails);
    const msg = {
        providerUuid: (tokenDetails.providerUuid || '').trim(),
        tokenUuid: (tokenDetails.id || '').trim(),
    };
    const {
        dispatch,
        appConfiguration,
    } = props;
    try {
        dispatch(showSpinner());
        if (!isPastQueue) {
            await axios.post(API.QUEUES.CALL_TOKEN, msg);
        }
        const link = appConfiguration.consultationBaseLink;
        const {
            episodeOfCareUuid,
            episodeProviderUuid,
            patientUuid,
            id,
        } = tokenDetails;
        const { eocUuid, epUuid } = await getPotentialEpisodeOfCareDetails(
            patientUuid,
            appConfiguration,
            dispatch,
            episodeOfCareUuid,
            id,
            episodeProviderUuid,
        );
        if (isValidFunction(postCallTokenHandler)) {
            postCallTokenHandler(patientUuid, tokenDetails.id.trim());
        } else {
            const emrLink =
                `${link}/clinical/${patientUuid}/${eocUuid || 'NA'}/${epUuid || 'NA'}/consultations`;
            window.open(emrLink, `${patientUuid}-${eocUuid || 'NA'}-${epUuid || 'NA'}`);
        }
        dispatch(hideSpinner());
        fetchDoctorQ();
    } catch (e) {
        apiCatchBlockFunction(e, dispatch);
    }
};

export default consultationStatuses;
