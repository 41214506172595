import React from 'react';
import PropTypes from 'prop-types';
import deepEqual from 'react-fast-compare';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import classNames from 'classnames';
import Close from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs/Tabs';
import Tab from '@material-ui/core/Tab/Tab';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Switch from '@material-ui/core/Switch/Switch';
import connect from 'react-redux/lib/connect/connect';
import cloneDeep from 'clone-deep';
import axios from 'axios';
import { Field, withFormik } from 'formik';

import {
    apiCatchBlockFunction, getJsonPath,
    isArrayValidAndNotEmpty,
    valueToFixedTwoDigits,
} from '../../../constants/CommonUtil';
import { isEmpty, required } from '../../../constants/FormValidations';
import API from '../../../constants/api';
import {
    ACCOUNT_INVOICE_OPERATION,
    getAccountInvoiceFromUiObject,
    getUiObjectFromAccountInvoiceResponse,
    getUiObjectFromMaterialReceive,
    getUiObjectFromPurchaseOrder,
    INVOICE_LINE_UI_OBJECT,
    INVOICE_UI_OBJECT,
} from '../../../mapper/IssueInvoiceMapper';
import {
    cancelAccountInvoiceRequest,
    clearSelectedAccountInvoice,
    createIssueInvoiceRequest,
    fetchAccountInvoiceById,
    fetchAccountInvoiceByUuid,
    fetchAccountInvoiceForPurchaseOrderRequest,
} from '../../../redux/modules/accountInvoice/accountInvoice-actions';
import { ACTION_HANDLERS } from '../MakeBillDialog/MakeBillHandlers';
import ActionButton from '../../ActionButton/ActionButton';
import issueInvoiceDialogStyles from '../MakeBillDialog/MakeBillDialogStyles';
import getPaymentInvoiceDetailsToPrintMemoize, {
    calculateDueDateFromPaymentTerm,
    getConsolidatedJournalEntriesMemoize,
    getSupplierSubCompany,
    makeBillDialogTabs,
} from '../MakeBillDialog/MakeBillDialogUtil';
import Print from '../../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import { APPLICATION_CONFIG_URL } from '../../../constants/constants';
import { hideSpinner, showSpinner } from '../../../redux/modules/spinner/spinner';
import PaymentDetailsTab from '../MakeBillDialog/PaymentDetailsTab';
import FormikReactSelectMaterial from '../../Formik/FieldComponents/FormikReactSelectMaterial';
import FormikTextField from '../../Formik/FieldComponents/FormikTextField';
import FormikTable from '../../Formik/FormikTable/FormikTable';
import { displayWarning } from '../../../redux/modules/warningDialog/warningDialog-actions';
import { commonGetApiRequest } from '../../../redux/modules/common/common-actions';
import { checkIfPrivilegeExistsForUser } from '../../../constants/privilegeChecker';
import { subCompanies } from '../../../constants/ERPConstants';
import PrintPDF from '../../../containers/RegistrationAppComponents/PrintHTML/PrintPDF';
import { errorMessage } from '../../../redux/modules/message/message-actions';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const formName = 'makeBillForm';

class IssueInvoiceDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            schema: null,
            readOnly: false,
            // flag to trigger invoice print
            print: false,
            // flag to trigger journal entry print
            printJournal: false,
            // contains the data that should be printed in the doc
            journalEntryPrintData: null,
            // selected tab i.e item details/payment details
            selectedTab: makeBillDialogTabs.ITEM_DETAILS,
            cancelInvoice: false,
            selectedInvoice: null,
            enableServices: false,
            invoiceDownloadToken: null,
        };
    }

    componentWillMount() {
        this.reloadComponent();
    }

    componentDidMount() {
        fetch(getJsonPath('/StockManagement/IssueInvoiceItems.json', APPLICATION_CONFIG_URL))
            .then(resp => resp.json())
            .then((json) => {
                this.setState({
                    schema: json,
                });
            })
            .catch((error) => {
                apiCatchBlockFunction(error, this.props.dispatch);
            });
    }

    componentWillReceiveProps(nextProps) {
        console.log('next rosdd123 ', nextProps, this.props);
        if (!deepEqual(nextProps.selectedInvoices, this.props.selectedInvoices)) {
            let readOnly = false;
            let selectedInvoice = null;
            // assuming there will be only one invoice attached with a purchase order
            if (isArrayValidAndNotEmpty(nextProps.selectedInvoices)) {
                const groupedByStatus = {};
                nextProps.selectedInvoices.forEach((ele) => {
                    groupedByStatus[ele.state] = (groupedByStatus[ele.state] || []).concat(ele);
                });
                if (isArrayValidAndNotEmpty(groupedByStatus[ACCOUNT_INVOICE_OPERATION.PAID])) {
                    readOnly = true;
                    selectedInvoice = groupedByStatus[ACCOUNT_INVOICE_OPERATION.PAID][0] || {};
                } else if (isArrayValidAndNotEmpty(groupedByStatus[ACCOUNT_INVOICE_OPERATION.CONFIRM])) {
                    readOnly = true;
                    selectedInvoice = groupedByStatus[ACCOUNT_INVOICE_OPERATION.CONFIRM][0] || {};
                } else if (isArrayValidAndNotEmpty(groupedByStatus[ACCOUNT_INVOICE_OPERATION.DRAFT])) {
                    readOnly = false;
                    selectedInvoice = groupedByStatus[ACCOUNT_INVOICE_OPERATION.DRAFT][0] || {};
                } else if ((nextProps.invoiceId || !isObjectValidAndNotEmpty(nextProps.purchaseOrder))
                    && isArrayValidAndNotEmpty(groupedByStatus.cancel)) {
                    readOnly = true;
                    selectedInvoice = getStringFromObject('cancel[0]', groupedByStatus, null);
                }
            }
            if ((!selectedInvoice) && isObjectValidAndNotEmpty(nextProps.selectedInvoices) &&
                !Array.isArray(nextProps.selectedInvoices)) {
                selectedInvoice = getStringFromObject('selectedInvoices', nextProps, {});
                const state = getStringFromObject('selectedInvoices.state', nextProps);
                if (state === ACCOUNT_INVOICE_OPERATION.CONFIRM) {
                    readOnly = true;
                }
                if (state === 'cancel') {
                    readOnly = true;
                }
                if (state === ACCOUNT_INVOICE_OPERATION.DRAFT) {
                    readOnly = false;
                }
            }
            this.setState({
                readOnly,
                cancelInvoice: false,
                selectedInvoice,
            },
                () => {
                    const { purchaseOrder } = this.props;
                    console.log('asdas0d0980---03-0ui2 - invoice', selectedInvoice);
                    if (isObjectValidAndNotEmpty(selectedInvoice)) {
                        this.initializeFormWithApiResponse(selectedInvoice);
                    } else if (isObjectValidAndNotEmpty(purchaseOrder)) {
                        this.initializeForm(getUiObjectFromPurchaseOrder(purchaseOrder));
                        this.setPaymentTermsFromSupplier(nextProps, purchaseOrder.supplier);
                    }
                });
        }

        if (!deepEqual(nextProps.bundleFormValues, this.props.bundleFormValues) && isObjectValidAndNotEmpty(this.props.bundleFormValues)) {
            this.initializeForm(
                nextProps.bundleFormValues,
            );
        }
    }

    componentDidUpdate(prevProps) {
        const { salesDiscountConfig } = this.props;
        const oldDiscountAcc = getStringFromObject('discountAccount', prevProps.values);
        const newDiscountAcc = getStringFromObject('discountAccount', this.props.values);
        // const oldSupplier = getStringFromObject('supplier', prevProps.values);
        // const newSupplier = getStringFromObject('supplier', this.props.values);
        if (!this.props.materialReceive) {
            if (!deepEqual(oldDiscountAcc, newDiscountAcc) && !isObjectValidAndNotEmpty(newDiscountAcc)) {
                this.props.setFieldValue('discountPer', 0);
                this.props.setFieldValue('discountAmount', 0);
            }
        }
        const oldSupplier = getStringFromObject('supplier', prevProps.values);
        const newSupplier = getStringFromObject('supplier', this.props.values);
        if (!deepEqual(oldSupplier, newSupplier)) {
            this.getSubCompanyOfSupplier(newSupplier);
        }
        const oldSubCompany = getStringFromObject('subCompany', prevProps.values);
        const newSubCompany = getStringFromObject('subCompany', this.props.values);
        if (!deepEqual(oldSubCompany, newSubCompany) && isObjectValidAndNotEmpty(salesDiscountConfig)) {
            const discountHead = { key: salesDiscountConfig[newSubCompany.key], value: '' };
            this.props.setFieldValue('discountAccount', discountHead);
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearSelectedAccountInvoice());
    }

    onPrintJournalEntriesClick = () => {
        this.fetchJournalEntriesAndPrint(false);
    };

    onPrintConsolidatedJournalEntriesClick = () => {
        this.fetchJournalEntriesAndPrint(true);
    };

    onPrintClick = () => {
        this.setState(prevState => ({
            print: !prevState.print,
        }));
    };

    onTabChange = (event, value) => {
        this.setState({ selectedTab: value });
    };

    onPrintInvoiceClick = async () => {
        const { dispatch, values } = this.props;
        const accountInvoiceUuid = getStringFromObject('uuid', values);
        try {
            dispatch(showSpinner());
            const response = await axios.get(`${API.ACCOUNT_INVOICE.GET_ISSUE_INVOICE_PRINT}?invoiceUuid=${accountInvoiceUuid}`);
            dispatch(hideSpinner());
            const failureReason = getStringFromObject('failureReason', response.data);
            if (failureReason) {
                dispatch(errorMessage(failureReason));
                return;
            }
            const downloadToken = getStringFromObject('downloadToken', response.data);
            this.setState({
                invoiceDownloadToken: downloadToken,
            });
        } catch (error) {
            apiCatchBlockFunction(error, dispatch);
        }
    }

    setPaymentTermsFromSupplier = (nextProps, supplier) => {
        const supplierUuid = getStringFromObject('uuid', supplier);
        if (supplierUuid) {
            nextProps.dispatch(commonGetApiRequest(`${API.SUPPLIER.FIND}${supplierUuid}`, {
                successCallback: (partner) => {
                    nextProps.setFieldValue('terms', partner.paymentTerms);
                    this.setDueDateOnPaymentTermChange(partner.paymentTerms, nextProps);
                },
            }));
        }
    };

    setDueDateOnPaymentTermChange = (paymentTerms, nextProps) => {
        const dueDate = calculateDueDateFromPaymentTerm(getStringFromObject('value.key', paymentTerms));
        // nextProps.setFieldValue('dateInvoice', dueDate);
        nextProps.setFieldValue('dueDate', dueDate);
    };

    getSubCompanyOfSupplier = async (supplier) => {
        const { setFieldValue } = this.props;
        const subCompany = await getSupplierSubCompany(supplier, this.props.dispatch);
        setFieldValue('subCompany', {
            key: subCompany,
            value: subCompany,
        });
    };

    fetchJournalEntriesAndPrint = (consolidated = false) => {
        const {
            selectedInvoice,
        } = this.state;
        if (isObjectValidAndNotEmpty(selectedInvoice)) {
            if (isObjectValidAndNotEmpty(selectedInvoice.accountMove)) {
                // account move already fetched.. no need to fetch again.
                this.printJournalEntries(selectedInvoice.accountMove, consolidated);
            } else {
                // not fetched.. fetch it first before proceeding
                this.props.dispatch(showSpinner());
                axios.get(`${API.ACCOUNT_MOVES.GET_ONE}${selectedInvoice.accountMoveUuid}`)
                    .then((response) => {
                        console.log('as-d0iasdps9oajdpasojds', response.data, consolidated);
                        this.props.dispatch(hideSpinner());
                        const copyOfSelectedInvoice = cloneDeep(selectedInvoice);
                        copyOfSelectedInvoice.accountMove = response.data;
                        this.printJournalEntries(copyOfSelectedInvoice.accountMove, consolidated);
                        this.setState({
                            selectedInvoice: copyOfSelectedInvoice,
                        });
                    })
                    .catch((error) => {
                        apiCatchBlockFunction(error, this.props.dispatch);
                    });
            }
        }
    };

    printJournalEntries = (accountMove, consolidated) => {
        let journalEntryPrintData = { ...accountMove, isConsolidated: false };
        if (consolidated) {
            // consolidate the move lines based on account
            journalEntryPrintData = getConsolidatedJournalEntriesMemoize(accountMove);
        }
        this.setState(prevState => ({
            selectedInvoice: { ...prevState.selectedInvoice, accountMove },
            journalEntryPrintData,
            printJournal: !prevState.printJournal,
        }));
    };

    reloadComponent = () => {
        const {
            purchaseOrder,
            dispatch,
            invoiceId, materialReceive,
            values,
        } = this.props;
        console.log('reloadComponentreloadComponent', {
            purchaseOrder, dispatch, invoiceId, materialReceive,
        });
        if (getStringFromObject('uuid', values)) {
            dispatch(fetchAccountInvoiceByUuid(getStringFromObject('uuid', values)));
        }
        if (invoiceId) {
            dispatch(fetchAccountInvoiceById(invoiceId));
        } else if (purchaseOrder.uuid) {
            dispatch(fetchAccountInvoiceForPurchaseOrderRequest(purchaseOrder.uuid));
        }
        if (isObjectValidAndNotEmpty(purchaseOrder)) {
            this.initializeForm(getUiObjectFromPurchaseOrder(purchaseOrder));
            this.setPaymentTermsFromSupplier(this.props, purchaseOrder.supplier);
        }
        if (materialReceive) {
            console.log('materialReceivematerialReceive', materialReceive);
            if (getStringFromObject('invoice.key', materialReceive)) {
                dispatch(fetchAccountInvoiceByUuid(getStringFromObject('invoice.key', materialReceive)));
            } else {
                this.initializeForm(getUiObjectFromMaterialReceive(materialReceive));
            }
        }
    };

    initializeForm = (formValues) => {
        const { resetForm } = this.props;
        resetForm(formValues);
    };

    reInitializeForm = () => {
        this.setState({ readOnly: false }, () => {
            this.initializeForm(cloneDeep(INVOICE_UI_OBJECT));
        });
    };

    initializeFormWithApiResponse = (reponse) => {
        if (isObjectValidAndNotEmpty(reponse)) {
            this.initializeForm(getUiObjectFromAccountInvoiceResponse(reponse));
        }
    };

    handleSave = () => {
        const { setFieldValue, submitForm, values } = this.props;
        const { enableServices } = this.state;
        setFieldValue('state', ACCOUNT_INVOICE_OPERATION.DRAFT);
        const accountInvoiceUuid = getStringFromObject('uuid', values);
        const isServiceInvoice = accountInvoiceUuid && getStringFromObject('isService', values, false);
        setFieldValue('isService', enableServices || isServiceInvoice);
        submitForm();
    };

    handleConfirm = () => {
        const { setFieldValue, submitForm, values } = this.props;
        const { cancelInvoice, enableServices } = this.state;
        setFieldValue('state', ACCOUNT_INVOICE_OPERATION.CONFIRM);
        const accountInvoiceUuid = getStringFromObject('uuid', values);
        const isServiceInvoice = accountInvoiceUuid && getStringFromObject('isService', values, false);
        setFieldValue('isService', enableServices || isServiceInvoice);
        if (cancelInvoice) {
            setFieldValue('cancelInvoice', cancelInvoice);
        }
        submitForm();
    };

    maxDiscount = (min, max) => (value) => {
        if (!isEmpty(value)) {
            const discount = NumberOf(value);
            if (discount < min) {
                return `value must be more than ${min}`;
            } else if (discount > max) {
                return `value must be less than ${max}`;
            }
        }
        return undefined;
    };

    handleChoosePayterm = (payterm) => {
        if (isObjectValidAndNotEmpty(payterm)) {
            this.setDueDateOnPaymentTermChange(payterm, this.props);
        }
    };

    handleClickCancel = () => {
        this.setState({ readOnly: false, cancelInvoice: true });
    };

    handleConfirmCancel = () => {
        const { setFieldValue, submitForm } = this.props;
        setFieldValue('state', ACCOUNT_INVOICE_OPERATION.CONFIRM);
        setFieldValue('cancelInvoice', true);
        setFieldValue('accountInvoiceLines', null);
        submitForm();
    };

    handleCancelInvoiceComplete = () => {
        const { dispatch } = this.props;
        dispatch(displayWarning(
            'Are you sure want to cancel the invoice completely ? This operation can not be undone.',
            this.handleConfirmCancel,
        ));
    };

    handleChangeDiscountPerc = () => {
        const { setFieldValue, values } = this.props;
        console.log('dicountchangesshandleChangeDiscountPerc', values);
        setFieldValue('discountAmount', 0);
    };

    handleChangeDiscountAmount = () => {
        const { setFieldValue, values } = this.props;
        console.log('dicountchangesshandleChangeDiscountAmount', values);

        setFieldValue('discountPer', 0);
    };

    handleToggleServices = (e, enableServices) => {
        const { values, dispatch, setFieldValue } = this.props;
        const lines = getStringFromObject('accountInvoiceLines', values, []);
        if (isArrayValidAndNotEmpty(lines)) {
            dispatch(displayWarning(
                'Any invoice lines added will be cleared !! Are you sure want to continue ?',
                () => {
                    // eslint-disable-next-line max-len
                    this.setState({ enableServices }, () => setFieldValue('accountInvoiceLines', [cloneDeep(INVOICE_LINE_UI_OBJECT)]));
                },
            ));
        } else {
            this.setState({ enableServices });
        }
    };

    handlePrintComplete = () => {
        this.setState({ invoiceDownloadToken: null });
    }

    render() {
        const {
            open,
            handleClose,
            classes,
            values,
            isValid,
            billNoMandatoryForIssueInvoice,
            additionalIssueInvoiceDetails,
            disablePartnerField,
        } = this.props;
        const {
            schema,
            readOnly,
            selectedInvoice,
            print,
            printJournal,
            journalEntryPrintData,
            selectedTab,
            cancelInvoice,
            enableServices,
            invoiceDownloadToken,
        } = this.state;
        const isCancelled = getStringFromObject('state', values) === 'cancel';
        const accountInvoiceLines = getStringFromObject('accountInvoiceLines', values, []);
        const accountInvoiceUuid = getStringFromObject('uuid', values);
        const hasDiscountHead = true;
        let amountUntaxed = 0;
        // eslint-disable-next-line prefer-const
        let totalSalePrice = 0;
        let totalTax = 0;
        if (isArrayValidAndNotEmpty(accountInvoiceLines)) {
            accountInvoiceLines.forEach((line) => {
                amountUntaxed += (NumberOf(line.amount) - (NumberOf(line.discountAmount) + NumberOf(line.discountAmount2)));
                totalTax += NumberOf(line.totalTax);
                if (line.rate) {
                    totalSalePrice += NumberOf(line.rate ? line.rate : 0) * (NumberOf(line.bonus) + NumberOf(line.quantity));
                }
            });
        }
        const total = amountUntaxed + totalTax;
        let totalDiscount = NumberOf(getStringFromObject('discountAmount', values));
        const discountPerc = NumberOf(getStringFromObject('discountPer', values));
        if (discountPerc > 0) {
            totalDiscount = total * (discountPerc / 100);
        }
        const invoiceNumber = getStringFromObject('number', values);
        let formSchema = { ...schema };
        const isServiceInvoice = accountInvoiceUuid && getStringFromObject('isService', values, false);
        if (enableServices || isServiceInvoice) {
            formSchema = {
                ...schema,
                tableCells: getStringFromObject('serviceTableCells', schema, []),
            };
        }
        const hasPayment = isArrayValidAndNotEmpty(getStringFromObject('paymentDetails', selectedInvoice));

        const autoDiscountConfig = getStringFromObject('autoDiscountConfig', schema);
        const subCompany = getStringFromObject('subCompany.key', values) || '';
        const marginAmount = valueToFixedTwoDigits(NumberOf(totalSalePrice) - NumberOf(amountUntaxed));
        let marginPercentage = 0;
        if (amountUntaxed) {
            marginPercentage = valueToFixedTwoDigits(((NumberOf(totalSalePrice) - NumberOf(amountUntaxed)) / NumberOf(amountUntaxed)) * 100);
        }
        return (
            <React.Fragment>
                <form>
                    <Dialog
                        open={open}
                        fullScreen
                        classes={{
                            paper: classes.paper,
                        }}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle disableTypography id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div>
                                    Issue Invoice {
                                        invoiceNumber
                                            ?
                                            '('.concat(invoiceNumber).concat(')')
                                            :
                                            ''
                                    }
                                </div>
                                <Close className="cursor-pointer" onClick={handleClose} testId="purchase-invoice-close" />
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container className="mt-1" spacing={16} >
                                <Grid item lg={3} md={4} sm={5}>
                                    <Field
                                        testId="supplier"
                                        name="supplier"
                                        test-id="purachase-invoice-supplier"
                                        component={FormikReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'name',
                                            value: 'uuid',
                                        }}
                                        label="Customer name"
                                        isDisabled={readOnly || (disablePartnerField && isObjectValidAndNotEmpty(values.supplier))}
                                        autocomplete
                                        required
                                        validate={required}
                                        dataSourceApi={`${API.SEARCH.RES_PARTNER_BASE}&isPayor=true&searchString=`}
                                    />
                                </Grid>
                                <Grid item lg={2} sm={3} md={2}>
                                    <Field
                                        name="subCompany"
                                        label="Sub Company"
                                        component={FormikReactSelectMaterial}
                                        options={Object.values(subCompanies).map(aSubCompany => ({
                                            key: aSubCompany,
                                            value: aSubCompany,
                                        }))}
                                        dataSourceConfig={{
                                            text: 'value',
                                            value: 'key',
                                        }}
                                        fullWidth
                                        validate={required}
                                        required
                                        isDisabled={readOnly || (disablePartnerField && isObjectValidAndNotEmpty(values.subCompany))}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container className="mt-2">
                                <Grid item lg={2} md={3} sm={3} style={{ paddingRight: '1em' }}>
                                    <Field
                                        test-id="purachase-invoice-terms"
                                        name="terms"
                                        component={FormikReactSelectMaterial}
                                        dataSourceConfig={{
                                            text: 'value.value',
                                            value: 'key',
                                        }}
                                        label="Terms"
                                        isDisabled={readOnly}
                                        autocomplete
                                        dataSourceApi={API.SEARCH.ACCOUNT_PAYMENT_TERM}
                                        onSelectHandlers={['handleChoosePayterm']}
                                        actionHandlers={{
                                            handleChoosePayterm: this.handleChoosePayterm,
                                        }}
                                    />
                                </Grid>
                                <Grid item lg={2} md={3} sm={3} style={{ paddingRight: '1em' }}>
                                    <Field
                                        test-id="purachase-invoice-date-invoice"
                                        name="dateInvoice"
                                        component={FormikTextField}
                                        type="date"
                                        label="Bill date"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={readOnly}
                                    />
                                </Grid>
                                <Grid item lg={2} md={3} sm={3} style={{ paddingRight: '1em' }}>
                                    <Field
                                        test-id="purachase-invoice-duedate"
                                        name="dueDate"
                                        component={FormikTextField}
                                        label="Due date"
                                        type="date"
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            classes: {
                                                root: classNames(classes.textField, classes.input),
                                            },
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled={readOnly}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    lg={5}
                                    justify="flex-start"
                                    direction="column"
                                    spacing={24}
                                    alignItems="flex-end"
                                >
                                    {
                                        readOnly && !isCancelled && !hasPayment &&
                                        <Grid item>
                                            <ActionButton
                                                test-id="purachase-invoice-edit-invoice"
                                                onClick={this.handleClickCancel}
                                            >
                                                Edit Invoice
                                            </ActionButton>
                                        </Grid>
                                    }
                                    <Grid item>
                                        <Field
                                            test-id="purachase-invoice-supplierInvoiceNumber"
                                            name="supplierInvoiceNumber"
                                            component={FormikTextField}
                                            label="Bill no"
                                            fullWidth
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    root: classNames(classes.textField, classes.input),
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            required={billNoMandatoryForIssueInvoice}
                                            validate={billNoMandatoryForIssueInvoice ? required : undefined}
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <div className="mt-2" style={{ background: '#fff', padding: '2rem' }}>
                                <Grid container spacing={32}>
                                    <Grid item>
                                        <Tabs value={selectedTab} onChange={this.onTabChange}>
                                            {
                                                Object.keys(makeBillDialogTabs).map(aTab => (
                                                    <Tab
                                                        key={aTab}
                                                        test-id={aTab}
                                                        value={makeBillDialogTabs[aTab]}
                                                        label={makeBillDialogTabs[aTab]}
                                                    />
                                                ))
                                            }
                                        </Tabs>
                                    </Grid>
                                    <Grid item>
                                        {
                                            // eslint-disable-next-line max-len
                                            !readOnly && !accountInvoiceUuid && selectedTab === makeBillDialogTabs.ITEM_DETAILS && checkIfPrivilegeExistsForUser(['create.service.invoice']) &&
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={enableServices}
                                                        onChange={this.handleToggleServices}
                                                        value="services"
                                                    />
                                                }
                                                label="Switch To Services"
                                            />
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container className="mt-1">
                                    {
                                        selectedTab === makeBillDialogTabs.ITEM_DETAILS &&
                                        isObjectValidAndNotEmpty(schema) &&
                                        <FormikTable
                                            fieldName="accountInvoiceLines"
                                            actionHandlers={ACTION_HANDLERS(autoDiscountConfig)}
                                            isEditable={!readOnly}
                                            tableRoot={{ minHeight: '20em', overflow: 'visible' }}
                                            {...formSchema}
                                            key={enableServices ? 'serviceInvoiceTable' : 'productInvoiceTable'}
                                            formValues={values}
                                        />
                                    }
                                    {
                                        selectedTab === makeBillDialogTabs.PAYMENT_DETAILS &&
                                        <PaymentDetailsTab
                                            selectedInvoice={selectedInvoice}
                                        />
                                    }
                                </Grid>
                                <Grid container className="mt-1" justify="space-between">
                                    <Grid item lg={3} md={3} sm={4}>
                                        <Field
                                            test-id="purachase-invoice-comment"
                                            name="comment"
                                            component={FormikTextField}
                                            label="Memo"
                                            multiline
                                            rows={3}
                                            rowsMax={3}
                                            fullWidth
                                            InputProps={{
                                                disableUnderline: true,
                                                classes: {
                                                    root: classNames(classes.textField, classes.input),
                                                },
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                    <Grid item lg={5} md={5} sm={2} />
                                    <Grid item lg={2} md={2} sm={3} alignItems="flex-end">
                                        <Grid container direction="column" spacing={8} className="mt-0-5">
                                            {
                                                hasDiscountHead &&
                                                <Grid item>
                                                    <Grid container spacing={8}>
                                                        <Grid item sm={6} lg={6} md={6}>
                                                            <Field
                                                                test-id="purchase-order-discount-perc"
                                                                label="Discount (%)"
                                                                name="discountPer"
                                                                component={FormikTextField}
                                                                type="number"
                                                                fullWidth
                                                                min={0}
                                                                max={100}
                                                                validate={this.maxDiscount(0, 100)}
                                                                disabled={readOnly}
                                                                onChangeHandlers={['handleChangeDiscountPerc']}
                                                                actionHandlers={{
                                                                    handleChangeDiscountPerc: this.handleChangeDiscountPerc,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item sm={6} lg={6} md={6}>
                                                            <Field
                                                                test-id="purchase-order-discount-amount"
                                                                label="Discount Amount"
                                                                name="discountAmount"
                                                                component={FormikTextField}
                                                                validate={this.maxDiscount(0, total)}
                                                                min={0}
                                                                max={total}
                                                                type="number"
                                                                fullWidth
                                                                disabled={readOnly}
                                                                onChangeHandlers={['handleChangeDiscountAmount']}
                                                                actionHandlers={{
                                                                    handleChangeDiscountAmount: this.handleChangeDiscountAmount,
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item container justify="flex-end" className="mb-3">
                                        <Grid container lg={3} md={3} sm={4} justify={`${additionalIssueInvoiceDetails ? 'space-between' : 'flex-end'}`}>
                                            {additionalIssueInvoiceDetails &&
                                                <Grid item lg={6} md={6} sm={9}>
                                                    <div className={classNames('mt-0-5', classes.totalField)}>
                                                        Total Sales Price:&nbsp;&nbsp; {valueToFixedTwoDigits(NumberOf(totalSalePrice))}
                                                    </div>
                                                    <div className={classNames('mt-0-5', classes.totalField)}>
                                                        Margin (Amount):&nbsp;&nbsp;{marginAmount}
                                                    </div>
                                                    <div className={classNames('mt-0-5', classes.totalField)}>
                                                        Margin (%):&nbsp;&nbsp;{marginPercentage}
                                                    </div>
                                                </Grid>}
                                            <Grid item lg={6} md={6} sm={3}>
                                                <Grid container alignContent="flex-end" direction="column">
                                                    <div className={classes.totalField}>
                                                        Subtotal:&nbsp;&nbsp;{valueToFixedTwoDigits(NumberOf(amountUntaxed))}
                                                    </div>
                                                    <div className={classNames('mt-0-5', classes.totalField)}>
                                                        Tax:&nbsp;&nbsp;{valueToFixedTwoDigits(totalTax)}
                                                    </div>
                                                    <div className={classNames('mt-0-5', classes.totalField)}>
                                                        Discount:&nbsp;&nbsp;{valueToFixedTwoDigits(totalDiscount)}
                                                    </div>
                                                    <hr />
                                                    <div className={classNames('mt-1', classes.totalField)}>
                                                        Net Amount:&nbsp;&nbsp;
                                                        {valueToFixedTwoDigits(Math.max(total - totalDiscount, 0))}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            <ActionButton
                                test-id="purachase-invoice-cancel"
                                primary={false}
                                disableRipple
                                onClick={handleClose}
                            >
                                Cancel
                            </ActionButton>
                            {
                                readOnly && !isCancelled && !cancelInvoice && !hasPayment &&
                                <ActionButton
                                    test-id="purachase-invoice-cancel-invoice"
                                    className="ml-1"
                                    secondary
                                    disableRipple
                                    onClick={this.handleCancelInvoiceComplete}
                                >
                                    Cancel Invoice
                                </ActionButton>
                            }
                            {
                                !readOnly &&
                                <React.Fragment>
                                    {
                                        !cancelInvoice &&
                                        <ActionButton
                                            testId="purachase-invoice-readonly-save"
                                            className="ml-1"
                                            disableRipple
                                            onClick={this.handleSave}
                                            disabled={(readOnly || (!isValid))}
                                        >
                                            Save
                                        </ActionButton>
                                    }
                                    <ActionButton
                                        testId="edit-cancel-create-new"
                                        className="ml-1"
                                        disableRipple
                                        onClick={this.handleConfirm}
                                        disabled={readOnly}
                                    >
                                        Confirm
                                    </ActionButton>
                                </React.Fragment>
                            }
                            {
                                readOnly &&
                                <React.Fragment>
                                    <ActionButton
                                        test-id="purachase-invoice-printjournals-consolidated"
                                        className="ml-1"
                                        disableRipple
                                        onClick={this.onPrintConsolidatedJournalEntriesClick}
                                    >
                                        Print Journals Consolidated
                                    </ActionButton>
                                    <ActionButton
                                        test-id="purachase-invoice-print-journals"
                                        className="ml-1"
                                        disableRipple
                                        onClick={this.onPrintJournalEntriesClick}
                                    >
                                        Print Journals
                                    </ActionButton>
                                    <ActionButton
                                        testId="purachase-invoice-print"
                                        className="ml-1"
                                        disableRipple
                                        onClick={this.onPrintClick}
                                    >
                                        Print
                                    </ActionButton>
                                    <ActionButton
                                        testId="purachase-invoice-print"
                                        className="ml-1"
                                        disableRipple
                                        onClick={this.onPrintInvoiceClick}
                                    >
                                        Print Invoice
                                    </ActionButton>
                                    <ActionButton
                                        testId="purchase-create-new"
                                        className="ml-1"
                                        disableRipple
                                        onClick={this.reInitializeForm}
                                        disabled={!readOnly}
                                    >
                                        Create New
                                    </ActionButton>
                                </React.Fragment>
                            }
                        </DialogActions>
                    </Dialog>
                </form>
                <Print
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/IssueInvoice/IssueInvoice.html`}
                    data={{
                        ...getPaymentInvoiceDetailsToPrintMemoize(selectedInvoice),
                        serviceInvoice: enableServices || isServiceInvoice,
                    }}
                    print={print}
                    subCompany={subCompany}
                />
                <PrintPDF
                    itemsToPrint={
                        [
                            {
                                url: `${API.DOWNLOAD_FILE.WITH_TOKEN}/${invoiceDownloadToken}`,
                                name: 'issueInvoicePrint',
                            },
                        ]
                    }
                    print={!!invoiceDownloadToken}
                    onPrintComplete={this.handlePrintComplete}
                />
                <Print
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/JournalPrint/JournalPrint.html`}
                    data={journalEntryPrintData}
                    print={printJournal}
                    subCompany={subCompany}
                />
            </React.Fragment>
        );
    }
}

IssueInvoiceDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    purchaseOrder: PropTypes.object,
    materialReceive: PropTypes.object,
    selectedInvoices: PropTypes.array,
    invoiceId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    /* formik props */
    resetForm: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    dirty: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    billNoMandatoryForIssueInvoice: PropTypes.bool,
    type: PropTypes.string,
    additionalIssueInvoiceDetails: PropTypes.bool,
    salesDiscountConfig: PropTypes.object,
    bundleFormValues: PropTypes.object,
    disablePartnerField: PropTypes.bool,
};

IssueInvoiceDialog.defaultProps = {
    materialReceive: null,
    purchaseOrder: {},
    selectedInvoices: [],
    invoiceId: '',
    billNoMandatoryForIssueInvoice: false,
    type: 'Account Invoice',
    additionalIssueInvoiceDetails: false,
    salesDiscountConfig: {},
    bundleFormValues: null,
    disablePartnerField: false,
};

const mapStateToProps = state => ({
    selectedInvoices: state.accountInvoice.selectedInvoice,
    billNoMandatoryForIssueInvoice:
        getStringFromObject('appConfiguration.billNoMandatoryForIssueInvoice', state),
    additionalIssueInvoiceDetails: getStringFromObject('appConfiguration.additionalIssueInvoiceDetails', state),
    salesDiscountConfig: getStringFromObject('appConfiguration.sales_discount_config', state),
});

const handleSubmitForm = (values, { props }) => {
    const dispatcher = getStringFromObject('dispatch', props);
    let payload = getAccountInvoiceFromUiObject(values);
    if (values.cancelInvoice) {
        payload.uuid = null;
        const length = NumberOf(getStringFromObject('accountInvoiceLines.length', values));
        if (length <= 0) {
            payload = null;
        }
        dispatcher(cancelAccountInvoiceRequest(values.uuid, payload));
    } else {
        dispatcher(createIssueInvoiceRequest(payload, null, null, props.type));
    }
};

export default connect(mapStateToProps)(withFormik({
    mapPropsToValues: () => cloneDeep(INVOICE_UI_OBJECT),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit: handleSubmitForm,
})(withStyles(issueInvoiceDialogStyles)(IssueInvoiceDialog)));
