import cloneDeep from 'clone-deep';
import { autofill, change } from 'redux-form';
import safeEval from '@packages/safe-eval';

import {
    getIndexFromFieldName,
    isArrayValidAndNotEmpty,
    isValidFunction,
    roundedValue,
    roundedValueFixedToTwoDigits,
    valueToFixedTwoDigits,
} from '../../../constants/CommonUtil';
import { errorMessage } from '../../../redux/modules/message/message-actions';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';


export const applyDiscountBasedOnConfig = (
    autoDiscountConfig,
    product,
    rowValue,
) => {
    const productType = getStringFromObject('type', product);
    const updatedRow = {
        ...rowValue,
    };
    console.log(
        'applyDiscountBasedOnConfig',
        {
            autoDiscountConfig,
            product,
            rowValue,
            productType,
            item: autoDiscountConfig[productType],
        });
    if (
        isObjectValidAndNotEmpty(autoDiscountConfig) &&
        isArrayValidAndNotEmpty(autoDiscountConfig[productType])
    ) {
        const matchingCondition = autoDiscountConfig[productType].find((aCondition) => {
            const {
                fieldToCheck,
                operation,
                value,
                ignoreProducts,
            } = aCondition;
            // exception
            if (isArrayValidAndNotEmpty(ignoreProducts) && ignoreProducts.includes(product.uuid)) {
                return false;
            }
            const productValForField = getStringFromObject(fieldToCheck, product);
            const evaluationString = `${productValForField}${operation}${value}`;
            try {
                return safeEval(evaluationString);
            } catch (e) {
                console.error('error evaluating expression', evaluationString);
            }
            return false;
        });
        console.log('matching condition', matchingCondition);
        if (isObjectValidAndNotEmpty(matchingCondition)) {
            updatedRow.discount1 = matchingCondition.discount;
        }
    }
    console.log('updatedRow', updatedRow);
    return updatedRow;
};
const calculateTotalPrice = (quantity, price, disc1, disc2, taxes) => {
    console.log('calculateTotalPrice', {
        quantity, price, disc1, disc2, taxes,
    });
    let totalPrice = quantity * price;
    totalPrice -= (totalPrice * NumberOf(disc1) * 0.01);
    totalPrice -= (totalPrice * NumberOf(disc2) * 0.01);
    let applicableTax = 0;
    if (isArrayValidAndNotEmpty(taxes)) {
        taxes.forEach((tax) => {
            applicableTax += NumberOf(tax.amount);
        });
    }
    console.log('calculateTotalPrice', {
        quantity, price, disc1, disc2, taxes, totalPrice,
    }, Math.max(NumberOf(totalPrice), 1), (applicableTax * totalPrice),
    );
    totalPrice = Math.max(NumberOf(totalPrice), 1);
    totalPrice += NumberOf(valueToFixedTwoDigits((applicableTax * totalPrice)));
    console.log('calculateTotalPrice', {
        quantity, price, disc1, disc2, taxes, totalPrice,
    }, (applicableTax * totalPrice),
    );
    return NumberOf(valueToFixedTwoDigits(totalPrice));
};

export const extractAndSetValues = (rowValue) => {
    const newRowValue = cloneDeep(rowValue);
    const {
        discount1, discount2,
    } = rowValue;
    const product = newRowValue.product ? newRowValue.product : {};
    const quantity = newRowValue.quantity === '' ? '' : NumberOf(newRowValue.quantity);
    const uom = isObjectValidAndNotEmpty(newRowValue.uom) ? newRowValue.uom : product.erpUomDto;
    // const factor = uom && uom.factor ? Number(uom.factor) : 1;
    const salePrice = NumberOf(rowValue.price);
    const amount = (quantity * salePrice);

    const discountPerc1 = NumberOf(discount1);
    const discountPerc2 = NumberOf(discount2);
    const discountAmount = roundedValue(amount * (discountPerc1 / 100));
    const discountAmount2 = roundedValue((amount - discountAmount) * (discountPerc2 / 100));
    const amountBeforeTax = roundedValue(amount - (discountAmount + discountAmount2));
    console.log('setvALUESS', {
        quantity,
        salePrice,
        amount,
        discount1,
        discount2,
        amountBeforeTax,
        rowValue,
    });
    const taxes = newRowValue.taxes || [];
    // let totalTax = 0;
    // if (isArrayValidAndNotEmpty(taxes)) {
    //     taxes.forEach((tax) => {
    //         const newTax = (NumberOf(tax.amount) * amount);
    //         totalTax += NumberOf(newTax);
    //         console.log('totalTaxCal', { newTax, totalTax });
    //     });
    // }

    let applicableTax = 0;
    if (isArrayValidAndNotEmpty(taxes)) {
        taxes.forEach((tax) => {
            applicableTax += NumberOf(tax.amount);
        });
    }
    const totalTax = applicableTax * amountBeforeTax;

    // Resetting after tax calculation

    newRowValue.total = roundedValueFixedToTwoDigits(amountBeforeTax + totalTax);
    newRowValue.quantity = quantity;
    newRowValue.uom = uom;
    newRowValue.salePrice = roundedValueFixedToTwoDigits(salePrice);
    newRowValue.cost = roundedValueFixedToTwoDigits(salePrice);
    newRowValue.mrp = roundedValueFixedToTwoDigits(salePrice);
    newRowValue.amount = roundedValueFixedToTwoDigits(amount);
    newRowValue.totalTax = totalTax;
    newRowValue.totalPrice = roundedValueFixedToTwoDigits(amountBeforeTax + totalTax);
    newRowValue.amountBeforeTax = roundedValueFixedToTwoDigits(amountBeforeTax);
    // newRowValue.trackByBatch = getStringFromObject('product.trackByBatch', rowValue, false);
    return newRowValue;
};

const setLine = (rowValue, index, dispatch, fields) => {
    console.log('asdfsdfsdfdsfdsf', rowValue, fields, typeof index);
    if (isObjectValidAndNotEmpty(fields)) {
        const valuesLength = fields.length;
        console.log('asdfsdfsdfdsfdsf', valuesLength);
        if (index === (valuesLength - 1).toString()) {
            // Add row automatically..
            fields.push({});
        }
    }
    if (isObjectValidAndNotEmpty(rowValue)) {
        const newRowValue = extractAndSetValues(rowValue);
        // dispatch(arrayRemove('addStockForm', 'addStock', index));
        dispatch(change('materialReceiveDialog', `receiveMaterialLines.${index}`, newRowValue));
    }
};

// Autocomplete on select handlers
const handleProductSelect = (product, formName, field, fields, dispatch, autoDiscountConfig) => {
    console.log('handleProductSelectData', product);
    if (isObjectValidAndNotEmpty(product) && formName && field && isValidFunction(dispatch) &&
        isObjectValidAndNotEmpty(fields)) {
        const index = getIndexFromFieldName(field);
        if (index != null) {
            let rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(index)) : null;
            rowValue.product = product;
            rowValue.uom = product.erpUomDto;
            rowValue.price = NumberOf(product.price);
            // rowValue.quantity = Math.max(1, NumberOf(rowValue.quantity));
            // if (NumberOf(rowValue.quantity) !== 0) {
            //     rowValue.quantity = NumberOf(rowValue.quantity);
            // }
            rowValue.quantity = NumberOf(rowValue.quantity) === 0 ? '' : NumberOf(rowValue.quantity);
            rowValue.bonusPercentage = getStringFromObject('bonusPercentage', product, 0);
            if (NumberOf(rowValue.bonusPercentage) && NumberOf(rowValue.quantity)) {
                rowValue.bonus = roundedValueFixedToTwoDigits(rowValue.quantity * (rowValue.bonusPercentage / 100));
            }

            rowValue.taxes = product.taxes || [];
            rowValue.expiry = '';
            rowValue.trackByBatch = product.trackByBatch;
            if (NumberOf(product.purchaseDiscount)) {
                rowValue.discount1 = NumberOf(product.purchaseDiscount);
            } else {
                rowValue = applyDiscountBasedOnConfig(autoDiscountConfig, product, rowValue);
            }
            const {
                quantity, price, discount1, discount2, taxes,
            } = rowValue;
            rowValue.totalPrice = calculateTotalPrice(quantity, price, discount1, discount2, taxes);

            setLine(rowValue, index, dispatch, fields);
        }
    }
};

const handleTaxSelect = (value, formName, field, fields, dispatch) => {
    if (formName && field && isValidFunction(dispatch) &&
        isObjectValidAndNotEmpty(fields)) {
        const index = getIndexFromFieldName(field);
        if (index != null) {
            const rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(index)) : null;
            if (isObjectValidAndNotEmpty(rowValue)) {
                // const {
                //     discount1, discount2, quantity, price,
                // } = rowValue;
                // rowValue.totalPrice = calculateTotalPrice(quantity, price, discount1, discount2, value);
                rowValue.taxes = value;
                setLine(rowValue, index, dispatch);
            }
        }
    }
};

const priceChangeHandler = (value, formName, field, fields, dispatch) => {
    if (formName && field && isValidFunction(dispatch) && isObjectValidAndNotEmpty(fields)
    ) {
        const index = getIndexFromFieldName(field);
        if (index != null) {
            const rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(index)) : null;
            if (isObjectValidAndNotEmpty(rowValue)) {
                // const {
                //     discount1, discount2, quantity, taxes,
                // } = rowValue;
                // rowValue.totalPrice = calculateTotalPrice(quantity, value, discount1, discount2, taxes);
                rowValue.price = value;
                setLine(rowValue, index, dispatch);
            }
        }
    }
};
const quantityChangeHandler = (value, formName, field, fields, dispatch) => {
    if (formName && field && isValidFunction(dispatch) && isObjectValidAndNotEmpty(fields)
    ) {
        const index = getIndexFromFieldName(field);
        if (index != null) {
            const rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(index)) : null;
            if (isObjectValidAndNotEmpty(rowValue)) {
                // const {
                //     discount1, discount2, price, taxes,
                // } = rowValue;
                // rowValue.totalPrice = calculateTotalPrice(value, price, discount1, discount2, taxes);
                if (NumberOf(rowValue.bonusPercentage) && NumberOf(value)) {
                    rowValue.bonus = roundedValueFixedToTwoDigits(value * (rowValue.bonusPercentage / 100));
                }
                rowValue.quantity = value;
                setLine(rowValue, index, dispatch);
            }
        }
    }
};
const totalChangeHandler = (value, formName, field, fields, dispatch) => {
    if (formName && field && isValidFunction(dispatch) && isObjectValidAndNotEmpty(fields)
    ) {
        const index = getIndexFromFieldName(field);
        if (index != null) {
            const rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(index)) : null;
            if (isObjectValidAndNotEmpty(rowValue)) {
                const {
                    price, quantity, amountBeforeTax,
                } = rowValue;

                const newTotalBeforeTax = NumberOf(value);
                const totalBeforeTaxAndDiscount = roundedValue(price * quantity);

                // if new totalBeforeTax is hchanged
                if (amountBeforeTax !== newTotalBeforeTax) {
                    // if new newTotalBeforeTax is higher than `price` * `quantity`, revert the changes

                    if (newTotalBeforeTax > totalBeforeTaxAndDiscount) {
                        rowValue.discount1 = 0;
                        setLine(rowValue, index, dispatch, fields);
                        dispatch(
                            errorMessage(
                                `Total before VAT cannot be more than ${totalBeforeTaxAndDiscount} (quantity * price)`,
                            ),
                        );
                    } else {
                        console.log('discountFieldsOnTotalChange', { });
                        // rowValue.totalPrice = calculateTotalPrice(value, price, discount1, discount2, taxes);

                        // calculate discount1 amount from `price` * `quantity` and totalBeforeTax
                        const discountAmount = totalBeforeTaxAndDiscount - newTotalBeforeTax;
                        const discount1 = roundedValue((discountAmount / totalBeforeTaxAndDiscount) * 100);
                        rowValue.amount = valueToFixedTwoDigits(totalBeforeTaxAndDiscount);
                        rowValue.discount1 = (discount1);
                        rowValue.amountBeforeTax = value;

                        console.log('totalChanged222', { rowValue });
                        setLine(rowValue, index, dispatch, fields);
                    }
                }
            }
        }
    }
};
const discountOneChangeHandler = (value, formName, field, fields, dispatch) => {
    if (formName && field && isValidFunction(dispatch) && isObjectValidAndNotEmpty(fields)
    ) {
        const index = getIndexFromFieldName(field);
        if (index != null) {
            const rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(index)) : null;
            if (isObjectValidAndNotEmpty(rowValue)) {
                // const {
                //     discount2, price, quantity, taxes,
                // } = rowValue;
                // rowValue.totalPrice = calculateTotalPrice(quantity, price, value, discount2, taxes);
                rowValue.discount1 = value;
                setLine(rowValue, index, dispatch);
            }
        }
    }
};
const discountTwoChangeHandler = (value, formName, field, fields, dispatch) => {
    if (formName && field && isValidFunction(dispatch) && isObjectValidAndNotEmpty(fields)
    ) {
        const index = getIndexFromFieldName(field);
        if (index != null) {
            const rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(index)) : null;
            if (isObjectValidAndNotEmpty(rowValue)) {
                // const {
                //     discount1, price, quantity, taxes,
                // } = rowValue;
                // rowValue.totalPrice = calculateTotalPrice(quantity, price, discount1, value, taxes);
                rowValue.discount2 = value;
                setLine(rowValue, index, dispatch);
            }
        }
    }
};

const rateChangeHandler = (value, formName, field, fields, dispatch) => {
    if (formName && field && isValidFunction(dispatch) && isObjectValidAndNotEmpty(fields)) {
        const index = getIndexFromFieldName(field);
        if (index != null) {
            const rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(index)) : null;
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.salePrice = value;
                setLine(rowValue, index, dispatch);
            }
        }
    }
};

const rowChangeHandler = (value, formName, field, fields, dispatch) => {
    console.log('rowChangeHandlerrowChangeHandler', { value }, { formName }, { field }, { fields });

    if (formName && field && isValidFunction(dispatch) && isObjectValidAndNotEmpty(fields)) {
        const index = getIndexFromFieldName(field);
        if (index != null) {
            const rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(index)) : null;
            let operation = rowValue.operation || 1;
            if (isObjectValidAndNotEmpty(rowValue)) {
                if (rowValue.uuid) {
                    operation = 2;
                }
            }
            dispatch(autofill(formName, `${field}.operation`, operation));
            console.log('rowChangeHandlerrowChangeHandler', { rowValue, fieldOp: `${field}.operation`, field });
        }
    }
};

const deleteRowHandler = (selectedRows, fields, dispatch, instance) => {
    if (isArrayValidAndNotEmpty(selectedRows) && isValidFunction(dispatch) && isObjectValidAndNotEmpty(fields)) {
        selectedRows.forEach((idx) => {
            // const rowValue = isValidFunction(fields.get) ? cloneDeep(fields.get(idx)) : null;
            // if (isObjectValidAndNotEmpty(rowValue) && rowValue.orderLineUuid) {
            //     rowValue.operation = 3;
            //     arrayRemove('purchaseOrderForm', 'purchaseOrderLines', idx);
            //     arrayInsert('purchaseOrderForm', 'purchaseOrderLines', idx, rowValue);
            // } else {
            //     fields.remove(idx);
            //     if (instance != null) {
            //         instance.setState({ selected: selectedRows.splice(idx, 1) });
            //     }
            // }
            fields.remove(idx);
            if (fields.length === 1) {
                fields.push({});
            }
            if (instance != null) {
                instance.setState({ selected: selectedRows.splice(idx, -1) });
            }
        });
    }
};

export const ACTION_HANDLERS = autoDiscountConfig => ({
    handleProductSelect: (product, formName, field, fields, dispatch) => {
        handleProductSelect(product, formName, field, fields, dispatch, autoDiscountConfig);
    },
    rateChangeHandler,
    quantityChangeHandler,
    rowChangeHandler,
    deleteRowHandler,
    handleTaxSelect,
    priceChangeHandler,
    discountOneChangeHandler,
    discountTwoChangeHandler,
    totalChangeHandler,
});

export default ACTION_HANDLERS;
