import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import sortBy from 'lodash.sortby';
import { connect } from 'react-redux';
import ReactSelectMaterial from '../../../../components/ReactSelectMaterial/ReactSelectMaterial';
import api from '../../../../constants/api';
import { errorMessage } from '../../../../redux/modules/message/message-actions';
import {
    apiCatchBlockFunction,
    isArrayValidAndNotEmpty,
    isValidFunction,
} from '../../../../constants/CommonUtil';
import { formatTimeForDisplay, isDateBefore } from '../../../../constants/DateUtil';
import consultationStatuses from '../../../QueueNodeDisplayContainer/QueueUtils';
import AppointmentTokenTimeBox
    from '../../../../components/AppointmentsComponent/AppointmentTokenTimeBox/AppointmentTokenTimeBox';
import OutlinedTextField from '../../../../components/OutlinedTextField';
import DateInput from '../../../../components/FormFieldComponents/DateInput/DateInput';
import DoctorQueueTableDisplay from './DoctorQueueTableDisplay';
import { GLOBAL_PROPERTIES, secondaryIdentifierKey } from '../../../../constants/constants';
import {
    encounterStatusMainFilters,
    encounterStatusMainFiltersStatusMappers,
    getConsultationStatusInitialFilter,
} from './ConsultationStatusFilter/ConsultationStatusFilterUtil';
import ConsultationStatusMainFilter from './ConsultationStatusFilter/ConsultationStatusMainFilter';
import { getStringFromObject } from '../../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../../constants/nullCheckUtils';

const summaryDetails = [
    {
        label: 'Total Patients Checked In',
        color: '',
        key: 'totalCheckedIn',
    },
    {
        label: 'Total Consulted',
        color: '',
        key: 'totalConsulted',
    },
    {
        label: 'Still Waiting',
        color: '',
        key: 'totalWaiting',
    },
];

class DoctorQueueDialog extends React.Component {
    constructor(props) {
        super(props);
        let doctor = null;
        if (isObjectValidAndNotEmpty(props.doctor)) {
            doctor = {
                key: props.doctor.key,
                value: props.doctor,
            };
        }
        this.state = {
            doctor,
            loading: false,
            queue: [],
            filteredQueue: [],
            queueDate: null,
            searchText: '',
            consultationStatusFilter: getConsultationStatusInitialFilter(props.encounterStatusBitHelper),
        };
    }

    componentDidMount() {
        this.fetchDoctorQueue();
    }

    onQueueDateChange = (queueDate) => {
        this.setState({
            queueDate,
        }, this.fetchDoctorQueue);
    };

    onRowButtonClick = async (_, data) => {
        const { dispatch, recentQueue, onRowButtonClick } = this.props;
        if (recentQueue && isValidFunction(onRowButtonClick)) {
            await onRowButtonClick(data);
            return null;
        }
        console.log('asdpoja[sd0aj[d0a', data);
        const consultationStatus = getStringFromObject('consultationStatus', data);
        if (consultationStatus === consultationStatuses.DONE) {
            dispatch(errorMessage('Token Cannot Be Cancelled As It\'s Already Called And Consulted'));
            return null;
        }
        try {
            if (data.episodeProviderUuid) {
                await axios.get(`${api.EPISODE_OF_CARE.DEACTIVATE_EP_PROV}${data.episodeProviderUuid}`);
            } else {
                await axios.get(`${api.QUEUES.CANCEL_TOKEN}${data.tokenUuid}`);
            }
            this.fetchDoctorQueue();
        } catch (e) {
            apiCatchBlockFunction(e, dispatch);
        }
        return null;
    };

    onDoctorSelect = (doctor) => {
        this.setState({
            doctor,
        }, this.fetchDoctorQueue);
    };

    onSearchChange = (value) => {
        const searchText = `${value || ''}`;
        this.setState({
            searchText,
        }, this.onFilterQueue);
    };

    onSelectAConsultationFilter = anOrderType => (value) => {
        this.setState((prevState) => {
            console.log('asda9uas-dsa');
            const consultationStatusFilter = {
                ...prevState.consultationStatusFilter,
                [anOrderType]: value,
            };
            return {
                consultationStatusFilter,
            };
        }, this.onFilterQueue);
    };

    onChangeConsultationFilter = (newConsultationStatusFilter) => {
        this.setState((prevState) => {
            console.log('asda9uas-dsa');
            const consultationStatusFilter = {
                ...prevState.consultationStatusFilter,
                ...newConsultationStatusFilter,
            };
            return {
                consultationStatusFilter,
            };
        }, this.onFilterQueue);
    };

    onFilterQueue = () => {
        this.setState((prevState) => {
            const { queue, searchText, consultationStatusFilter } = prevState;
            const lowerCaseSearchText = searchText.toLowerCase();
            let newFilteredQueueState = [];
            queue.forEach((aQueue) => {
                const filteredNodes = aQueue.nodes.filter((aNode) => {
                    let showNode = true;
                    if (lowerCaseSearchText) {
                        showNode = ((aNode.patientIdentifier || '').toLowerCase().includes(lowerCaseSearchText) ||
                            (aNode.patientSecondaryIdentifier || '').toLowerCase().includes(lowerCaseSearchText) ||
                            `${(aNode.tokenNumber || '')}`.toLowerCase() === (lowerCaseSearchText) ||
                            (getStringFromObject('patient.value', aNode) || '').toLowerCase().includes(lowerCaseSearchText));
                    }
                    if (showNode) {
                        Object.keys(consultationStatusFilter).forEach((anOrderType) => {
                            const notOrdered =
                                consultationStatusFilter[anOrderType][encounterStatusMainFilters.NOT_ORDERED];
                            const ordered =
                                consultationStatusFilter[anOrderType][encounterStatusMainFilters.ORDERED];
                            const fulfilled =
                                consultationStatusFilter[anOrderType][encounterStatusMainFilters.FULFILLED];
                            console.log('asd0a9da-s0sais', consultationStatusFilter[anOrderType]);
                            if (showNode) {
                                // if all false
                                let orderTypeShowNode = false;
                                if (!notOrdered && !ordered && !fulfilled) {
                                    orderTypeShowNode = true;
                                }
                                if (notOrdered) {
                                    orderTypeShowNode =
                                        encounterStatusMainFiltersStatusMappers[encounterStatusMainFilters.NOT_ORDERED]
                                            .includes(aNode[anOrderType]);
                                }
                                if (!orderTypeShowNode && ordered) {
                                    orderTypeShowNode =
                                        encounterStatusMainFiltersStatusMappers[encounterStatusMainFilters.ORDERED]
                                            .includes(aNode[anOrderType]);
                                }
                                if (!orderTypeShowNode && fulfilled) {
                                    orderTypeShowNode =
                                        encounterStatusMainFiltersStatusMappers[encounterStatusMainFilters.FULFILLED]
                                            .includes(aNode[anOrderType]);
                                }
                                showNode = orderTypeShowNode;
                            }
                        });
                    }
                    if (showNode && consultationStatusFilter[encounterStatusMainFilters.INCOMPLETE_ENCOUNTER]) {
                        const markedAsIncomplete = getStringFromObject('markedAsIncomplete', aNode, false);
                        showNode = markedAsIncomplete === consultationStatusFilter[encounterStatusMainFilters.INCOMPLETE_ENCOUNTER];
                    }
                    return showNode;
                });
                console.log('asda09sud0a', consultationStatusFilter);
                if (isArrayValidAndNotEmpty(filteredNodes)) {
                    newFilteredQueueState = newFilteredQueueState.concat({
                        ...aQueue,
                        nodes: filteredNodes,
                    });
                }
            });
            return {
                filteredQueue: newFilteredQueueState,
                forceExpandExpansionPanel: !prevState.forceExpandExpansionPanel,
            };
        });
    };

    getSummaryCounts = () => {
        const {
            queue,
        } = this.state;
        let totalCheckedIn = 0;
        let totalConsulted = 0;
        let totalWaiting = 0;
        let slotDateTime = '';
        let currentToken = '';
        let completeCurrentTokenDetails = null;
        if (isArrayValidAndNotEmpty(queue)) {
            for (let j = 0; j < queue.length; j += 1) {
                const aQueue = queue[j];
                if (isObjectValidAndNotEmpty(aQueue) && isArrayValidAndNotEmpty(aQueue.nodes)) {
                    for (let i = 0; i < aQueue.nodes.length; i += 1) {
                        const theNode = aQueue.nodes[i];
                        if (isObjectValidAndNotEmpty(theNode.patient)) {
                            totalCheckedIn += 1;
                        }
                        if (theNode.consultationStatus === consultationStatuses.DONE) {
                            totalConsulted += 1;
                        }
                        if (
                            theNode.consultationStatus === consultationStatuses.WAITING &&
                            isObjectValidAndNotEmpty(theNode.patient)
                        ) {
                            totalWaiting += 1;
                        }
                        if (theNode.isCurrentNode) {
                            currentToken = theNode.tokenNumber;
                            // eslint-disable-next-line
                            slotDateTime = theNode.slotDateTime;
                            completeCurrentTokenDetails = theNode;
                        }
                    }
                }
            }
        }
        return {
            totalWaiting,
            totalConsulted,
            totalCheckedIn,
            currentToken,
            slotDateTime,
            completeCurrentTokenDetails,
        };
    };

    fetchDoctorQueue = async () => {
        const {
            doctor,
            queueDate,
        } = this.state;
        const {
            dataApi,
            recentQueue,
        } = this.props;
        if (isObjectValidAndNotEmpty(doctor) || recentQueue) {
            this.setState({
                loading: true,
            });
            const doctorUuid = getStringFromObject('key', doctor);
            try {
                let response = null;
                if (recentQueue) {
                    response = await axios.get(dataApi);
                } else {
                    response = await axios.get(`${api.QUEUES.QUEUE_FOR_DOC}${doctorUuid}&queueDate=${queueDate || ''}`);
                }
                // change status of expired slots that were not used to expired

                const queues = response.data;
                let finalQueueList = [];
                if (isArrayValidAndNotEmpty(queues)) {
                    queues.forEach((aDoctorQueue) => {
                        // let theQueueNodes = sortBy(aDoctorQueue.nodes, ['tokenTypePriority', 'slotDateTime']);
                        let theQueueNodes = sortBy(aDoctorQueue.nodes, ['slotDateTime']);
                        // sort by tokentype priority
                        console.log('asdas09daus9dasd', theQueueNodes);
                        if (isArrayValidAndNotEmpty(theQueueNodes)) {
                            theQueueNodes = theQueueNodes.map((aQueueNode) => {
                                let theQueueNode = {
                                    ...aQueueNode,
                                };
                                theQueueNode.slotTimes = [aQueueNode.slotDateTime];
                                theQueueNode.appointmentType = [aQueueNode.slotType];
                                theQueueNode.tokenNumbers = [aQueueNode.tokenNumber];
                                theQueueNode.nationality = [getStringFromObject('nationality.value', aQueueNode) || ''];
                                console.log('as90dua-0daus0', aQueueNode);
                                if (recentQueue) {
                                    theQueueNode = this.addEncounterStatusFlags(theQueueNode);
                                }
                                if (
                                    !isObjectValidAndNotEmpty(aQueueNode.patient) &&
                                    isDateBefore(new Date(aQueueNode.slotDateTime), new Date())
                                ) {
                                    return { ...theQueueNode, consultationStatus: 'UNUSED' };
                                }
                                return theQueueNode;
                            });
                        }
                        finalQueueList = finalQueueList.concat({
                            ...aDoctorQueue,
                            nodes: theQueueNodes,
                        });
                    });
                }
                console.log('asd=a-di=a-sdasd', finalQueueList);
                this.setState({
                    queue: finalQueueList,
                    filteredQueue: finalQueueList,
                    loading: false,
                });
            } catch (e) {
                apiCatchBlockFunction(e, this.props.dispatch);
            }
        } else {
            this.setState({
                queue: [],
                filteredQueue: [],
            });
        }
    };

    addEncounterStatusFlags = (aQueueNode) => {
        const {
            encounterStatusBitHelper,
        } = this.props;
        const theQueueNode = { ...aQueueNode };
        const { encounterOrderStatus } = aQueueNode;
        // convert this to binary
        if (encounterOrderStatus || encounterOrderStatus === 0) {
            let binaryValue = encounterOrderStatus.toString(2);
            if (binaryValue.length % 2 === 1) {
                binaryValue = `0${binaryValue}`;
            }
            const reversedBinary = (binaryValue.toString().match(/[\s\S]{1,2}/g) || []).reverse();
            if (isObjectValidAndNotEmpty(encounterStatusBitHelper)) {
                Object.keys(encounterStatusBitHelper).forEach((anOrderType) => {
                    if (encounterStatusBitHelper[anOrderType] || encounterStatusBitHelper[anOrderType] === 0) {
                        const theIndexForStatus = encounterStatusBitHelper[anOrderType] / 2;
                        const binaryValueForTheOrderType = reversedBinary[theIndexForStatus] || '00';
                        theQueueNode[anOrderType] = parseInt(binaryValueForTheOrderType, 2);
                    }
                });
            }
            console.log('asd09ud0-asd', reversedBinary, encounterStatusBitHelper);
        }
        return theQueueNode;
    };

    render() {
        const {
            doctor,
            queue,
            loading,
            filteredQueue,
            searchText,
            queueDate,
            forceExpandExpansionPanel,
            consultationStatusFilter,
        } = this.state;
        const {
            frontDeskSupervisorPrivilege,
            recentQueue,
            dispatch,
            tableSchema,
            onRowClick,
            secondaryIdentifierLabel,
        } = this.props;
        const summaryCounts = this.getSummaryCounts();
        console.log('asdao-=aoda', queue, filteredQueue);
        return (
            <React.Fragment>
                <Grid container spacing={16} className="mb-1">
                    <Grid item sm={3} md={3} lg={3}>
                        {
                            !recentQueue &&
                            <ReactSelectMaterial
                                testId="doctor"
                                dataSourceApi={api.SEARCH.DOCTOR}
                                autoFocus
                                autocomplete
                                dataSourceConfig={{
                                    text: 'value.value',
                                    value: 'key',
                                }}
                                value={doctor}
                                label="Doctor"
                                onChange={this.onDoctorSelect}
                            />
                        }
                    </Grid>
                    {
                        !recentQueue &&
                        isObjectValidAndNotEmpty(doctor) &&
                        summaryCounts.currentToken &&
                        <Grid item sm={2} md={2} lg={2}>
                            <AppointmentTokenTimeBox
                                timeSlot={formatTimeForDisplay(new Date(summaryCounts.slotDateTime))}
                                tokenNumber={summaryCounts.currentToken}
                                available
                                boxStyle={{
                                    width: '100%',
                                    margin: 0,
                                    boxShadow: 'none',
                                    marginRight: '1rem',
                                }}
                                token={summaryCounts.completeCurrentTokenDetails}
                            />
                        </Grid>
                    }
                    {
                        !recentQueue &&
                        summaryDetails.map(aSummaryDetails => (
                            <Grid item sm={2} md={2} lg={2} key={aSummaryDetails.key} className="flexChildren">
                                <div>
                                    {aSummaryDetails.label}:&nbsp;
                                    <b>{getStringFromObject(aSummaryDetails.key, summaryCounts)}</b>
                                </div>
                            </Grid>
                        ))
                    }
                    <Grid item sm={1} md={1} lg={1} />
                    <Grid item sm={3} md={3} lg={3}>
                        {
                            !recentQueue &&
                            isObjectValidAndNotEmpty(doctor) &&
                            <DateInput
                                test-id="queue-date"
                                label="Queue Date"
                                onChange={this.onQueueDateChange}
                                maxDate={new Date()}
                                value={queueDate}
                            />
                        }
                    </Grid>
                    <Grid item sm={3} md={3} lg={3} />
                    <Grid item sm={3} md={3} lg={3}>
                        <OutlinedTextField
                            testId="search-doctor"
                            value={searchText}
                            placeholder={`Search By Patient ID / ${secondaryIdentifierLabel || 'National ID'} / Name`}
                            onChange={this.onSearchChange}
                        />
                    </Grid>
                    {
                        recentQueue &&
                        <Grid item sm={9} md={9} lg={9}>
                            <ConsultationStatusMainFilter
                                onSelectAConsultationFilter={this.onSelectAConsultationFilter}
                                onChangeConsultationFilter={this.onChangeConsultationFilter}
                                consultationStatusFilter={consultationStatusFilter}
                            />
                        </Grid>
                    }
                </Grid>
                {
                    loading &&
                    <DoctorQueueTableDisplay
                        loading={loading}
                        frontDeskSupervisorPrivilege={frontDeskSupervisorPrivilege}
                    />
                }
                {
                    (isArrayValidAndNotEmpty(queue) && !loading) &&
                    filteredQueue.map((aQueue, index) => (
                        <DoctorQueueTableDisplay
                            key={aQueue.startTime}
                            onRowButtonClick={this.onRowButtonClick}
                            fetchDoctorQueue={this.fetchDoctorQueue}
                            queue={aQueue}
                            loading={loading}
                            dispatch={dispatch}
                            index={index}
                            frontDeskSupervisorPrivilege={frontDeskSupervisorPrivilege}
                            forceExpand={forceExpandExpansionPanel}
                            recentQueue={recentQueue}
                            tableSchema={tableSchema}
                            onRowClick={onRowClick}
                        />
                    ))
                }
                {
                    !isArrayValidAndNotEmpty(queue) && !loading &&
                        <div className="centerAlignedLabel" test-id="error-code">
                            No Data To Display
                        </div>
                }
                {
                    !isArrayValidAndNotEmpty(filteredQueue) && !loading &&
                        <div className="centerAlignedLabel" test-id="error-code">
                            No items matched for current filter parameters
                        </div>
                }
            </React.Fragment>
        );
    }
}

DoctorQueueDialog.propTypes = {
    doctor: PropTypes.object,
    frontDeskSupervisorPrivilege: PropTypes.string,
    dataApi: PropTypes.string,
    secondaryIdentifierLabel: PropTypes.string,
    recentQueue: PropTypes.bool,
    dispatch: PropTypes.func,
    onRowClick: PropTypes.func,
    tableSchema: PropTypes.object,
    encounterStatusBitHelper: PropTypes.object,
    onRowButtonClick: PropTypes.func,
};

DoctorQueueDialog.defaultProps = {
    doctor: null,
    frontDeskSupervisorPrivilege: '',
    dataApi: '',
    secondaryIdentifierLabel: 'National ID',
    recentQueue: false,
    dispatch: () => {},
    onRowClick: () => {},
    tableSchema: {},
    encounterStatusBitHelper: {},
    onRowButtonClick: () => {},
};

const mapStateToProps = state => ({
    frontDeskSupervisorPrivilege: getStringFromObject('appConfiguration.frontDeskSupervisorPrivilege', state),
    secondaryIdentifierLabel:
        getStringFromObject(`appConfiguration.${secondaryIdentifierKey}`, state),
    encounterStatusBitHelper:
        getStringFromObject(`appConfiguration.${GLOBAL_PROPERTIES.ENCOUNTER_OT_STATUS_BIT_POS_MAP}`, state),
});

export default connect(mapStateToProps)(DoctorQueueDialog);

