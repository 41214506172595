import initialStates from '../../initialStates';
import {
    FETCH_CORPORATE_SALES_PURCHASE_ORDER_FAILURE,
    FETCH_CORPORATE_SALES_PURCHASE_ORDER_SUCCESS
 } from './corporateSalesPurchaseOrder-actions';

const corporateSalesPurchaseOrderReducers = (state = initialStates.corporateSalesPurchaseOrder, action) => {
    let newState;
    switch (action.type) {
    case FETCH_CORPORATE_SALES_PURCHASE_ORDER_SUCCESS:
        newState = {
            list: action.data.content,
            page: action.page,
            size: action.size,
            filterValues: action.filterValues,
            totalElements: action.data.totalElements,
        };
        break;
    case FETCH_CORPORATE_SALES_PURCHASE_ORDER_FAILURE:
        newState = { ...initialStates.debitAndCreditLines };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default corporateSalesPurchaseOrderReducers;
