import axios from 'axios';

import { getStringFromObject } from './lodashUtils';
import api from './api';
import { getState } from './state';
import { getJsonPath } from './CommonUtil';

// eslint-disable-next-line import/prefer-default-export
export const getLoggedInUserPhotoUrl = () => {
    const state = getState();
    const photoUuid = getStringFromObject('login.photo', state, '');
    return photoUuid ? `${api.FILE.DOWNLOAD}${photoUuid}` : getJsonPath('/EmployeeDashboard/person.png');
};

export const requestBase64 = (url, callback) => {
    axios.get(url, { responseType: 'arraybuffer' })
        .then((response) => {
            const contentType = response.headers['content-type'];
            const uint8Array = new Uint8Array(response.data);
            const base64String = btoa(String.fromCharCode(...uint8Array));
            const data = `data:${contentType};base64,${base64String}`;
            callback(null, data);
        })
        .catch((error) => {
            callback(error);
        });
};
