import api from '../../../constants/api';

export const FETCH_CORPORATE_SALES_DELIVERY_NOTES_REQUEST = '@@corporateSalesDeliveryNotes/FETCH_CORPORATE_SALES_DELIVERY_NOTES_REQUEST';
export const FETCH_CORPORATE_SALES_DELIVERY_NOTES_SUCCESS = '@@corporateSalesDeliveryNotes/FETCH_CORPORATE_SALES_DELIVERY_NOTES_SUCCESS';
export const FETCH_CORPORATE_SALES_DELIVERY_NOTES_FAILURE = '@@corporateSalesDeliveryNotes/FETCH_CORPORATE_SALES_DELIVERY_NOTES_FAILURE';

export const fetchDeliveryNotes = (page, size, searchConstruct, seekParams, seekDirection, filterValues) => ({
    type: FETCH_CORPORATE_SALES_DELIVERY_NOTES_REQUEST,
    api: api.CORPORATE_SALES.DELIVERY_NOTES.PAGE,
    page,
    size,
    seekParams,
    seekDirection,
    searchConstruct,
    filterValues,
});
