import { isObjectValidAndNotEmpty } from '../../../../../constants/nullCheckUtils';

export const encounterStatusMap = {
    NO_ORDERS: 0,
    ALL_UNFULFILLED_ORDERS: 1,
    PARTLY_FULFILLED_ORDERS: 2,
    ALL_FULFILLED_ORDERS: 3,
};

export const encounterStatusMapLabel = {
    NO_ORDERS: 'No Orders',
    ALL_UNFULFILLED_ORDERS: 'Not Fulfilled',
    PARTLY_FULFILLED_ORDERS: 'Partly Fulfilled',
    ALL_FULFILLED_ORDERS: 'All Fulfilled',
};


export const encounterStatusMainFilters = {
    NOT_ORDERED: 'NOT_ORDERED',
    ORDERED: 'ORDERED',
    FULFILLED: 'FULFILLED',
    INCOMPLETE_ENCOUNTER: 'INCOMPLETE_ENCOUNTER',
};

export const encounterStatusMainFiltersLabels = {
    NOT_ORDERED: 'Not Ordered',
    ORDERED: 'Ordered',
    FULFILLED: 'Fulfilled',
};

export const encounterStatusMainFiltersStatusMappers = {
    NOT_ORDERED: [encounterStatusMap.NO_ORDERS],
    ORDERED: [encounterStatusMap.ALL_UNFULFILLED_ORDERS],
    FULFILLED: [encounterStatusMap.ALL_FULFILLED_ORDERS, encounterStatusMap.PARTLY_FULFILLED_ORDERS],
};

export const initialEncounterStatusValue = {
    [encounterStatusMainFilters.NOT_ORDERED]: false,
    [encounterStatusMainFilters.ORDERED]: false,
    [encounterStatusMainFilters.FULFILLED]: false,
    [encounterStatusMainFilters.INCOMPLETE_ENCOUNTER]: false,
};

export const encounterStatusCompactFilters = {
    LAB_ORDER_WITH_RESULTS: 'LAB_ORDER_WITH_RESULTS',
    NO_DRUGS: 'NO_DRUGS',
    XRAY_WITH_RESULTS: 'XRAY_WITH_RESULTS',
    INCOMPLETE_ENCOUNTER: 'INCOMPLETE_ENCOUNTER',
    ALL_ENCOUNTER: 'ALL_ENCOUNTER',
};

export const encounterStatusCompactFiltersLabel = {
    LAB_ORDER_WITH_RESULTS: 'Lab Orders With Results',
    NO_DRUGS: 'No Drugs',
    XRAY_WITH_RESULTS: 'X-RAY With Results',
    INCOMPLETE_ENCOUNTER: 'Incomplete Encounters',
};

export const encounterStatusCompactFiltersMappers = {
    LAB_ORDER_WITH_RESULTS: {
        'Lab Order': {
            [encounterStatusMainFilters.NOT_ORDERED]: false,
            [encounterStatusMainFilters.ORDERED]: false,
            [encounterStatusMainFilters.FULFILLED]: true,
        },
    },
    NO_DRUGS: {
        'Drug Order': {
            [encounterStatusMainFilters.NOT_ORDERED]: true,
            [encounterStatusMainFilters.ORDERED]: false,
            [encounterStatusMainFilters.FULFILLED]: false,
        },
    },
    XRAY_WITH_RESULTS: {
        'X-RAY Order': {
            [encounterStatusMainFilters.NOT_ORDERED]: false,
            [encounterStatusMainFilters.ORDERED]: false,
            [encounterStatusMainFilters.FULFILLED]: true,
        },
        'US Order': {
            [encounterStatusMainFilters.NOT_ORDERED]: false,
            [encounterStatusMainFilters.ORDERED]: false,
            [encounterStatusMainFilters.FULFILLED]: true,
        },
    },
    INCOMPLETE_ENCOUNTER: {
        [encounterStatusMainFilters.INCOMPLETE_ENCOUNTER]: true,
    },
    ALL_ENCOUNTER: {
        [encounterStatusMainFilters.INCOMPLETE_ENCOUNTER]: false,
    },
};

export const getConsultationStatusInitialFilter = (encounterStatusBitHelper) => {
    const consultationStatusFilter = {};
    if (isObjectValidAndNotEmpty(encounterStatusBitHelper)) {
        Object.keys(encounterStatusBitHelper).forEach((anOrderType) => {
            consultationStatusFilter[anOrderType] = {
                ...initialEncounterStatusValue,
            };
        });
    }
    return consultationStatusFilter;
};
