import { takeLatest } from 'redux-saga/effects';
import {
    FETCH_CORPORATE_SALES_ACCOUNT_INVOICE_REQUEST,
} from '../../redux/modules/corporateSalesAccountInvoice/corporateSalesAccountInvoice-actions';
import fetchCorporateSalesAccountInvoiceWorker from './corporateSalesAccountInvoiceWorkerSaga';

function* fetchCorporateSalesAccountInvoiceWatcher() {
    yield takeLatest(FETCH_CORPORATE_SALES_ACCOUNT_INVOICE_REQUEST, fetchCorporateSalesAccountInvoiceWorker);
}

export default fetchCorporateSalesAccountInvoiceWatcher;
