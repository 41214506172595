import api from '../../../constants/api';

export const FETCH_CORPORATE_SALES_PURCHASE_ORDER_REQUEST = '@@corporateSalesQuotation/FETCH_CORPORATE_SALES_PURCHASE_ORDER_REQUEST';
export const FETCH_CORPORATE_SALES_PURCHASE_ORDER_SUCCESS = '@@corporateSalesQuotation/FETCH_CORPORATE_SALES_PURCHASE_ORDER_SUCCESS';
export const FETCH_CORPORATE_SALES_PURCHASE_ORDER_FAILURE = '@@corporateSalesQuotation/FETCH_CORPORATE_SALES_PURCHASE_ORDER_FAILURE';

export const fetchPurchaseOrder = (page, size, searchConstruct, filterValues) => ({
    type: FETCH_CORPORATE_SALES_PURCHASE_ORDER_REQUEST,
    api: api.CORPORATE_SALES.PURCHASE_ORDER.PAGE,
    page,
    size,
    searchConstruct,
    filterValues,
});
