import initialStates from '../../initialStates';
import {
    FETCH_CORPORATE_SALES_DELIVERY_NOTES_SUCCESS,
    FETCH_CORPORATE_SALES_DELIVERY_NOTES_FAILURE,
} from './corporateSalesDeliveryNotes-actions';

const corporateSalesDeliveryNotesReducers = (state = initialStates.corporateSalesDeliveryNotes, action) => {
    let newState;
    switch (action.type) {
    case FETCH_CORPORATE_SALES_DELIVERY_NOTES_SUCCESS:
        newState = {
            list: action.data.content,
            first: action.data.first,
            last: action.data.last,
            forwardSeekMap: action.data.forwardSeekMap,
            backwardSeekMap: action.data.backwardSeekMap,
            page: action.page,
            size: action.size,
            filterValues: action.filterValues,
        };
        break;
    case FETCH_CORPORATE_SALES_DELIVERY_NOTES_FAILURE:
        newState = { ...initialStates.debitAndCreditLines };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default corporateSalesDeliveryNotesReducers;
