import uuid from 'uuid/v4';
import cloneDeep from 'clone-deep';
import deepEqual from 'react-fast-compare';
import safeEval from '@packages/safe-eval';

import {
    getIndexFromFieldName,
    isArrayValidAndNotEmpty,
    isValidFunction,
    roundedValue,
    roundedValueFixedToTwoDigits,
    valueToFixedTwoDigits,
} from '../../constants/CommonUtil';
import { PURCHASE_ORDER_LINE_UI_OBJECT } from '../../mapper/PurchaseOrderMapper';
import { warningMessage } from '../../redux/modules/message/message-actions';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const EMPTY_ROW = {
    product: '',
    description: '',
    uom: '',
    assetCategory: '',
    quantity: '',
    rate: '',
    amount: '',
    amountBeforeTax: '',
    taxes: '',
    total: '',
    visible: true,
};

export const applyDiscountBasedOnConfig = (
    autoDiscountConfig,
    product,
    rowValue,
) => {
    const productType = getStringFromObject('type', product);
    const updatedRow = {
        ...rowValue,
    };
    if (
        isObjectValidAndNotEmpty(autoDiscountConfig) &&
        isArrayValidAndNotEmpty(autoDiscountConfig[productType])
    ) {
        const matchingCondition = autoDiscountConfig[productType].find((aCondition) => {
            const {
                fieldToCheck,
                operation,
                value,
                ignoreProducts,
                multipleOperations,
                operationList,
            } = aCondition;
            // exception
            if (isArrayValidAndNotEmpty(ignoreProducts) && ignoreProducts.includes(product.uuid)) {
                return false;
            }
            let evaluationString = '';
            const productValForField = getStringFromObject(fieldToCheck, product);
            if (multipleOperations) {
                if (!isArrayValidAndNotEmpty(operationList)) return false;
                const operationsStrings = operationList.map(
                    anOperation => `${productValForField}${anOperation.operation}${anOperation.value}`,
                );
                evaluationString = operationsStrings.join('&&');
            } else {
                evaluationString = `${productValForField}${operation}${value}`;
            }
            try {
                return safeEval(evaluationString);
            } catch (e) {
                console.error('error evaluating expression', evaluationString);
            }
            return false;
        });
        console.log('matching condition', matchingCondition);
        if (isObjectValidAndNotEmpty(matchingCondition)) {
            updatedRow.discount = matchingCondition.discount;
        }
    }
    return updatedRow;
};

export const calculateCurrentCost = (rate, discountAmount, discountAmount2, qty, bonus) => {
    const cost = ((NumberOf(rate) * qty) - discountAmount - discountAmount2) / (qty + NumberOf(bonus));
    return NumberOf(roundedValueFixedToTwoDigits(cost));
};

export const calculateNewExpectedWeightedCost = (newCost, newQty, weightedCost, weightedQty) => {
    const newWeightedCost = roundedValueFixedToTwoDigits((newCost * newQty) + (weightedCost * weightedQty)) / (newQty + weightedQty);
    console.log('🚀 - newWeightedCost:', newWeightedCost);
    return roundedValueFixedToTwoDigits(newWeightedCost);
};

export const getRateAndTotals = (newRowValue, quantity, taxes, discountPerc, discountPerc2) => {
    const rate = NumberOf(getStringFromObject('rate', newRowValue, 0));
    const amountWithoutTax = (quantity * rate);
    const discount = NumberOf(discountPerc);
    const discount2 = NumberOf(discountPerc2);
    const discountAmount = roundedValue(amountWithoutTax * (discount / 100));
    const discountAmount2 = roundedValue((amountWithoutTax - discountAmount) * (discount2 / 100));
    const amountBeforeTax = roundedValue(amountWithoutTax - (discountAmount + discountAmount2));
    let applicableTax = 0;
    if (isArrayValidAndNotEmpty(taxes)) {
        taxes.forEach((tax) => {
            applicableTax += NumberOf(tax.amount);
        });
    }
    const taxAmount = amountBeforeTax * applicableTax;
    const cost = calculateCurrentCost(newRowValue.rate, discountAmount, discountAmount2, newRowValue.quantity, newRowValue.bonus);
    const newCost = calculateNewExpectedWeightedCost(cost, newRowValue.quantity, newRowValue.cost, newRowValue.qtyAtWhichWeightedCostIsCalculated);
    return {
        ...newRowValue,
        rate: valueToFixedTwoDigits(rate),
        discount,
        discount2,
        discountAmount: valueToFixedTwoDigits(discountAmount),
        discountAmount2: valueToFixedTwoDigits(discountAmount2),
        amount: roundedValue(amountWithoutTax),
        amountBeforeTax: roundedValue(amountBeforeTax),
        taxAmount: roundedValue(taxAmount),
        total: roundedValue(amountBeforeTax + taxAmount),
        newCost,
    };
};

const setPurchaseOrderLine = (rowValue, index, form) => {
    const newRowValue = cloneDeep(rowValue);
    const isRateEditedManually = getStringFromObject('isRateEditedManually', newRowValue, false);
    const setFieldValue = getStringFromObject('setFieldValue', form);
    if (!isValidFunction(setFieldValue)) {
        return;
    }
    const valuesLength = getStringFromObject('values.purchaseOrderLines.length', form);
    if (index === (valuesLength - 1).toString()) {
        // Add row automatically..
        setFieldValue(`purchaseOrderLines.${valuesLength}`, cloneDeep(EMPTY_ROW));
    }
    if (isObjectValidAndNotEmpty(newRowValue) && isValidFunction(setFieldValue)) {
        const product = getStringFromObject('product', newRowValue, null);
        const quantity = Math.max(NumberOf(newRowValue.quantity), 0);
        const uom =
            isObjectValidAndNotEmpty(newRowValue.uom) ?
                newRowValue.uom :
                getStringFromObject('erpUomDto', product);
        // const factor = uom && uom.factor ? Number(uom.factor) : 1;
        const taxes = newRowValue.taxes || [];
        newRowValue.quantity = quantity;
        newRowValue.uom = uom;
        newRowValue.discount = NumberOf(newRowValue.discount);
        newRowValue.paramMap = { productUomCategroyId: getStringFromObject('erpUomDto.category.key', product) };
        // Resetting after tax calculation
        if (isRateEditedManually) {
            setFieldValue(`purchaseOrderLines.${index}`, {
                ...newRowValue,
                ...getRateAndTotals(newRowValue, quantity, taxes, rowValue.discount, rowValue.discount2),
            });
        } else {
            let applicableTax = 0;
            if (isArrayValidAndNotEmpty(taxes)) {
                taxes.forEach((tax) => {
                    applicableTax += NumberOf(tax.amount);
                });
            }
            const totalBeforeTax = Math.max(NumberOf(newRowValue.amountBeforeTax), 1);
            const rate = NumberOf(newRowValue.rate);
            const amount = roundedValue(quantity * rate);
            if (rate <= 0 || totalBeforeTax > amount) {
                return;
            }
            let totalBeforeTaxBeforeDiscount2 = totalBeforeTax;
            const disocunt2 = NumberOf(newRowValue.discount2);
            if (disocunt2) {
                totalBeforeTaxBeforeDiscount2 = totalBeforeTax / (1 - (disocunt2 / 100));
            }
            const discountAmount = amount - totalBeforeTaxBeforeDiscount2;
            const discount = roundedValue((discountAmount / amount) * 100);
            newRowValue.amount = valueToFixedTwoDigits(amount);
            newRowValue.discount = valueToFixedTwoDigits(discount);
            newRowValue.discountAmount = valueToFixedTwoDigits(discountAmount);
            newRowValue.discountAmount2 = valueToFixedTwoDigits(totalBeforeTaxBeforeDiscount2 * (disocunt2 / 100));
            newRowValue.taxAmount = valueToFixedTwoDigits(totalBeforeTax * applicableTax);
            newRowValue.total =
                valueToFixedTwoDigits(totalBeforeTax + (totalBeforeTax * applicableTax));
            setFieldValue(`purchaseOrderLines.${index}`, newRowValue);
        }
    }
    console.log('as-d0aidak[psdad', newRowValue);
};

// Autocomplete on select handlers
const handleProductSelect = (product, fieldPath, form, actionParams, autoDiscountConfig) => {
    const { values } = form;
    const { purchaseOrderLines } = values;
    let unique = true;
    purchaseOrderLines.forEach((a) => {
        if (deepEqual(a.product, product)) {
            unique = false;
        }
    });
    if (isObjectValidAndNotEmpty(product) && fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        console.log('jksdfhlakjsdfhksj', index, form, actionParams);
        if (index != null) {
            if (unique) {
                let rowValue = cloneDeep(getStringFromObject(`values.purchaseOrderLines.${index}`, form, {}));
                rowValue.product = product;
                rowValue.isRateEditedManually = true;
                rowValue.bonusPercentage = getStringFromObject('bonusPercentage', product, 0);
                rowValue.quantity = Math.max(NumberOf(rowValue.quantity), 1);
                if (NumberOf(rowValue.bonusPercentage) && NumberOf(rowValue.quantity)) {
                    rowValue.bonus = roundedValueFixedToTwoDigits(rowValue.quantity * (rowValue.bonusPercentage / 100));
                }
                rowValue.uom = getStringFromObject('erpUomDto', product, null);
                rowValue.rate = NumberOf(getStringFromObject('price', product, 0));
                rowValue.cost = NumberOf(getStringFromObject('cost', product, 0));
                rowValue.taxes = getStringFromObject('taxes', product, []);
                rowValue.uiUuid = uuid();
                // TODO need to make this discount a configuration
                if (NumberOf(product.purchaseDiscount)) {
                    rowValue.discount = NumberOf(product.purchaseDiscount);
                } else {
                    rowValue = applyDiscountBasedOnConfig(autoDiscountConfig, product, rowValue);
                }
                console.log('asda9uda-uds-s', rowValue);
                setPurchaseOrderLine(rowValue, index, form);
            } else {
                const setFieldValue = getStringFromObject('setFieldValue', form);
                if (isValidFunction(setFieldValue)) {
                    setFieldValue(`purchaseOrderLines.${index}`, cloneDeep(EMPTY_ROW));
                }
                const dispatcher = getStringFromObject('dispatch', actionParams);
                if (isValidFunction(dispatcher(warningMessage(
                    `Product '${getStringFromObject('productName', product)}' already added !!`,
                ))));
            }
        }
    }
};

const handleUomSelect = (value, fieldPath, form) => {
    if (isObjectValidAndNotEmpty(value) && fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.purchaseOrderLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.uom = value;
                rowValue.isRateEditedManually = true;
                setPurchaseOrderLine(rowValue, index, form);
            }
        }
    }
};

const handleTaxSelect = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.purchaseOrderLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.taxes = Array.isArray(value) ? value : [];
                rowValue.isRateEditedManually = true;
                setPurchaseOrderLine(rowValue, index, form);
            }
        }
    }
};

export const calculateBonusPercentage = (bonusQty, quantity) => {
    if (NumberOf(quantity) === 0) {
        return '';
    }
    const bonus = roundedValueFixedToTwoDigits(NumberOf(NumberOf(bonusQty) / NumberOf(quantity)) * 100);
    return `${bonus}%`;
};

const bonusChangeHandler = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.purchaseOrderLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.bonus = value;
                rowValue.bonusPercentage = calculateBonusPercentage(value, rowValue.quantity);
                setPurchaseOrderLine(rowValue, index, form);
            }
        }
    }
};

const quantityChangeHandler = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.purchaseOrderLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.quantity = value;
                rowValue.isRateEditedManually = true;
                if (NumberOf(rowValue.bonusPercentage) && NumberOf(value)) {
                    rowValue.bonus = roundedValueFixedToTwoDigits(value * (rowValue.bonusPercentage / 100));
                }
                setPurchaseOrderLine(rowValue, index, form);
            }
        }
    }
};

const discountChangeHandler = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.purchaseOrderLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.discount = NumberOf(value);
                rowValue.isRateEditedManually = true;
                setPurchaseOrderLine(rowValue, index, form);
            }
        }
    }
};
const discountChangeHandler2 = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.purchaseOrderLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.discount2 = NumberOf(value);
                rowValue.isRateEditedManually = true;
                setPurchaseOrderLine(rowValue, index, form);
            }
        }
    }
};

const rateChangeHandler = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.purchaseOrderLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.rate = value;
                rowValue.isRateEditedManually = true;
                setPurchaseOrderLine(rowValue, index, form);
            }
        }
    }
};

const rowChangeHandler = (value, fieldPath, form) => {
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const setFieldValue = getStringFromObject('setFieldValue', form);
        const index = getIndexFromFieldName(fieldPath);
        if (index != null && isValidFunction(setFieldValue)) {
            const rowValue = cloneDeep(getStringFromObject(`values.purchaseOrderLines.${index}`, form, {}));
            let operation = rowValue.operation || 1;
            if (isObjectValidAndNotEmpty(rowValue)) {
                if (rowValue.uuid) {
                    operation = 2;
                }
            }
            setFieldValue(`purchaseOrderLines.${index}.operation`, operation);
        }
    }
};

const deleteRowHandler = (selectedRows, form, instance) => {
    const setFieldValue = getStringFromObject('setFieldValue', form);
    const values = cloneDeep(getStringFromObject('values.purchaseOrderLines', form, []));
    if (isArrayValidAndNotEmpty(selectedRows) && isValidFunction(setFieldValue) && isArrayValidAndNotEmpty(values)) {
        selectedRows.forEach((idx) => {
            values.splice(idx, 1);
            if (values.length === 0) {
                values.push(PURCHASE_ORDER_LINE_UI_OBJECT);
            }
            if (instance != null) {
                instance.setState({ selected: selectedRows.splice(idx, -1) });
            }
        });
        setFieldValue('purchaseOrderLines', values);
    }
};

const deleteUnusedRowHandler = (selected, form) => {
    const { values, setFieldValue } = form;
    const { purchaseOrderLines } = values;
    console.log('jkasdfaljajksdfhasdf', selected, form, purchaseOrderLines);
    const newPoLines = [];
    purchaseOrderLines.forEach((a) => {
        if (isObjectValidAndNotEmpty(a.product)) {
            newPoLines.push(a);
        }
    });
    setFieldValue('purchaseOrderLines', newPoLines);
};

const deleteRowWithZeroQuantity = (selected, form) => {
    const { values, setFieldValue } = form;
    const { purchaseOrderLines } = values;
    let lines = [];
    if (isArrayValidAndNotEmpty(purchaseOrderLines)) {
        lines = purchaseOrderLines.filter(l => NumberOf(l.quantity) > 0);
    }
    setFieldValue('purchaseOrderLines', lines);
};

const totalChangeHandler = (value, fieldPath, form) => {
    console.log('klalsdfaskldf', value, fieldPath, form);
    if (fieldPath && isObjectValidAndNotEmpty(form)) {
        const index = getIndexFromFieldName(fieldPath);
        if (index != null) {
            const rowValue = cloneDeep(getStringFromObject(`values.purchaseOrderLines.${index}`, form, {}));
            if (isObjectValidAndNotEmpty(rowValue)) {
                rowValue.amountBeforeTax = value;
                rowValue.isRateEditedManually = false;
                setPurchaseOrderLine(rowValue, index, form);
            }
        }
    }
};

export const ACTION_HANDLERS = autoDiscountConfig => ({
    handleProductSelect: (product, fieldPath, form, actionParams) => {
        handleProductSelect(product, fieldPath, form, actionParams, autoDiscountConfig);
    },
    rateChangeHandler,
    discountChangeHandler,
    discountChangeHandler2,
    quantityChangeHandler,
    handleUomSelect,
    rowChangeHandler,
    deleteRowHandler,
    handleTaxSelect,
    deleteUnusedRowHandler,
    totalChangeHandler,
    deleteRowWithZeroQuantity,
    bonusChangeHandler,
});

export default ACTION_HANDLERS;
