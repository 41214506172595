import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    getUrlWithApiParams,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { addSeekDirectionAndParamsToUrlParams } from '../../constants/UrlUtil';
import {
    FETCH_CORPORATE_SALES_ACCOUNT_INVOICE_FAILURE,
    FETCH_CORPORATE_SALES_ACCOUNT_INVOICE_SUCCESS,
} from '../../redux/modules/corporateSalesAccountInvoice/corporateSalesAccountInvoice-actions';

function* fetchCorporateSalesAccountInvoiceWorker(action) {
    try {
        const {
            api,
            page,
            size,
            searchConstruct,
            filterValues,
            seekParams,
            seekDirection,
        } = action;
        const urlWithApiWithParams = getUrlWithApiParams(api, { size });
        const apiWithParams = addSeekDirectionAndParamsToUrlParams(urlWithApiWithParams, seekDirection, seekParams);
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(axios.post, apiWithParams, searchConstruct);
        yield put({
            type: FETCH_CORPORATE_SALES_ACCOUNT_INVOICE_SUCCESS,
            data: {
                ...(response.data || {}),
            },
            page,
            size,
            filterValues,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = 'Invoices fetch failed';
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: FETCH_CORPORATE_SALES_ACCOUNT_INVOICE_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export default fetchCorporateSalesAccountInvoiceWorker;
