/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events, no-unused-vars */

import React from 'react';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Visibility from '@material-ui/icons/Visibility';
// import ReactToggle from '../ReduxFormToggle/ReactToggle';
import { green, red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ArrowRight from '@material-ui/icons/ArrowRight';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Download from '@material-ui/icons/CloudDownload';
import InfoIcon from '@material-ui/icons/Info';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CancelRounded from '@material-ui/icons/CancelRounded';
import EditIcon from '@material-ui/icons/Edit';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button/Button';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

import { getAppointmentColor } from '../../constants/constants';
import {
    getFirstLettersOfWordsInString, getPayerTypeColor,
    getPayerTypeColorForInsurance,
    getStringForBoolean,
    isArrayValidAndNotEmpty,
    isStringNullOrUndefined,
    isValidFunction,
    roundedValueFixedToTwoDigits,
    toTitleCase,
} from '../../constants/CommonUtil';
import {
    formatDate,
    formatDateForDisplay,
    formatDateTimeForDisplay,
    formatTimeForDisplay,
    isDateValid, parseDate,
} from '../../constants/DateUtil';
import AppointmentTokenTimeBox from '../AppointmentsComponent/AppointmentTokenTimeBox/AppointmentTokenTimeBox';
import MenuActionButton from '../FormFieldComponents/MenuActionButton/MenuActionButton';
import ActionButton from '../ActionButton/ActionButton';
import { getAvatarColor } from './TabelRowUtil';
import LeavePlanDisplay from '../../containers/EmployeeLeavesContainer/LeavePlanDisplay';
import ConsultationStatusDisplayContainer
    from '../ConsultationStatusDisplayComponent/ConsultationStatusDisplayContainer';
import CopiableText from '../CopiableText/CopiableText';
import { payerTypes } from '../../containers/RegistrationAppComponents/QuickRegistration/QuickRegistrationForm/QuickRegistrationFormUtil';
import { round } from '../../constants/PrecisionUtil';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';
import { checkIfPrivilegeExistsForUser } from '../../constants/privilegeChecker';
import MenuActionButtonV2 from '../FormFieldComponents/MenuActionButtonV2/MenuActionButtonV2';

const ICON_ACTIONS = {
    ERROR: 'ERROR',
    WARNING: 'WARNING',
    CLOSE: 'CLOSE',
    EDIT: 'EDIT',
    INFO: 'INFO',
    VIEW: 'VIEW',
    DOWNLOAD: 'DOWNLOAD',
    DELETE: 'DELETE',
    PRINT: 'PRINT',
};


const style = theme => ({
    error: {
        color: '',
    },
    largeFont: {
        fontSize: '18px !important',
    },
    warning: {
        color: '',
    },
    hover: {
        '&:hover': {
            backgroundColor: 'rgb(208, 231, 232)',
        },
        height: '35px',
    },
    button: {
        color: theme.palette.common.white,
        textTransform: 'initial',
        fontSize: '0.8rem',
        minHeight: '2em',
        height: '2em',
        minWidth: '5em',
        padding: '0 16px',
    },
});

const shouldHide = (cell, rowValue) => {
    if (cell.hideIf) {
        const { hideIf } = cell;
        if (hideIf && hideIf.lhsKey && hideIf.rhsKey) {
            const lhs = getStringFromObject(hideIf.lhsKey, rowValue, null);
            const rhs = getStringFromObject(hideIf.rhsKey, rowValue, null);
            return lhs === rhs;
        } else if (hideIf && hideIf.key) {
            console.log('as0-dia-sdisa', hideIf.key, hideIf.value, rowValue);
            return getStringFromObject(hideIf.key, rowValue) === hideIf.value;
        }
    }
    return false;
};

class MaterialTableRow extends React.PureComponent {
    constructor(props) {
        super(props);
        this.rowRef = React.createRef();
        this.state = {
            highlightRow: false,
            expandRow: props.alwaysExpand,
        };
    }

    componentDidMount() {
        console.log('RefFoucs', this.rowRef.current);
    }

    onRowClick = (e, index, uniqueKey, data) => {
        console.log('jkladlfkjsdhflk', e, index, uniqueKey);
        if (this.props.expandOnRowClick) {
            this.setState(state => ({ expandRow: !state.expandRow }));
        }
        if (isValidFunction(this.props.onRowClick)) {
            this.props.onRowClick(e, index, uniqueKey, data);
        }
    };

    onToggleHighlight = (e) => {
        console.log('asd9-dua09dusjdaas', e);
        this.setState(prevState => ({
            highlightRow: !prevState.highlightRow,
        }));
    };

    onCheckBoxClick = (event, index, data) => {
        event.stopPropagation();
        if (this.props.onCheckBoxClick) {
            this.props.onCheckBoxClick(event, index, data);
        }
    };

    // this is used in the search dialog box of registration v2..
    // this is for keyboard friendly and enter on selection of a patient
    onCheckBoxEnter = (e, index, data) => {
        if ((e.keyCode === 13 || e.which === 13) && e.shiftKey === false) {
            console.log('sdasuds0-aduas-d0s', index, data);
            e.stopPropagation();
            if (this.props.onRowClick) {
                this.props.onRowClick(e, index);
            }
        }
    };

    getChoices = (choices, data) => choices.filter((choice) => {
        if (choice.showIf) {
            return getStringFromObject(choice.showIf, data, false);
        }
        if (choice.showIfNot) {
            return !getStringFromObject(choice.showIfNot, data, false);
        }
        return true;
    });

    isToBeHide = (category) => {
        console.log('category: ', category);
        if (category.hideIf) {
            const { data } = this.props;
            const { hideIf } = category;
            if (hideIf.isNull && (!data[hideIf.key])) {
                return true;
            }
            if (hideIf.isNotNull && (data[hideIf.key])) {
                return true;
            }
            if (hideIf.isEmptyArray && (data[hideIf.key])) {
                return !isArrayValidAndNotEmpty(data[hideIf.key]);
            }
            if (isArrayValidAndNotEmpty(hideIf)) {
                console.log('asd-0aod-as0da[', hideIf);
                for (let i = 0; i < hideIf.length; i += 1) {
                    const hideIfVal = getStringFromObject(hideIf[i].key, data) === hideIf[i].value;
                    console.log('asd-0aod-as0da[', getStringFromObject(hideIf[i].key, data), hideIf[i].value, hideIfVal);
                    if (hideIfVal) {
                        return true;
                    }
                }
                return false;
            }
            // value could be one of many
            if (isArrayValidAndNotEmpty(hideIf.value) && hideIf.value.includes(data[hideIf.key])) {
                return true;
            }
            if (data[hideIf.key] === hideIf.value) {
                console.log('rowValueHideIf', hideIf, data);
                return true;
            }
        }
        return false;
    };

    isToBeShown = (category) => {
        console.log('asda-dais-d0a', category.showOnlyIf);
        let showIt = true;
        if (isObjectValidAndNotEmpty(category.showOnlyIf)) {
            showIt = this.isToBeHide({ ...category, hideIf: category.showOnlyIf });
        }
        if (showIt && isArrayValidAndNotEmpty(category.privilege)) {
            showIt = checkIfPrivilegeExistsForUser(category.privilege);
        }
        return showIt;
    };
    handleInternalRowExpand = () => {
        this.setState(state => ({ expandRow: !state.expandRow }));
    };

    handleSelect = (value, event) => {
        const { handleSelectAction, index, data } = this.props;
        handleSelectAction(value, index, event, data);
    };

    showData = (field, data, isExpandable) => {
        const { expandRow } = this.state;
        const {
            classes,
            handleRowExpand,
            index,
            handleTextButtonClick,
            handleIconAction,
            handleRowButtonClick,
        } = this.props;
        let appointmentColorElement = null;
        let textToDisplay = '';
        if (isArrayValidAndNotEmpty(data)) {
            textToDisplay = data.map(d => getStringFromObject(field.find, d)).filter(Boolean).join(', ');
        } else {
            textToDisplay = getStringFromObject(field.find, data, field.default || '');
        }
        if (isStringNullOrUndefined(textToDisplay) && !isStringNullOrUndefined(field.alternateFind)) {
            textToDisplay = getStringFromObject(field.alternateFind, data, field.alternateDefault || '');
        }
        let validation = false;
        let appointmentTime = '';
        let slotTimes = [];
        let slotType = '';
        let tokenNumber = '';
        const avatarStyle = {
            width: '30px',
            height: '30px',
            fontSize: '1rem',
            color: '#fff',
        };
        let stringArray = '';
        let menuChoicesV2 = [];
        if (field.fieldType === 'menuActionButtonV2' && isArrayValidAndNotEmpty(field.choices)) {
            menuChoicesV2 = this.getChoices(field.choices, data);
            menuChoicesV2 = menuChoicesV2.filter((choice) => {
                if (
                    !this.isToBeHide(choice) &&
                    this.isToBeShown(choice)
                ) {
                    return true;
                }
                return false;
            });
        }

        switch (field.fieldType) {
        case 'text':
            if (field.isDate) {
                textToDisplay = formatDateForDisplay(new Date(textToDisplay));
            }
            if (field.removeUnderscores) {
                textToDisplay = textToDisplay.replaceAll('_', ' ');
            }
            if (field.makeTitleCase) {
                textToDisplay = toTitleCase(textToDisplay);
            }
            return textToDisplay;
        case 'stringArray':
            if (isArrayValidAndNotEmpty(textToDisplay)) {
                textToDisplay.forEach((sA) => {
                    stringArray = `${stringArray}<br/>${sA}`;
                });
                stringArray = stringArray.slice(5);
            } else {
                stringArray = 'failed';
            }
            console.log('stringArray', stringArray);
            return (
                <div dangerouslySetInnerHTML={{ __html: stringArray }} />
            );
        case 'html':
            if (field.isDate) {
                textToDisplay = formatDateForDisplay(new Date(textToDisplay));
            }
            return (
                <div dangerouslySetInnerHTML={{ __html: textToDisplay }} />
            );
        case 'encounterStatus':
            return (
                <ConsultationStatusDisplayContainer
                    row={data}
                />
            );
        case 'copiable':
            return (
                <CopiableText
                    setHtmlDangerously={field.setHtmlDangerously}
                    textToCopy={textToDisplay}
                />
            );
        case 'boolean':
            textToDisplay = getStringForBoolean(textToDisplay);
            return textToDisplay;
        case 'leavePlan':
            return (
                <LeavePlanDisplay
                    data={data}
                />
            );
        case 'avatar':
            textToDisplay = (
                <Tooltip title={textToDisplay}>
                    <Avatar
                        style={{
                            ...avatarStyle,
                            backgroundColor:
                                    getAvatarColor(
                                        field.colorSchema,
                                        textToDisplay,
                                        field.colorSchemaSpecialCase,
                                        data,
                                    ),
                        }}
                    >
                        {
                            getFirstLettersOfWordsInString(textToDisplay)
                        }
                    </Avatar>
                </Tooltip>
            );
            return textToDisplay;
        case 'tooltip':
            textToDisplay = getStringFromObject(field.tooltipText, data) ? (
                <Tooltip title={getStringFromObject(field.tooltipText, data)}>
                    <span>{textToDisplay}</span>
                </Tooltip>
            ) : <span>{textToDisplay}</span>;
            return textToDisplay;
        case 'time':
            return (textToDisplay || textToDisplay === 0) ? formatTimeForDisplay(new Date(textToDisplay)) : '';
        case 'appointmentSlot':
            console.log('asdasda-ia0-dia0di0dd', data);
            slotTimes = getStringFromObject('slotTimes', data) || [];
            slotType = getStringFromObject('appointmentType', data) || '';
            tokenNumber = (getStringFromObject('tokenNumbers', data) || []).join(', ');
            if (isArrayValidAndNotEmpty(slotTimes)) {
                appointmentTime = slotTimes.map((aSlotTime) => {
                    if (aSlotTime) {
                        return formatTimeForDisplay(new Date(aSlotTime));
                    }
                    return 'NO-SLOT';
                }).join(', ');
            } else {
                appointmentTime = 'NO-SLOT';
            }
            return (
                <div className="flexWithCenterAligned">
                    <AppointmentTokenTimeBox
                        slotType={slotType}
                        timeSlot={appointmentTime}
                        tokenNumber={tokenNumber}
                        available
                        token={data}
                        boxStyle={{
                            width: '100%',
                            margin: 0,
                            boxShadow: 'none',
                            marginRight: '1rem',
                        }}
                        hideTime={Boolean(field.hideTime)}
                    />
                </div>
            );
        case 'datetime':
            if ((textToDisplay || textToDisplay === 0) && isDateValid(new Date(textToDisplay))) {
                textToDisplay = formatDateTimeForDisplay(new Date(textToDisplay));
            } else {
                textToDisplay = '';
            }
            return <div>{textToDisplay}</div>;
        case 'monthYear':
            if ((textToDisplay || textToDisplay === 0) && isDateValid(parseDate(textToDisplay, 'dd/MM/yyyy'))) {
                textToDisplay = formatDate(parseDate(textToDisplay, 'dd/MM/yyyy'), 'MMM-yyyy');
            } else {
                textToDisplay = '';
            }
            return <div>{textToDisplay}</div>;
        case 'price':
            return <div>{roundedValueFixedToTwoDigits(NumberOf(textToDisplay))}</div>;
        case 'roundedNumber':
            return <div>{round(textToDisplay)}</div>;
        case 'date':
            if (isDateValid(new Date(textToDisplay))) {
                textToDisplay = formatDateForDisplay(new Date(textToDisplay));
            } else {
                textToDisplay = '';
            }
            return <div>{textToDisplay}</div>;
        case 'statusButton':
            console.log('asd0909opksld;a,sdasd', textToDisplay, data, field.find);
            appointmentColorElement =
                    getAppointmentColor[(textToDisplay || '').toLowerCase()];
            return (
                <Button
                    className={classes.button}
                    style={{ backgroundColor: appointmentColorElement }}
                >
                    {textToDisplay}
                </Button>
            );
        case 'expandedIconText':
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ArrowRight
                        onClick={() => this.handleInternalRowExpand(index)}
                        style={{
                            transform: expandRow ? 'rotate(90deg)' : 'rotate(0deg)',
                            transition: 'all 0.5s',
                            fontSize: '2rem',
                            cursor: 'pointer',
                            color: '#6395CA',
                            visibility: (!isExpandable) ? 'hidden' : 'visible',
                        }}
                    />
                    <div>{field.isDate ? formatDateForDisplay(new Date(textToDisplay)) : textToDisplay}</div>
                </div>
            );
        case 'textButton':
            return (
                <Button
                    color="primary"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleTextButtonClick(index);
                    }}
                >
                        Write Off
                </Button>
            );
        case 'menuActionButton':
            return (
                <MenuActionButton
                    choices={this.getChoices(field.choices, data)}
                    dataSourceConfig={field.dataSourceConfig}
                    handleSelectAction={this.handleSelect}
                    label={field.label}
                    rowValue={data}
                    input={{
                        onChange: () => { },
                    }}
                />
            );
        case 'menuActionButtonV2':
            if (menuChoicesV2.length < 1) {
                return false;
            }
            return (
                <MenuActionButtonV2
                    choices={menuChoicesV2}
                    dataSourceConfig={field.dataSourceConfig}
                    handleSelectAction={this.handleSelect}
                    label={field.label}
                    rowValue={data}
                    input={{
                        onChange: () => { },
                    }}
                />
            );

        case 'closeIcon':
            return (
                <HighlightOff
                    fontSize="default"
                    color="primary"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleIconAction(e, ICON_ACTIONS.CLOSE, index, data);
                    }}
                />
            );
        case 'editIcon':
            return (
                <EditIcon
                    fontSize="default"
                    color="primary"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleIconAction(e, ICON_ACTIONS.EDIT, index, data);
                    }}
                />
            );
        case 'switchIcon':
            return (
                <Switch
                    disabled={field.disabled}
                    className={field.className}
                    color={field.color}
                    checked={field.checked}
                />
            );
        case 'infoIcon':
            return (
                <IconButton
                    onClick={e => handleIconAction(e, ICON_ACTIONS.INFO, index)}
                >
                    <InfoIcon
                        fontSize="default"
                        color="primary"
                    />
                </IconButton>
            );
        case 'downloadIcon':
            return (
                <Tooltip title="Download CSV">
                    <Download
                        fontSize="default"
                        onClick={e => handleIconAction(e, ICON_ACTIONS.INFO, index, data)}
                        color="primary"
                    />
                </Tooltip>
            );
        case 'checkMark':
            return (
                <Tooltip title={field.tooltip}>
                    <CheckCircle
                        nativeColor={field.nativeColor}
                    />
                </Tooltip>
            );
        case 'deleteIcon':
            return (
                <IconButton
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleIconAction(e, ICON_ACTIONS.DELETE, index, data);
                    }}
                >
                    <DeleteIcon
                        fontSize="default"
                        color="primary"
                    />
                </IconButton>
            );
        case 'printIcon':
            return (
                <IconButton
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleIconAction(e, ICON_ACTIONS.PRINT, index, data);
                    }}
                >
                    <PrintIcon
                        fontSize="default"
                        color="primary"
                    />
                </IconButton>
            );
        case 'viewIcon':
            return (
                <Tooltip title={field.buttonLabel}>
                    <IconButton
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleIconAction(e, ICON_ACTIONS.VIEW, index, data);
                        }}
                    >
                        <Visibility
                            fontSize="default"
                            color="primary"
                        />
                    </IconButton>
                </Tooltip>
            );
        case 'toggleCheck':
            validation = (getStringFromObject(field.checkField[0], data, null) === getStringFromObject(field.checkField[1], data, null));
            if (!getStringFromObject(field.checkField[0], data, null)) {
                return (
                    <CancelRounded style={{ color: red[500] }} />
                );
            }
            if (getStringFromObject(field.checkField[0], data, null) === getStringFromObject(field.checkField[1], data, null)) {
                return (
                    <CheckCircle style={{ color: green[500] }} />
                );
            }
            return (
                getStringFromObject(field.checkField[0], data, null) < getStringFromObject(field.checkField[1], data, null) ?
                    <ArrowDownward style={{ color: red[500] }} /> :
                    <ArrowUpward style={{ color: green[500] }} />
            );
        case 'actionButton':
            if (isArrayValidAndNotEmpty(field.hideIf)) {
                for (let i = 0; i < field.hideIf.length; i += 1) {
                    const hideIfCondition = field.hideIf[i];
                    const dataForField = getStringFromObject(hideIfCondition.fieldName, data);
                    if (isArrayValidAndNotEmpty(hideIfCondition.value)) {
                        if (hideIfCondition.value.includes(dataForField)) {
                            return field.showText || null;
                        }
                    } else if (dataForField === hideIfCondition.value) {
                        return field.showText || null;
                    }
                }
            }
            return (
                <ActionButton
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleRowButtonClick(index, data, field.name);
                    }}
                    test-id={`${field.name}[${index}]`}
                    name={field.name}
                >
                    {field.buttonLabel || field.label}
                </ActionButton>
            );
        // not for generic use or selecting row.
        // checked state is handled in parent component of MaterialTable
        case 'checkBox':
            return (
                <Checkbox
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleRowButtonClick(index, data, field.name);
                    }}
                    checked={Boolean(textToDisplay)}
                />
            );
        default:
            return <div>{textToDisplay}</div>;
        }
    };

    emphasizeRow = (schema, data) => {
        let retVal = false;
        if (data[schema.key] === schema.value) {
            retVal = true;
        }

        return retVal;
    };

    render() {
        const { expandRow, highlightRow } = this.state;
        const {
            isSelected,
            index,
            level,
            rowStyle,
            tableCells,
            hideCheckBoxIcon,
            emphasizeRow,
            data,
            uniqueKey,
            errorIcon,
            warningIcon,
            classes,
            getExpandableComponent,
            isExpandable,
            handleRowExpand,
            expandedRowIndex,
            handleIconAction,
            getExtraRows,
            retired,
            retiredValue,
            retiredColor,
            schema,
            tableName,
            colorSchemaForOPDQueue,
            rowColorPickerProperty,
            formularyItemTypeKeyColorsMap,
            getRowStyle,
            recentQueue,
        } = this.props;
        let rowStyleCopy = { ...rowStyle };
        let rowExpandable = (isExpandable || expandRow);
        const markedAsIncomplete = getStringFromObject('markedAsIncomplete', data, false);
        if ('isExpandable' in data) {
            rowExpandable = rowExpandable && data.isExpandable;
        }
        let expanded = false;
        if (expandedRowIndex === index || expandRow) {
            expanded = true;
        }
        if (retired && retiredValue !== null && retiredValue !== undefined &&
            getStringFromObject(retired, data) === retiredValue) {
            rowStyleCopy.background = retiredColor;
        } else if (isObjectValidAndNotEmpty(emphasizeRow) && isObjectValidAndNotEmpty(data)) {
            if (this.emphasizeRow(emphasizeRow, data)) {
                rowStyleCopy.background = !isStringNullOrUndefined(emphasizeRow.bgColor)
                    ?
                    emphasizeRow.bgColor
                    :
                    '#a1faeb';
            }
        } else if (schema.colorSchema === 'PayerType') {
            rowStyleCopy.background = getPayerTypeColorForInsurance(data.payerType);
        } else if (schema.colorSchema === 'PayerTypeRecentQueue') {
            const payerType = data.payerType || payerTypes.CASH;
            console.log('as0d9uas0d9usa', getPayerTypeColor(payerType, colorSchemaForOPDQueue), payerType, colorSchemaForOPDQueue);
            rowStyleCopy.background = getPayerTypeColor(payerType, colorSchemaForOPDQueue);
            rowStyleCopy.fontSize = '18px !important';
        } else if (schema.colorSchema === 'PayerTypeBillingHistoryTab') {
            const counterPartInvoiceUuid = getStringFromObject('counterPartInvoiceUuid', data) || false;
            const payerType = counterPartInvoiceUuid ? payerTypes.INSURANCE : payerTypes.CASH;
            rowStyleCopy.background = getPayerTypeColor(payerType, colorSchemaForOPDQueue);
        } else if (schema.colorSchema === 'productDispatchColorScheme') {
            rowStyleCopy.background = data.status === 'DONE' ? '#FFFFFF' : '#50C878';
        } else if (schema.colorSchema === 'excludedDiagnosisColorScheme') {
            rowStyleCopy.background = data.insuranceStatus === 'EXCLUDED' ? '#FFCCCB' : '#FFFFFF';
        } else {
            rowStyleCopy.background = '#FFFFFF';
        }

        if (rowColorPickerProperty && getStringFromObject(rowColorPickerProperty, data)) {
            rowStyleCopy.background = getStringFromObject(rowColorPickerProperty, data);
        }

        if (highlightRow && !rowStyleCopy.background) {
            rowStyleCopy.background = '#ededed';
        }

        if (
            schema.colorSchema === 'coPayUsageBorder' &&
            isObjectValidAndNotEmpty(formularyItemTypeKeyColorsMap) &&
            data.formularyItemTypeKey
        ) {
            rowStyleCopy.borderLeft = `${formularyItemTypeKeyColorsMap[data.formularyItemTypeKey]} 5px solid`;
        }
        if (recentQueue && markedAsIncomplete) {
            rowStyleCopy.borderLeft = '#FFD700 8px solid';
        }

        const rowStyleFromFunction = (isValidFunction(getRowStyle) ? getRowStyle(data) : {});
        rowStyleCopy = { ...rowStyleCopy, ...rowStyleFromFunction };
        console.log('Tesdahkfhdat', expandRow, expanded, rowStyle, rowStyleCopy);
        return (
            <React.Fragment>
                <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={index}
                    key={index}
                    className={classes.hover}
                    onClick={e => this.onRowClick(e, index, data[uniqueKey], data)}
                    selected={isSelected}
                    style={rowStyleCopy}
                    ref={this.rowRef}
                    test-id={`${tableName}[${index}]`}
                >
                    {
                        !hideCheckBoxIcon &&
                        <TableCell padding="checkbox" style={{ width: '1em' }}>
                            <Checkbox
                                disableRipple
                                checked={isSelected}
                                color="primary"
                                onFocus={this.onToggleHighlight}
                                onBlur={this.onToggleHighlight}
                                onClick={e => this.onCheckBoxClick(e, index, data[uniqueKey])}
                                onKeyDown={e => this.onCheckBoxEnter(e, index, data[uniqueKey])}
                                data-test-id={`${tableName}-cb-[${index}]`}
                            />
                        </TableCell>
                    }
                    {
                        (rowExpandable) && (isExpandable) &&
                        <TableCell padding="checkbox" style={{ width: '1em' }}>
                            <ArrowRight
                                onClick={() => handleRowExpand(index, data)}
                                style={{
                                    transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
                                    transition: 'all 0.5s',
                                    fontSize: '2rem',
                                    cursor: 'pointer',
                                    color: '#6395CA',
                                }}
                            />
                        </TableCell>
                    }
                    {
                        (isExpandable) && (!rowExpandable) &&
                        <TableCell padding="checkbox">
                            <ArrowRight
                                onClick={() => handleRowExpand(index, data)}
                                style={{
                                    transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
                                    transition: 'all 0.5s',
                                    fontSize: '2rem',
                                    cursor: 'pointer',
                                    color: '#6395CA',
                                    visibility: 'hidden',
                                }}
                            />
                        </TableCell>
                    }
                    {
                        tableCells && tableCells.map((cell) => {
                            const { cellStyle } = cell;
                            console.log('CellStyle', cell, shouldHide(cell, data));
                            let className = cell.error && data.error ? classes.error : '';
                            className = cell.warning && data.warning ? classes.warning : '';
                            className = cell.largeFont ? classes.largeFont : '';
                            return (
                                <TableCell
                                    component="td"
                                    key={cell.id}
                                    colSpan={cell.colSpan}
                                    test-id={`${tableName}[${index}].${cell.name}`}
                                    align={cell.align || 'left'}
                                    style={{ ...cellStyle }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: cell.align === 'right' ? 'flex-end' : 'unset',
                                        }}
                                    >
                                        {
                                            cell.error && data.error &&
                                            <div onClick={e => handleIconAction(e, ICON_ACTIONS.ERROR, index)}>
                                                {errorIcon}
                                            </div>
                                        }
                                        {
                                            cell.warning && data.warning &&
                                            <div onClick={e => handleIconAction(e, ICON_ACTIONS.WARNING, index)}>
                                                {warningIcon}
                                            </div>
                                        }
                                        <div className={className} style={cell.divStyle || {}}>
                                            {
                                                !shouldHide(cell, data) &&
                                                this.showData(cell, data, data.isExpandable)
                                            }
                                        </div>
                                    </div>
                                </TableCell>
                            );
                        })
                    }
                </TableRow>
                {
                    expanded && rowExpandable && getExpandableComponent &&
                    (
                        <TableRow style={{ border: 'none' }}>
                            <TableCell
                                colSpan={tableCells.length + 1 + (hideCheckBoxIcon ? 0 : 1)}
                                style={{ border: 'none', padding: '0px' }}
                            >
                                {
                                    getExpandableComponent(
                                        index,
                                        data,
                                        level,
                                        this.onRowClick,
                                        handleRowExpand,
                                    )
                                }
                            </TableCell>
                        </TableRow>
                    )
                }
                {
                    expanded && rowExpandable && getExtraRows && getExtraRows(index, data, level)
                }
            </React.Fragment>
        );
    }
}

MaterialTableRow.propTypes = {
    tableCells: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired,
    uniqueKey: PropTypes.string,
    isSelected: PropTypes.bool,
    rowStyle: PropTypes.object,
    schema: PropTypes.object,
    hideCheckBoxIcon: PropTypes.bool,
    data: PropTypes.object,
    level: PropTypes.number,
    onRowClick: PropTypes.func,
    errorIcon: PropTypes.node,
    alwaysExpand: PropTypes.bool,
    warningIcon: PropTypes.node,
    emphasizeRow: PropTypes.bool,
    getExpandableComponent: PropTypes.func,
    getExtraRows: PropTypes.func,
    handleIconAction: PropTypes.func,
    handleTextButtonClick: PropTypes.func,
    onCheckBoxClick: PropTypes.func,
    isExpandable: PropTypes.bool,
    expandOnRowClick: PropTypes.bool,
    handleRowExpand: PropTypes.func,
    handleRowButtonClick: PropTypes.func,
    handleSelectAction: PropTypes.func,
    expandedRowIndex: PropTypes.number,
    retired: PropTypes.string,
    retiredColor: PropTypes.string,
    tableName: PropTypes.string.isRequired,
    retiredValue: PropTypes.bool,
    colorSchemaForOPDQueue: PropTypes.object,
    rowColorPickerProperty: PropTypes.string,
    formularyItemTypeKeyColorsMap: PropTypes.object,
    getRowStyle: PropTypes.func,
    recentQueue: PropTypes.bool,
};

MaterialTableRow.defaultProps = {
    isSelected: false,
    handleIconAction: () => { },
    emphasizeRow: false,
    uniqueKey: '',
    rowStyle: {},
    schema: {},
    hideCheckBoxIcon: false,
    alwaysExpand: false,
    data: {},
    onRowClick: () => { },
    errorIcon: <React.Fragment />,
    warningIcon: <React.Fragment />,
    handleTextButtonClick: () => { },
    onCheckBoxClick: () => { },
    getExpandableComponent: null,
    getExtraRows: null,
    isExpandable: false,
    handleRowExpand: () => { },
    handleSelectAction: () => { },
    handleRowButtonClick: () => { },
    expandedRowIndex: null,
    expandOnRowClick: false,
    retired: null,
    retiredValue: null,
    retiredColor: 'rgb(255, 205, 210)',
    level: 1,
    colorSchemaForOPDQueue: {},
    rowColorPickerProperty: '',
    formularyItemTypeKeyColorsMap: {},
    getRowStyle: null,
    recentQueue: false,
};

export default withStyles(style)(MaterialTableRow);
