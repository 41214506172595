import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import deepEqual from 'react-fast-compare';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Grid from '@material-ui/core/Grid/Grid';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Close from '@material-ui/icons/Close';
import { Field, reduxForm, initialize, change } from 'redux-form';
import { Typography } from '@material-ui/core';

import ReduxFormReactSelectMaterial from '../ReduxFormReactSelectMaterial';
import API from '../../constants/api';
import { required } from '../../constants/FormValidations';
import {
    apiCatchBlockFunction,


} from '../../constants/CommonUtil';
import { mapTransferObjFromUiObj, mapUiObjFromTransferObj } from '../../mapper/AccountTransferMapper';
import {
    clearAccountTransfer,
    createAccountTransferRequest,
    fetchAccountTransfer,
} from '../../redux/modules/accountTransfer/accountTransfer-actions';
import ActionButton from '../ActionButton/ActionButton';
import { displayWarning } from '../../redux/modules/warningDialog/warningDialog-actions';
import { APPLICATION_CONFIG_URL } from '../../constants/constants';
import Print from '../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import { commonGetApiRequest } from '../../redux/modules/common/common-actions';
import { errorMessage } from '../../redux/modules/message/message-actions';
import { subCompanies } from '../../constants/ERPConstants';
import DateInput from '../FormFieldComponents/DateInput/DateInput';
import ReduxFormTextField from '../FormFieldComponents/ReduxFormTextField/ReduxFormTextField';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const formName = 'accountTransfer';

const style = theme => ({
    header: {
        fontSize: '1.5rem',
    },
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `1px solid ${theme.palette.borderColor}`,
    },
    reactSelectTextField: {
        padding: '0',
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `1px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '2px 6px',
    },
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        padding: '12px 24px',
    },
    dialogContent: {
        minWidth: '920px',
        marginTop: '1rem',
        overflow: 'visible',
    },
    buttonStyle: {
        height: '1.8rem',
        minWidth: '6rem',
        minHeight: '1rem',
        borderRadius: '1rem',
        fontSize: '0.8rem',
        padding: '4px 16px',
        textTransform: 'capitalize',
        marginLeft: '1rem',
        color: '#fff',
    },
    cancelButton: {
        border: `1px solid ${theme.palette.secondaryTextColor}`,
        color: theme.palette.secondaryTextColor,
    },
    dialog: {
        overflow: 'visible',
    },
    paper: {
        overflow: 'visible',
    },
});

class TransferDialog extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isView: props.isView || false,
            print: false,
            journalEntryPrintData: null,
            printJournal: false,
        };
    }

    componentDidMount() {
        const { dispatch, accountTransfer, accountMoveId } = this.props;
        const selectedAccount = getStringFromObject('selected', accountTransfer, null);
        if (accountMoveId) {
            dispatch(fetchAccountTransfer(accountMoveId));
        } else if (selectedAccount) {
            dispatch(initialize(formName, mapUiObjFromTransferObj(selectedAccount)));
        }
    }

    componentWillReceiveProps(nextProps) {
        const { dispatch } = this.props;
        const selectedAccount = getStringFromObject('accountTransfer.selected',
            this.props, null);
        const nextSelectedAccount = getStringFromObject('accountTransfer.selected',
            nextProps, null);
        if (!deepEqual(nextSelectedAccount, selectedAccount)) {
            this.setState({ isView: !!nextSelectedAccount });
            dispatch(initialize(formName, mapUiObjFromTransferObj(nextSelectedAccount)));
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(clearAccountTransfer());
    }

    getAccountBalance = async (id) => {
        const response = await axios.get(`${API.ACCOUNT_TRANSFER.GET_ACCOUNT_BALANCE}${id}`);
        return response;
    };

    fetchJournalEntriesAndPrint = () => {
        const { formValues, dispatch } = this.props;
        const { journalEntryPrintData } = this.state;
        const accountMoveUuid = getStringFromObject('accountMoveUuid', formValues);
        if (accountMoveUuid) {
            if (!isObjectValidAndNotEmpty(journalEntryPrintData)) {
                // not fetched.. fetch it first before proceeding
                dispatch(commonGetApiRequest(
                    `${API.ACCOUNT_MOVES.GET_ONE}${accountMoveUuid}`,
                    {
                        successCallback: (res) => {
                            this.setState(p => ({ journalEntryPrintData: res, printJournal: !p.printJournal }));
                        },
                    },
                ));
            } else {
                this.setState(p => ({ printJournal: !p.printJournal }));
            }
        }
    };

    reInitializeFormAndFocusUserField = () => {
        const { dispatch } = this.props;
        dispatch(initialize(formName, mapUiObjFromTransferObj({})));
        this.setState({ isView: false });
    };

    handleTransferFrom = (value) => {
        const { dispatch } = this.props;
        if (value && value.key) {
            this.getAccountBalance(value.key)
                .then((response) => {
                    dispatch(change(formName, 'balanceFrom', response.data));
                })
                .catch(error => apiCatchBlockFunction(error, dispatch));
        }
    };

    handleTransferTo = (value) => {
        const { dispatch } = this.props;
        if (value && value.key) {
            this.getAccountBalance(value.key)
                .then((response) => {
                    dispatch(change(formName, 'balanceTo', response.data));
                })
                .catch(error => apiCatchBlockFunction(error, dispatch));
        }
    };

    handleSave = (formValues) => {
        const { dispatch, handleClose } = this.props;
        const transferAmount = NumberOf(getStringFromObject('amount', formValues) || 0);
        if (transferAmount > 0) {
            dispatch(createAccountTransferRequest(mapTransferObjFromUiObj(formValues), handleClose));
        } else {
            dispatch(errorMessage('Enter a valid Amount'));
        }
    };

    loadBalance = () => {
        const { formValues } = this.props;
        this.handleTransferFrom(getStringFromObject('transferFrom', formValues, null));
        this.handleTransferTo(getStringFromObject('transferTo', formValues, null));
    };

    toggleEdit = () => {
        this.setState(p => ({ isView: !p.isView }), () => {
            if (!this.state.isView) {
                this.loadBalance();
            }
        });
    };

    handleCancel = () => {
        const { isView } = this.state;
        const { dispatch, reset } = this.props;
        if (!isView) {
            dispatch(displayWarning(
                'Are you sure want to cancel edit ? Any unsaved changes will be lost',
                () => {
                    this.toggleEdit();
                    reset();
                },
            ));
        }
    };

    handlePrint = () => this.setState(p => ({ print: !p.print }));

    render() {
        const {
            open,
            handleClose,
            classes,
            handleSubmit,
            formValues,
            company,
        } = this.props;
        const {
            isView,
            print,
            journalEntryPrintData,
            printJournal,
        } = this.state;
        const name = getStringFromObject('name', formValues);
        const isPeriodClosed = getStringFromObject('periodClosed', formValues, false);
        const isReconciled = getStringFromObject('reconciled', formValues, false);
        const subCompany = getStringFromObject('subCompany', formValues);
        console.log('asdfsdfsdfdsfdsf', handleSubmit);
        return (
            <React.Fragment>
                <form>
                    <Dialog
                        open={open}
                        maxWidth="md"
                        scroll="body"
                        aria-labelledby="form-dialog-title"
                        className={classes.dialog}
                        classes={{
                            paper: classes.paper,
                        }}
                    >
                        <DialogTitle id="form-dialog-title" className={classes.title}>
                            <Grid container justify="space-between">
                                <div>Fund Transfer &nbsp;{name && <span>({name})</span>}</div>
                                <Close className="cursor-pointer" onClick={handleClose} test-id="fund-transfer-close" />
                            </Grid>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <Grid container spacing={16}>
                                <Grid container item sm={12} spacing={16} justify="space-between" alignItems="center">
                                    <Grid item sm={6}>
                                        <Field
                                            testId="transferFrom"
                                            name="transferFrom"
                                            component={ReduxFormReactSelectMaterial}
                                            dataSourceConfig={{
                                                text: 'value',
                                                value: 'key',
                                            }}
                                            label="Transfer Fund From"
                                            selectProps={{
                                                textFieldProps: {
                                                    InputProps: {
                                                        disableUnderline: true,
                                                        classes: {
                                                            root: classes.reactSelectTextField,
                                                            input: classes.selectInput,
                                                        },
                                                    },
                                                },
                                            }}
                                            onSelect={this.handleTransferFrom}
                                            autocomplete
                                            dataSourceApi={`${API.SEARCH.ACCOUNT_HEADS}`}
                                            validate={[required]}
                                            isDisabled={isView}
                                        />
                                    </Grid>
                                    {!isView &&

                                        <Grid item sm={6}>
                                            <Field
                                                testId="balanceFrom"
                                                name="balanceFrom"
                                                component={ReduxFormTextField}
                                                label="Balance A"
                                                disabled
                                                fullWidth
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                                <Grid container item sm={12} spacing={16} justify="space-between" alignItems="center">
                                    <Grid item sm={6}>
                                        <Field
                                            testId="transferTo"
                                            name="transferTo"
                                            component={ReduxFormReactSelectMaterial}
                                            dataSourceConfig={{
                                                text: 'value',
                                                value: 'key',
                                            }}
                                            label="Transfer Fund To"
                                            selectProps={{
                                                textFieldProps: {
                                                    InputProps: {
                                                        disableUnderline: true,
                                                        classes: {
                                                            root: classes.reactSelectTextField,
                                                            input: classes.selectInput,
                                                        },
                                                    },
                                                },
                                            }}
                                            onSelect={this.handleTransferTo}
                                            autocomplete
                                            dataSourceApi={`${API.SEARCH.ACCOUNT_HEADS}`}
                                            validate={[required]}
                                            isDisabled={isView}
                                        />
                                    </Grid>
                                    {!isView &&
                                        <Grid item sm={6}>
                                            <Field
                                                testId="balanceTo"
                                                name="balanceTo"
                                                component={ReduxFormTextField}
                                                label="Balance B"
                                                fullWidth
                                                disabled
                                                type="number"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    }
                                </Grid>
                                <Grid container item sm={12} spacing={16} justify="space-between" alignItems="center">
                                    <Grid item sm={6}>
                                        <Field
                                            testId="amount"
                                            name="amount"
                                            component={ReduxFormTextField}
                                            label="Transfer Amount"
                                            type="number"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            validate={[required]}
                                            disabled={isView}
                                        />
                                    </Grid>
                                    <Grid item sm={6} >
                                        <Field
                                            testId="date"
                                            name="date"
                                            component={DateInput}
                                            disabled={isView}
                                            label="Date"
                                            type="date"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                {
                                    !isView &&
                                    <Grid container item sm={12} spacing={16} justify="space-between" alignItems="center">
                                        <Grid item lg={6} md={6} sm={12}>
                                            <Field
                                                testId="journal"
                                                name="journal"
                                                component={ReduxFormReactSelectMaterial}
                                                dataSourceConfig={{
                                                    text: 'name',
                                                    value: 'id',
                                                }}
                                                style={{ padding: '6px' }}
                                                autocomplete
                                                label="Select Journal"
                                                placeholder="Select Journal"
                                                selectProps={{
                                                    textFieldProps: {
                                                        InputProps: {
                                                            classes: {
                                                                multiline: classes.multiline,
                                                            },
                                                            disableUnderline: true,
                                                        },
                                                    },
                                                }}
                                                isDisabled={isView}
                                                dataSourceApi={API.SEARCH.ACCOUNT_JOURNAL}
                                            />
                                        </Grid>
                                        {
                                            !getStringFromObject('uuid', formValues) && (
                                                <Grid item lg={6} sm={12} md={6}>
                                                    <Field
                                                        name="subCompany"
                                                        testId="sub-company"
                                                        label="Sub Company"
                                                        component={ReduxFormReactSelectMaterial}
                                                        isDisabled={isView}
                                                        options={Object.values(subCompanies).map(aSubCompany => ({
                                                            key: aSubCompany,
                                                            value: aSubCompany,
                                                        }))}
                                                        dataSourceConfig={{
                                                            text: 'value',
                                                            value: 'key',
                                                        }}
                                                        fullWidth
                                                        validate={[required]}
                                                        required
                                                    />
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                }
                                <Grid item lg={6} md={12} sm={12}>
                                    <Field
                                        testId="memo"
                                        name="memo"
                                        component={ReduxFormTextField}
                                        label="Memo"
                                        multiline
                                        rows={2}
                                        disabled={isView}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                {
                                    !getStringFromObject('uuid', formValues) && (
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Typography variant="body1" gutterBottom>
                                                Note: Sub Company is only used for sequencing.
                                            </Typography>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </DialogContent>
                        <DialogActions style={{ padding: '12px' }}>
                            {
                                !isView && name &&
                                <ActionButton
                                    test-id="cancel-fund"
                                    primary={false}
                                    disableRipple
                                    onClick={this.handleCancel}
                                >
                                    Cancel
                                </ActionButton>
                            }
                            {
                                isView && !isPeriodClosed && name && !isReconciled &&
                                <ActionButton
                                    test-id="edit-fund"
                                    disableRipple
                                    onClick={this.toggleEdit}
                                >
                                    Edit
                                </ActionButton>
                            }
                            {
                                !isView &&
                                <ActionButton
                                    testId="confirm-fund"
                                    type="Submit"
                                    disableRipple
                                    onClick={handleSubmit(this.handleSave)}
                                >
                                    Confirm
                                </ActionButton>
                            }
                            {
                                name && isView &&
                                <ActionButton
                                    test-id="transfer-journal-print"
                                    primary
                                    onClick={this.fetchJournalEntriesAndPrint}
                                    disableRipple
                                >
                                    Print Journal Entries
                                </ActionButton>
                            }
                            {
                                name && isView &&
                                <ActionButton
                                    test-id="transfer-print"
                                    disableRipple
                                    toolTipTitle={<div>Print</div>}
                                    primary
                                    onClick={this.handlePrint}
                                    disableTool={false}
                                >
                                    Print
                                </ActionButton>
                            }
                            {
                           name && isView
                               && (
                                   <ActionButton
                                       test-id="transfer-journal-new"
                                       primary
                                       onClick={this.reInitializeFormAndFocusUserField}
                                       disableRipple
                                   >
                                       Create New
                                   </ActionButton>
                               )
                             }
                        </DialogActions>
                    </Dialog>
                    <Print
                        data={{
                            ...formValues,
                            company,
                        }}
                        url={`${APPLICATION_CONFIG_URL}/HtmlPrint/BookManagement/fundTransfer.html`}
                        print={print}
                        subCompany={subCompany}
                    />
                    <Print
                        url={`${APPLICATION_CONFIG_URL}/HtmlPrint/JournalPrint/JournalPrint.html`}
                        data={journalEntryPrintData}
                        print={printJournal}
                        subCompany={subCompany}
                    />
                </form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    accountTransfer: state.accountTransfer,
    company: getStringFromObject('companyName', state.appConfiguration),
    formValues: state.form[formName] ? state.form[formName].values : {},
});

TransferDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    accountTransfer: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    accountMoveId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    formValues: PropTypes.object,
    isView: PropTypes.bool,
    company: PropTypes.string,
};

TransferDialog.defaultProps = {
    formValues: null,
    accountMoveId: null, // Todo change to fund transfer id
    isView: false,
    company: '',
};

export default connect(mapStateToProps)(reduxForm({
    form: formName,
})(withStyles(style)(TransferDialog)));
