export const holidayAdmin = ['hr.holidays.admin'];
export const announcementAdmin = ['hr.announcements.admin'];
export const assetsAdmin = ['hr.assets.admin'];
export const vitalsPrivilege = ['vitalsqueue'];
export const appointment = ['hr.appointment'];
export const appointmentAdmin = ['appointments.admin'];
export const organisationAdmin = ['hr.organisation.admin'];
export const organisationAccountStatus = ['hr.organisation.account.status'];
export const leavePrivilege = ['hr.leave'];
export const customerInvoiceEdit = ['erp.invoice.edit'];
export const cashCloseEditPrivileges = ['erp.cashClose.edit'];
export const opdDoctorPrivilege = ['doctoropd'];
export const searchActivePatientPrivilege = ['clinicalsearch.active.patients'];
export const searchAllPatientPrivilege = ['clinicalsearch.all.patients'];

export const registrationPatientDetailsModify = 'registration.editpatientdetails';

export const bundleCreatePrivilege = 'payer.bundle.create';

export const expenseEditPrivilege = ['accounting.edit.expense'];

export const payerInvoiceCancelPrivilege = ['erp.invoice.payer.cancel'];
export const payerInvoiceEditPrivilege = ['erp.invoice.payer.edit'];
export const deleteInvoicePaymentPrivilege = ['supplier.invoicepayment.delete'];
export const editFormularyListStatePrivilege = ['edit.formulary.list.state'];
export const payerAdminPrivilege = ['payer.bundle.action'];
export const validatePbmPrivilege = ['validate.pbm'];

export const revertApprovedAttendancePrivilege = ['revert.approved.attendance'];
export const referToDoctorFromRegistrationPrivilege = ['hr.registration.refer.to.doctor'];

export const consultationPrivilege = ['hr.registration.consultation'];

export const convertNonDrugToDrugPrivilege = ['convert.non.drug.to.drug.privilege'];
