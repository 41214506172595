import React from 'react';
import deepEqual from 'react-fast-compare';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { ShowChart, LocalOffer } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, withFormik } from 'formik';
import { Popover } from '@material-ui/core';
import classNames from 'classnames';
import {
    apiCatchBlockFunction, getJsonPath,
    isArrayValidAndNotEmpty,
    isValidFunction,
    roundedValue,
    valueToFixedTwoDigits,
} from '../../constants/CommonUtil';
import PurchaseOrderDetails from '../../components/PurchaseOrderComponents/PurchaseOrderDetails';
import SendEmailDialog from '../../components/PurchaseOrderComponents/SendEmailDialog';
import {
    clearSelectedPurchaseOrder,
    createPurchaseOrderRequest,
    fetchPurchaseOrderByUuidRequest,
    fetchPurchaseOrderRequest,
} from '../../redux/modules/purchaseOrder/purchaseOrder-actions';
import {
    getUiObject,
    mapPurchaseOrderFromUiObject,
    mapIntentToPurchaseOrder,
    PURCHASE_ORDER_OPERATION,
    PURCHASE_ORDER_TYPE,
    PURCHASE_ORDER_UI_OBJECT,
} from '../../mapper/PurchaseOrderMapper';
import { ACTION_HANDLERS, calculateCurrentCost, calculateNewExpectedWeightedCost } from '../../components/PurchaseOrderComponents/PurchaseOrderActionHandlers';
import { displayWarning } from '../../redux/modules/warningDialog/warningDialog-actions';
import RecieveStockDialog from '../../components/PurchaseOrderComponents/RecieveStockDialog';
import MakeBillDialog from '../../components/PurchaseOrderComponents/MakeBillDialog/MakeBillDialog';
import ActionButton from '../../components/ActionButton/ActionButton';
import API from '../../constants/api';
import { APPLICATION_CONFIG_URL, ROWS_PER_PAGE } from '../../constants/constants';
import FormikTable from '../../components/Formik/FormikTable/FormikTable';
import FormikTextField from '../../components/Formik/FieldComponents/FormikTextField';
import Print from '../RegistrationAppComponents/PrintHTML/PrintHTML';
import { convertToPlainDataMemoized } from '../../components/PrintComponents/PurchaseOrderPrintUtil';
import OutlinedTextField from '../../components/OutlinedTextField';
import GenericFilterWithSearch from '../../components/GenericFilterComponent/GenericFilterWithSearch';
import {
    productTypes,
    stockRequisitionStrategies,
} from '../PurchaseContainers/PurchaseOrdersContainer/PurchaseOrdersContainerUtil';
// import FormikReactSelectMaterial from '../../components/Formik/FieldComponents/FormikReactSelectMaterial';
import { isEmpty } from '../../constants/FormValidations';
import { getSupplierSubCompany } from '../../components/PurchaseOrderComponents/MakeBillDialog/MakeBillDialogUtil';
import { commonPostApiRequest } from '../../redux/modules/common/common-actions';
import PreviousPurchaseInvoiceLinesDialog
    from '../../components/PreviousPurchaseInvoiceLinesDialog/PreviousPurchaseInvoiceLinesDialog';
import { hideSpinner, showSpinner } from '../../redux/modules/spinner/spinner';
import { errorMessage } from '../../redux/modules/message/message-actions';
import { getStringFromObject } from '../../constants/lodashUtils';
import { NumberOf } from '../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../constants/nullCheckUtils';

const purchaseOrderForm = 'purchaseOrderForm';

const style = theme => ({
    textField: {
        borderRadius: '5px',
        backgroundColor: '#fff',
        border: `2px solid ${theme.palette.borderColor}`,
    },
    input: {
        padding: '2px 6px',
    },
    totalField: {
        textAlign: 'right',
        fontWeight: '500',
        padding: '0 8px',
    },
    fileUpload: {
        border: `2px solid ${theme.palette.borderColor}`,
        borderRadius: '5px',
        padding: '1rem',
    },
    actionButtons: {
        position: 'fixed',
        bottom: '0',
        right: '2rem',
    },
});

const renderTextField = (label, value) => (
    <Grid item sm={3} md={3} lg={2}>
        <OutlinedTextField
            label={label}
            value={value}
            disabled
        />
    </Grid>
);

class PurchaseOrderContainer extends React.Component {
    constructor(props) {
        super(props);
        console.log('asdasd0a9sudp', JSON.stringify(props.values));
        this.state = {
            schema: null,
            isRecieveStockClicked: false,
            isPaymentInvoiceClicked: false,
            printOrder: false,
            automaticPoSchema: null,
            searchText: '',
            subCompany: '',
            resetPage: false,
            isPreviousPurchaseInvoiceLinesDialogCloseOpen: false,
            previousPurchaseInvoiceLinesProduct: null,
            popoverElement: null,
            popoverValue: 0,
        };
    }

    componentWillMount() {
        const { match: { params }, dispatch } = this.props;
        if (params && params.id) {
            dispatch(fetchPurchaseOrderRequest(params.id));
        }
    }

    componentDidMount() {
        this.getJsonSchema();
    }

    componentDidUpdate(prevProps) {
        const oldDiscountAcc = getStringFromObject('discountAccount', prevProps.values);
        const newDiscountAcc = getStringFromObject('discountAccount', this.props.values);
        const oldSupplier = getStringFromObject('supplier', prevProps.values);
        const newSupplier = getStringFromObject('supplier', this.props.values);
        if (!deepEqual(oldSupplier, newSupplier)) {
            this.getSubCompanyOfSupplier(newSupplier);
        }
        if (!deepEqual(oldDiscountAcc, newDiscountAcc) && !isObjectValidAndNotEmpty(newDiscountAcc)) {
            this.props.setFieldValue('discountPercent', 0);
            this.props.setFieldValue('discount', 0);
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearSelectedPurchaseOrder());
    }

    getJsonSchema = async () => {
        await this.loadJosn('/StockManagement/PurchaseOrderItems.json', 'schema', true);
        await this.loadJosn('/StockManagement/AutomaticPurchaseOrderItems.json', 'automaticPoSchema');
        this.fetchStockIntentRequest();
    };

    getSubCompanyOfSupplier = async (supplier) => {
        console.log('asd09audas09dsa', supplier);
        const subCompany = await getSupplierSubCompany(supplier, this.props.dispatch);
        this.setState({
            subCompany,
        });
    };

    loadJosn = async (jsonPath, stateVariable, fromAppSpecificConfig) => {
        try {
            const response = await axios.get(
                fromAppSpecificConfig
                    ? getJsonPath(jsonPath, APPLICATION_CONFIG_URL)
                    : getJsonPath(jsonPath),
            );
            this.setState({
                [stateVariable]: response.data,
            });
        } catch (e) {
            console.error(`There has been a problem with your fetch operation:${error.message}`);
        }
    };

    initializeForm = (formValues) => {
        const { resetForm } = this.props;
        resetForm(formValues);
    };

    reinitializeForm = (savedPurchaseOrder) => {
        console.log('asd-asd-adu-as', savedPurchaseOrder);
        if (isObjectValidAndNotEmpty(savedPurchaseOrder)) {
            console.log('asda0s9ua0s9u-as', savedPurchaseOrder);
            // this.props.setValues(getUiObject(savedPurchaseOrder));
        }
    };

    fetchPurchaseOrder = () => {
        const uuid = getStringFromObject('uuid', this.props.values);
        if (uuid) {
            this.props.dispatch(fetchPurchaseOrderByUuidRequest(uuid, this.reinitializeForm));
        }
    };

    fetchStockIntentRequest = async () => {
        const { intentUuid } = this.props;
        const { schema } = this.state;
        if (intentUuid) {
            const response = await axios.get(`${API.STOCK_INTENT.FETCH_STOCK_INTENT_BY_UUID}${intentUuid}`);
            this.initializeForm(await mapIntentToPurchaseOrder(response.data, schema));
        }
    };

    maxDiscount = (min, max) => (value) => {
        if (!isEmpty(value)) {
            const discount = NumberOf(value);
            if (discount < min) {
                return `value must be more than ${min}`;
            } else if (discount > max) {
                return `value must be less than ${max}`;
            }
        }
        return undefined;
    };

    savePurchaseOrderInternal = () => {
        const {
            setFieldValue, submitForm, values,
        } = this.props;
        setFieldValue('type', PURCHASE_ORDER_TYPE.NEW_PURCHASE_ORDER);
        // setFieldValue('callback', this.reinitializeForm);
        const promise = new Promise((resolve, reject) => {
            try {
                const { purchaseOrderLines } = values;
                const newPoLines = purchaseOrderLines.filter(a => isObjectValidAndNotEmpty(a.product));
                setFieldValue('purchaseOrderLines', newPoLines);
                resolve();
            } catch (e) {
                reject(e);
            }
        });
        promise.then(() => submitForm()).catch(() => { });
    };

    handlePOSave = () => {
        const { setFieldValue } = this.props;
        setFieldValue('operation', PURCHASE_ORDER_OPERATION.SAVE);
        this.savePurchaseOrderInternal();
    };

    handlePOConfirm = () => {
        const { setFieldValue, intentUuid } = this.props;
        setFieldValue('operation', PURCHASE_ORDER_OPERATION.CONFIRM);
        if (intentUuid) {
            setFieldValue('intentUuid', intentUuid);
        }
        this.savePurchaseOrderInternal();
    };

    handlePrint = () => {
        this.setState(prevState => ({ printOrder: !prevState.printOrder }));
    };

    handleCreateNew = () => {
        const { dispatch } = this.props;
        dispatch(clearSelectedPurchaseOrder());
        this.initializeForm({ ...PURCHASE_ORDER_UI_OBJECT });
    }

    handleReset = () => {
        let callBack = this.handlePOSave;
        const state = getStringFromObject('state', this.props.values);
        if (state === 'confirmed') {
            callBack = this.handlePOConfirm;
        }
        this.props.dispatch(displayWarning(
            'All changes will be lost, are you sure want to continue',
            callBack,
            {},
            this.props.handleReset,
            {},
            'Save and Exit',
            'Do not save',
            'Cancel',
            () => { },
            undefined,
        ));
    };

    handleCloseSaveAndSend = () => {
        this.setState({
            isSaveAndSendClicked: false,
        });
    };

    handleRecieveStock = () => {
        this.setState({
            isRecieveStockClicked: true,
        });
    };

    markReceiveStockClicked = () => {
        console.log('TestComes');
        const {
            dispatch,
        } = this.props;
        dispatch(displayWarning('Are you sure you want to mark it as fully received? If you say yes, you will not\n' +
            ' be able to receive further stock for this Purchase order',
            // eslint-disable-next-line indent
            this.handleMarkAsReceiveStock,
        ));
    };

    handleMarkAsReceiveStock = () => {
        const { selected, dispatch } = this.props;
        axios.post(`${API.PURCHASE_ORDER.MARK_RECEIVE_STOCK}?purchaseOrderUuid=${selected.uuid}`)
            .then((response) => {
                this.initializeForm(getUiObject(response.data));
            }).catch(error => apiCatchBlockFunction(error, dispatch));
    };

    handleClickPaymentInvoice = () => {
        this.setState({
            isPaymentInvoiceClicked: true,
        });
    };

    handleCloseRecieveStock = () => {
        this.setState({
            isRecieveStockClicked: false,
        }, this.fetchPurchaseOrder);
    };

    handleClosePaymentInvoice = () => {
        this.setState({
            isPaymentInvoiceClicked: false,
        }, this.fetchPurchaseOrder);
    };

    handleClosePrintDialog = () => {
        this.setState({ printOrder: false });
    };

    handleEditClick = () => {
        this.props.setFieldValue('editable', true);
    };

    handleCancelPO = () => {
        const {
            values,
            dispatch,
            handleClickCancel,
        } = this.props;
        const poUuid = getStringFromObject('uuid', values);
        dispatch(displayWarning(
            'Are you sure want to cancel Purchase Order ? This operation can not be undone !!!',
            () => {
                dispatch(commonPostApiRequest(
                    `${API.PURCHASE_ORDER.CANCEL_PO}${poUuid}`,
                    {
                        successCallback: handleClickCancel,
                    },
                ));
            },
            undefined,
            undefined,
            undefined,
            'CANCEL PO',
            'DO NOT CANCEL',
        ));
    };

    handleCancel = (e) => {
        const {
            values, handleReset, handleClickCancel, setFieldValue,
        } = this.props;
        const editable = getStringFromObject('editable', values);
        if (editable) {
            let callBack = this.handlePOSave;
            const state = getStringFromObject('state', this.props.values);
            if (state === 'confirmed') {
                callBack = this.handlePOConfirm;
            }
            this.props.dispatch(displayWarning(
                'All changes will be lost, are you sure want to continue ?',
                callBack,
                {},
                () => {
                    handleReset();
                    setFieldValue('editable', false);
                },
                {},
                'Save and Exit',
                'Do not save',
                'Cancel',
                () => { },
                undefined,
            ));
        } else {
            handleClickCancel(e);
        }
    };

    filterProducts = () => {
        const { searchText } = this.state;
        const { values, setFieldValue } = this.props;
        const lines = getStringFromObject('purchaseOrderLines', values, []);
        if (isArrayValidAndNotEmpty(lines)) {
            const pLines = lines.map((line, index) => {
                if (searchText) {
                    const product = getStringFromObject('product.productName', line);
                    if (product && !product.toLowerCase().includes(searchText.toLowerCase())) {
                        return { ...line, originalIndex: index, visible: false };
                    }
                }
                return { ...line, originalIndex: index, visible: true };
            });
            setFieldValue('purchaseOrderLines', pLines);
            this.setState(p => ({ resetPage: !p.resetPage }));
        }
    };

    handleApplyFilter = ({ searchText }) => this.setState({ searchText }, this.filterProducts);


    handleChangeDiscountPerc = () => {
        const { setFieldValue } = this.props;
        setFieldValue('discount', 0);
    };

    handleChangeDiscountAmount = () => {
        const { setFieldValue } = this.props;
        setFieldValue('discountPercent', 0);
    };

    handleInfoIconClick = (event, index) => {
        const { values, setFieldError } = this.props;
        event.stopPropagation();
        const productUuid = getStringFromObject(`purchaseOrderLines.${index}.product.uuid`, values);
        if (productUuid) {
            this.setState({
                isPreviousPurchaseInvoiceLinesDialogCloseOpen: true,
                previousPurchaseInvoiceLinesProduct: {
                    uuid: productUuid,
                    name: getStringFromObject(`purchaseOrderLines.${index}.product.productName`, values) || '',
                },
            });
        } else {
            setFieldError(`purchaseOrderLines.${index}.product`, 'required');
        }
    }

    hideQuantityPopover =() => {
        this.setState({
            popoverElement: null,
            popoverValue: '',
        });
    }

    showQuantity = async (event, index) => {
        const { values, dispatch } = this.props;
        dispatch(showSpinner());
        const productUuid = getStringFromObject(`purchaseOrderLines.${index}.product.uuid`, values);
        const shipTo = getStringFromObject('warehouse.uuid', values);
        try {
            if (productUuid && shipTo) {
                const target = event.currentTarget;
                const response = await axios.get(`${API.PRODUCT.GET_PRODUCT_CURRENT_QUANTITY}${productUuid}&stockWarehouseUuid=${shipTo}`);
                this.setState({
                    popoverElement: target,
                    popoverElementLabel: 'Current Quantity',
                    popoverValue: NumberOf(response.data),
                });
            } else {
                dispatch(errorMessage('select product and ship to'));
            }
        } catch (error) {
            apiCatchBlockFunction(error, dispatch);
        }
        dispatch(hideSpinner());
    }

    showExpectedCost = async (event, index) => {
        const { values, dispatch } = this.props;
        const target = event.currentTarget;
        dispatch(showSpinner());
        const productUuid = getStringFromObject(`purchaseOrderLines.${index}.product.uuid`, values);
        const rate = getStringFromObject(`purchaseOrderLines.${index}.rate`, values);
        const discountAmount = getStringFromObject(`purchaseOrderLines.${index}.discountAmount`, values);
        const discountAmount2 = getStringFromObject(`purchaseOrderLines.${index}.discountAmount2`, values);
        const quantity = getStringFromObject(`purchaseOrderLines.${index}.quantity`, values);
        const bonus = getStringFromObject(`purchaseOrderLines.${index}.bonus`, values);
        try {
            if (productUuid) {
                const response = await axios.get(`${API.GET_LATEST_PRODUCT_WEIGHT}?productUuid=${productUuid}`);
                const qtyAtWhichWeightedCostIsCalculated = getStringFromObject('data.quantity', response);
                const costAtWhichWeightedCostIsCalculated = getStringFromObject('data.cost', response);

                const cost = calculateCurrentCost(rate, discountAmount, discountAmount2, quantity, bonus);
                const exptdCost = calculateNewExpectedWeightedCost(cost, quantity, costAtWhichWeightedCostIsCalculated, qtyAtWhichWeightedCostIsCalculated);
                console.log('🚀 - response:', response);
                this.setState({
                    popoverElement: target,
                    popoverElementLabel: 'Expected Cost',
                    popoverValue: NumberOf(exptdCost),
                });
            } else {
                dispatch(errorMessage('Product not selected!'));
            }
        } catch (error) {
            apiCatchBlockFunction(error, dispatch);
        }
        dispatch(hideSpinner());
    }

    handlePreviousPurchaseInvoiceLinesDialogClose = () => {
        this.setState({
            isPreviousPurchaseInvoiceLinesDialogCloseOpen: false,
            previousPurchaseInvoiceLinesProduct: null,
        });
    }

    renderAutomaticPoDetails = (selected) => {
        let jobParams = getStringFromObject('jobParams', selected);
        if (jobParams) {
            jobParams = JSON.parse(jobParams);
            const strategy = getStringFromObject('strategy', jobParams);
            return (
                <Grid container spacing={8}>
                    {renderTextField('Strategy', stockRequisitionStrategies[strategy])}
                    {renderTextField('Months', getStringFromObject('months', jobParams))}
                    {
                        renderTextField(
                            'Product Type',
                            productTypes[getStringFromObject('PRODUCT_TYPE', jobParams)],
                        )
                    }
                    {
                        strategy === 'FORECASTING' &&
                        <React.Fragment>
                            {renderTextField('Buffer Stock', getStringFromObject('buffer_stock', jobParams))}
                            {renderTextField('Order Cycle', getStringFromObject('order_cycle', jobParams))}
                            {renderTextField('Lead Time', getStringFromObject('lead_time', jobParams))}
                        </React.Fragment>
                    }
                </Grid>
            );
        }
        return <React.Fragment />;
    };

    render() {
        const {
            classes,
            dispatch,
            values,
            setFieldValue,
            dirty,
            dialogContent,
            selected,
            intentUuid,
            enableSubVendorCheckBox,
        } = this.props;
        const {
            schema,
            isSaveAndSendClicked,
            isRecieveStockClicked,
            isPaymentInvoiceClicked,
            printOrder,
            automaticPoSchema,
            searchText,
            resetPage,
            subCompany,
            isPreviousPurchaseInvoiceLinesDialogCloseOpen,
            previousPurchaseInvoiceLinesProduct,
            popoverElement,
            popoverValue,
            popoverElementLabel,
        } = this.state;
        let subTotal = 0;
        let totalTax = 0;
        const hasDiscountHead = true;
        let discountAmount = NumberOf(getStringFromObject('discount', values));
        const discountPerc = NumberOf(getStringFromObject('discountPercent', values));
        if (isObjectValidAndNotEmpty(values) && isArrayValidAndNotEmpty(values.purchaseOrderLines)) {
            values.purchaseOrderLines.forEach((line) => {
                subTotal += NumberOf(line.total);
                totalTax += NumberOf(line.taxAmount);
            });
        }
        const amountWithTax = subTotal;
        if (discountPerc > 0) {
            discountAmount = roundedValue(amountWithTax * (discountPerc / 100));
        }
        subTotal -= totalTax;
        const total = (subTotal + totalTax) - discountAmount;
        const editable = !!getStringFromObject('editable', values, false);
        const productReceived = !!getStringFromObject('productReceived', values, false);
        const invoiced = getStringFromObject('invoiced', values, false);
        const poState = getStringFromObject('state', values);
        const poName = getStringFromObject('name', values, undefined);
        const isCompleted = (poState === 'done') || (poState === 'cancel');
        const isConfirmed = ((poState === 'confirmed') || isCompleted);
        const jobParams = getStringFromObject('jobParams', selected);
        let formSchema = schema;
        if (jobParams && !isConfirmed && !!getStringFromObject('uuid', selected)) {
            formSchema = automaticPoSchema;
        }
        // let discountSearchApi = '';
        // if (isObjectValidAndNotEmpty(formSchema)) {
        //     discountSearchApi = `${getStringFromObject('discountHeadApi', formSchema)}?subCompany=${subCompany || ''}&searchString=`;
        // }
        const autoDiscountConfig = getStringFromObject('autoDiscountConfig', schema);
        console.log('afjkadhfka', this.props);
        return (
            <React.Fragment>
                <form>
                    <div className="p2">
                        {
                            !dialogContent &&
                            <Grid container justify="space-between">
                                <h4>Purchase Order&nbsp;{selected.name ? `(${selected.name})` : ''}</h4>
                            </Grid>
                        }
                        {
                            (!isConfirmed && !!getStringFromObject('uuid', selected)) &&
                            this.renderAutomaticPoDetails(selected)
                        }
                        <PurchaseOrderDetails
                            formValues={values}
                            handleRecieveStock={this.handleRecieveStock}
                            handleClickPaymentInvoice={this.handleClickPaymentInvoice}
                            formName={purchaseOrderForm}
                            dispatch={dispatch}
                            isView={editable}
                            isEditable={editable}
                            handleMarkAsReceiveStock={this.markReceiveStockClicked}
                            setFieldValue={setFieldValue}
                            subCompany={subCompany}
                            intentUuid={intentUuid}
                            enableSubVendorCheckBox={enableSubVendorCheckBox}
                        />
                    </div>
                    <div style={{ background: '#fff', padding: '2rem' }}>
                        <Grid container style={{ fontSize: '1.2rem' }}>
                            <Grid item lg={4} md={4} sm={4}>
                                Item Details
                            </Grid>
                            <Grid item lg={3} md={3} sm={4}>
                                <GenericFilterWithSearch
                                    placeholder="Search By Product"
                                    hideFilters
                                    renderChips={false}
                                    filterValues={{ searchText }}
                                    saveFilter={this.handleApplyFilter}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className="mt-1">
                            {
                                isObjectValidAndNotEmpty(formSchema) &&
                                <FormikTable
                                    // uniqueField="uiUuid"
                                    fieldName="purchaseOrderLines"
                                    actionHandlers={ACTION_HANDLERS(autoDiscountConfig)}
                                    dispatch={dispatch}
                                    styles={{ minHeight: '20em' }}
                                    rerenderOnEveryChange
                                    resetPage={resetPage}
                                    {...formSchema}
                                    visibilityField="visible"
                                    tableRoot={{ minHeight: '20em', overflow: 'visible' }}
                                    isEditable={editable}
                                    rowsPerPageOptions={ROWS_PER_PAGE}
                                    actionParams={{ dispatch }}
                                    infoIcon
                                    handleInfoIconClick={this.handleInfoIconClick}
                                    additionalButtons={[
                                        {
                                            name: 'currentQty',
                                            label: 'Current Quantity',
                                            element: <ShowChart color="primary" />,
                                            onClick: this.showQuantity,
                                        },
                                        {
                                            name: 'exptdPrice',
                                            label: 'Expected Price',
                                            element: <LocalOffer color="primary" />,
                                            onClick: this.showExpectedCost,
                                        },
                                    ]}
                                />
                            }
                        </Grid>
                        <Grid container className="mt-1" justify="space-between">
                            <Grid item lg={3} md={3} sm={4}>
                                <Field
                                    name="notes"
                                    component={FormikTextField}
                                    label="Your message to supplier"
                                    multiline
                                    rows={5}
                                    rowsMax={5}
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            root: classNames(classes.textField, classes.input),
                                        },
                                    }}
                                    disabled={!editable}
                                />
                            </Grid>
                            <Grid item lg={5} md={5} sm={2} />
                            <Grid item lg={2} md={2} sm={3} alignItems="flex-end">
                                <Grid container direction="column" spacing={8} className="mt-0-5">
                                    {/* <Grid item>
                                        <Field
                                            test-id="purchase-order-discount-head"
                                            name="discountHead"
                                            component={FormikReactSelectMaterial}
                                            dataSourceConfig={{
                                                text: 'value',
                                                value: 'key',
                                            }}
                                            label="Discount Head"
                                            autocomplete
                                            dataSourceApi={discountSearchApi}
                                            isDisabled={isConfirmed}
                                        />
                                    </Grid> */}
                                    {
                                        hasDiscountHead &&
                                        <Grid item>
                                            <Grid container spacing={8}>
                                                <Grid item sm={6} lg={6} md={6}>
                                                    <Field
                                                        testId="purchase-order-discount-perc"
                                                        label="Discount (%)"
                                                        name="discountPercent"
                                                        component={FormikTextField}
                                                        type="number"
                                                        fullWidth
                                                        validate={this.maxDiscount(0, 100)}
                                                        disabled={!editable}
                                                        onChangeHandlers={['handleChangeDiscountPerc']}
                                                        actionHandlers={{
                                                            handleChangeDiscountPerc: this.handleChangeDiscountPerc,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item sm={6} lg={6} md={6}>
                                                    <Field
                                                        testId="purchase-order-discount-amount"
                                                        label="Discount Amount"
                                                        name="discount"
                                                        component={FormikTextField}
                                                        validate={this.maxDiscount(0, amountWithTax)}
                                                        type="number"
                                                        fullWidth
                                                        disabled={!editable}
                                                        onChangeHandlers={['handleChangeDiscountAmount']}
                                                        actionHandlers={{
                                                            handleChangeDiscountAmount: this.handleChangeDiscountAmount,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item lg={2} md={2} sm={3}>
                                <div className={classNames('mt-0-5', classes.totalField)}>
                                    Subtotal:&nbsp;&nbsp;{valueToFixedTwoDigits(subTotal)}
                                </div>
                                <div className={classNames('mt-0-5', classes.totalField)}>
                                    Tax:&nbsp;&nbsp;{valueToFixedTwoDigits(totalTax)}
                                </div>
                                <div className={classNames('mt-0-5', classes.totalField)}>
                                    Discount:&nbsp;&nbsp;{valueToFixedTwoDigits(discountAmount)}
                                </div>
                                <div className={classNames('mt-1', classes.totalField)}>
                                    Total:&nbsp;&nbsp;{valueToFixedTwoDigits(total)}
                                </div>
                            </Grid>
                        </Grid>
                        {/* <Grid container className="mt-2">
                            <Grid item lg={3} md={3} sm={4}>
                                <Field
                                    name="memo"
                                    component={FormikTextField}
                                    label="Memo"
                                    multiline
                                    rows={3}
                                    rowsMax={3}
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                        classes: {
                                            root: classNames(classes.textField, classes.input),
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    disabled={isConfirmed}
                                />
                            </Grid>
                        </Grid> */}
                        {/* <Grid container className="mt-2">
                            <Grid item lg={3} md={3} sm={4}>
                                <div style={{ color: '#0000008a' }}>Attachments</div>
                                <div className={classes.fileUpload}>
                                    <Field
                                        name="attachments"
                                        component={UploadFile}
                                        disabled={isConfirmed}
                                        uploadApi=""
                                    />
                                </div>
                            </Grid>
                        </Grid> */}
                        <Grid container justify="flex-end" className={classNames('p1', classes.actionButtons)}>
                            {
                                dialogContent &&
                                <ActionButton
                                    test-id="purchaseorder-cancel"
                                    primary={false}
                                    disableRipple
                                    onClick={this.handleCancel}
                                >
                                    Cancel
                                </ActionButton>
                            }
                            {
                                !editable && !productReceived && !isCompleted &&
                                <ActionButton
                                    className="ml-1"
                                    test-id="purchaseorder-edit"
                                    disableRipple
                                    onClick={this.handleEditClick}
                                >
                                    Edit
                                </ActionButton>
                            }
                            {
                                !editable && !productReceived && !isCompleted && !invoiced &&
                                <ActionButton
                                    className="ml-1"
                                    test-id="cancel-purchaseorder"
                                    disableRipple
                                    onClick={this.handleCancelPO}
                                >
                                    Cancel PO
                                </ActionButton>
                            }
                            {
                                editable &&
                                <React.Fragment>
                                    <ActionButton
                                        className="ml-1"
                                        test-id="purchaseorder-reset"
                                        disableRipple
                                        disabled={!dirty}
                                        onClick={this.handleReset}
                                    >
                                        Reset
                                    </ActionButton>
                                    {
                                        !isConfirmed &&
                                        <ActionButton
                                            className="ml-1"
                                            testId="purchaseorder-save"
                                            disableRipple
                                            onClick={this.handlePOSave}
                                            disabled={!editable}
                                        >
                                            Save
                                        </ActionButton>
                                    }
                                    <ActionButton
                                        className="ml-1"
                                        testId="purchaseorder-confirm"
                                        disableRipple
                                        onClick={this.handlePOConfirm}
                                        disabled={!editable}
                                    >
                                        Confirm
                                    </ActionButton>
                                </React.Fragment>
                            }
                            {
                                isConfirmed &&
                                <ActionButton
                                    className="ml-1"
                                    test-id="purchaseorder-new"
                                    disableRipple
                                    disabled={editable}
                                    onClick={this.handleCreateNew}
                                >
                                    New
                                </ActionButton>
                            }
                            {
                                poName &&
                                <ActionButton
                                    className="ml-1"
                                    testId="purchaseorder-print"
                                    disableRipple
                                    onClick={this.handlePrint}
                                    disabled={!poName}
                                >
                                    Print
                                </ActionButton>
                            }
                        </Grid>
                    </div>
                </form>
                {
                    isSaveAndSendClicked &&
                    <SendEmailDialog
                        open
                        handleClose={this.handleCloseSaveAndSend}
                    />
                }
                {
                    isRecieveStockClicked &&
                    <RecieveStockDialog
                        open
                        purchaseOrder={values}
                        purchaseOrderDetails={selected}
                        handleClose={this.handleCloseRecieveStock}
                    />
                }
                {
                    isPaymentInvoiceClicked &&
                    <MakeBillDialog
                        open
                        purchaseOrder={values}
                        handleClose={this.handleClosePaymentInvoice}
                    />
                }
                {
                    isPreviousPurchaseInvoiceLinesDialogCloseOpen &&
                    isObjectValidAndNotEmpty(previousPurchaseInvoiceLinesProduct) && (
                        <PreviousPurchaseInvoiceLinesDialog
                            onClose={this.handlePreviousPurchaseInvoiceLinesDialogClose}
                            productUuid={previousPurchaseInvoiceLinesProduct.uuid}
                            productName={previousPurchaseInvoiceLinesProduct.name}
                        />
                    )
                }
                <Print
                    data={convertToPlainDataMemoized(values)}
                    url={`${APPLICATION_CONFIG_URL}/HtmlPrint/PurchaseOrder/purchaseOrder.html`}
                    print={printOrder}
                    subCompany={subCompany}
                />
                <Popover
                    open={!!popoverElement}
                    anchorEl={popoverElement}
                    onClose={this.hideQuantityPopover}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <div
                        style={{
                            padding: '8px',
                        }}
                    >
                        {popoverElementLabel}: {popoverValue}
                    </div>
                </Popover>
            </React.Fragment>
        );
    }
}

PurchaseOrderContainer.propTypes = {
    classes: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    handleClickCancel: PropTypes.func,
    selected: PropTypes.object,
    dialogContent: PropTypes.bool,
    /* formik props */
    dirty: PropTypes.bool.isRequired,
    handleReset: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setValues: PropTypes.func.isRequired,
    values: PropTypes.object,
    intentUuid: PropTypes.string,
    setFieldError: PropTypes.func.isRequired,
    enableSubVendorCheckBox: PropTypes.func,
};

PurchaseOrderContainer.defaultProps = {
    dispatch: () => { },
    handleClickCancel: () => { },
    selected: {},
    dialogContent: false,
    values: {},
    intentUuid: null,
    enableSubVendorCheckBox: false,
};

const handleSubmitForm = (values, { props, ...formikBag }) => {
    console.log('vinay inside submit form', formikBag);
    formikBag.setSubmitting(true);
    const dispatch = getStringFromObject('dispatch', props);
    const purchaseOrder = mapPurchaseOrderFromUiObject(values);
    const isConfirmed = getStringFromObject('operation', purchaseOrder) === PURCHASE_ORDER_OPERATION.CONFIRM;
    const setError = getStringFromObject('setFieldError', formikBag, () => { });
    let error = false;
    if (isConfirmed && isArrayValidAndNotEmpty(purchaseOrder.purchaseOrderLineDtos)) {
        purchaseOrder.purchaseOrderLineDtos.forEach((line, index) => {
            if (NumberOf(line.quantity) < 1 && Number(line.bonus) < 1) {
                error = true;
                setError(`purchaseOrderLines.${index}.bonus`, 'Bonus should be more than 0');
            }
        });
    }
    if (!error) {
        const successCallback = (r) => {
            if (isValidFunction(getStringFromObject('callback', values))) {
                getStringFromObject('callback', values)(r);
                formikBag.setSubmitting(false);
            }
        };
        dispatch(createPurchaseOrderRequest(purchaseOrder, successCallback));
    } else {
        formikBag.setSubmitting(false);
    }
};

const mapStateToProps = state => ({
    enableSubVendorCheckBox:
        getStringFromObject('appConfiguration.enableSubVendorCheckBox', state),
});

export default connect(mapStateToProps)(withFormik({
    mapPropsToValues: props => getUiObject(props.selected),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: purchaseOrderForm,
    handleSubmit: handleSubmitForm,
})(withStyles(style)(PurchaseOrderContainer)));
