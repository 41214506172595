import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { ERROR_MESSAGE } from '../../redux/modules/message/message-actions';
import { HIDE_SPINNER, SHOW_SPINNER } from '../../redux/modules/spinner/spinner';
import {
    didApiCallFailDueToUnauthorizedUser,
    didApiCallFailDueToVersionMismatch,
    getErrorMessage,
    getUrlWithApiParams,
} from '../../constants/CommonUtil';
import { MISMATCH_IN_VERSION, SESSION_TIMEOUT } from '../../redux/modules/login/login-actions';
import { FETCH_CORPORATE_SALES_PURCHASE_ORDER_FAILURE, FETCH_CORPORATE_SALES_PURCHASE_ORDER_SUCCESS } from '../../redux/modules/corporateSalesPurchaseOrder/corporateSalesPurchaseOrder-actions';

function* fetchCorporateSalesPurchaseWorker(action) {
    try {
        const {
            api,
            page,
            size,
            searchConstruct,
            filterValues,
        } = action;
        const urlWithApiWithParams = getUrlWithApiParams(api, { page, size });
        yield put({ type: SHOW_SPINNER, state: { canShow: true } });
        const response = yield call(axios.post, urlWithApiWithParams, searchConstruct);
        yield put({
            type: FETCH_CORPORATE_SALES_PURCHASE_ORDER_SUCCESS,
            data: {
                ...(response.data || {}),
            },
            filterValues,
            page,
            size,
        });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    } catch (e) {
        if (didApiCallFailDueToUnauthorizedUser(e)) {
            yield put({ type: SESSION_TIMEOUT, actionToDispatchAfterLogin: action });
        } else if (didApiCallFailDueToVersionMismatch(e)) {
            yield put({ type: MISMATCH_IN_VERSION });
        } else {
            const msg = 'Purchase Order fetch failed';
            yield put({ type: ERROR_MESSAGE, message: `${msg}: ${getErrorMessage(e)}` });
        }
        yield put({ type: FETCH_CORPORATE_SALES_PURCHASE_ORDER_FAILURE, data: {} });
        yield put({ type: HIDE_SPINNER, state: { canShow: false } });
    }
}

export default fetchCorporateSalesPurchaseWorker;
