import api from '../../../constants/api';

export const FETCH_CORPORATE_SALES_QUOTATION_REQUEST = '@@corporateSalesQuotation/FETCH_CORPORATE_SALES_QUOTATION_REQUEST';
export const FETCH_CORPORATE_SALES_QUOTATION_SUCCESS = '@@corporateSalesQuotation/FETCH_CORPORATE_SALES_QUOTATION_SUCCESS';
export const FETCH_CORPORATE_SALES_QUOTATION_FAILURE = '@@corporateSalesQuotation/FETCH_CORPORATE_SALES_QUOTATION_FAILURE';

export const fetchQuotation = (page, size, searchConstruct, seekParams, seekDirection, filterValues) => ({
    type: FETCH_CORPORATE_SALES_QUOTATION_REQUEST,
    api: api.CORPORATE_SALES.QUOTATION.PAGE,
    page,
    size,
    seekParams,
    seekDirection,
    searchConstruct,
    filterValues,
});
