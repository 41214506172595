import { takeLatest } from 'redux-saga/effects';
import {
    FETCH_CORPORATE_SALES_DELIVERY_NOTES_REQUEST
} from '../../redux/modules/corporateSalesDeliveryNotes/corporateSalesDeliveryNotes-actions';
import fetchCorporateSalesDeliveryNotesWorker from './corporateSalesDeliveryNotesWorkerSaga';

function* fetchCorporateSalesDeliveryNotesWatcher() {
    yield takeLatest(FETCH_CORPORATE_SALES_DELIVERY_NOTES_REQUEST, fetchCorporateSalesDeliveryNotesWorker);
}

export default fetchCorporateSalesDeliveryNotesWatcher;
