import api from '../../../constants/api';

export const FETCH_CORPORATE_SALES_ACCOUNT_INVOICE_REQUEST = '@@corporateSalesAccountInvoice/FETCH_CORPORATE_SALES_ACCOUNT_INVOICE_REQUEST';
export const FETCH_CORPORATE_SALES_ACCOUNT_INVOICE_SUCCESS = '@@corporateSalesAccountInvoice/FETCH_CORPORATE_SALES_ACCOUNT_INVOICE_SUCCESS';
export const FETCH_CORPORATE_SALES_ACCOUNT_INVOICE_FAILURE = '@@corporateSalesAccountInvoice/FETCH_CORPORATE_SALES_ACCOUNT_INVOICE_FAILURE';

export const fetchAccountInvoices = (page, size, searchConstruct, seekParams, seekDirection, filterValues) => ({
    type: FETCH_CORPORATE_SALES_ACCOUNT_INVOICE_REQUEST,
    api: api.CORPORATE_SALES.ACCOUNT_INVOICE.PAGE,
    page,
    size,
    seekParams,
    seekDirection,
    searchConstruct,
    filterValues,
});
