import { takeLatest } from 'redux-saga/effects';
import fetchCorporateSalesQuotationWorker from './corporateSalesWorkerSaga';
import {
              FETCH_CORPORATE_SALES_QUOTATION_REQUEST
        } from '../../redux/modules/corporateSalesQuotation/corporateSalesQuotations-actions';

function* fetchCorporateSalesQuotationWatcher() {
    yield takeLatest(FETCH_CORPORATE_SALES_QUOTATION_REQUEST, fetchCorporateSalesQuotationWorker);
}

export default fetchCorporateSalesQuotationWatcher;
