import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import deepEqual from 'react-fast-compare';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton/index';
import Clear from '@material-ui/icons/Clear';

import { GLOBAL_PROPERTIES } from '../../../../../constants/constants';
import {
    encounterStatusCompactFilters,
    encounterStatusCompactFiltersLabel,
    encounterStatusCompactFiltersMappers,
    encounterStatusMap,
    encounterStatusMapLabel,
    getConsultationStatusInitialFilter,
} from './ConsultationStatusFilterUtil';
import ReduxFormCheckbox from '../../../../../components/FormFieldComponents/ReduxFormCheckbox/ReduxFormCheckbox';
import ConsultationStatusFilter from './ConsultationStatusFilter';
import ConsultationStatusDisplayComponent
    from '../../../../../components/ConsultationStatusDisplayComponent/ConsultationStatusDisplayComponent';
import { getStringFromObject } from '../../../../../constants/lodashUtils';
import { isObjectValidAndNotEmpty } from '../../../../../constants/nullCheckUtils';

class ConsultationStatusMainFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            compactFilter: {
                [encounterStatusCompactFilters.LAB_ORDER_WITH_RESULTS]: false,
                [encounterStatusCompactFilters.NO_DRUGS]: false,
                [encounterStatusCompactFilters.XRAY_WITH_RESULTS]: false,
                [encounterStatusCompactFilters.INCOMPLETE_ENCOUNTER]: false,
            },
        };
    }

    onChangeCompactFilter = filterName => () => {
        const newFilterValue = !this.state.compactFilter[filterName];
        this.setState(prevState => ({
            compactFilter: {
                ...prevState.compactFilter,
                [filterName]: newFilterValue,
            },
        }), () => {
            this.props.onChangeConsultationFilter(this.getOrderTypeFilterChanges());
        });
    };

    onResetCompactFilter = () => {
        this.setState({
            compactFilter: {
                [encounterStatusCompactFilters.LAB_ORDER_WITH_RESULTS]: false,
                [encounterStatusCompactFilters.NO_DRUGS]: false,
                [encounterStatusCompactFilters.XRAY_WITH_RESULTS]: false,
                [encounterStatusCompactFilters.INCOMPLETE_ENCOUNTER]: false,
            },
        }, () => {
            this.props.onChangeConsultationFilter(this.getOrderTypeFilterChanges());
        });
    };

    getOrderTypeFilterChanges = () => {
        const {
            compactFilter,
        } = this.state;
        const {
            encounterStatusBitHelper,
        } = this.props;
        let filter = getConsultationStatusInitialFilter(encounterStatusBitHelper);
        if (isObjectValidAndNotEmpty(compactFilter)) {
            if (compactFilter[encounterStatusCompactFilters.LAB_ORDER_WITH_RESULTS]) {
                filter = {
                    ...filter,
                    ...encounterStatusCompactFiltersMappers[encounterStatusCompactFilters.LAB_ORDER_WITH_RESULTS],
                };
            }
            if (compactFilter[encounterStatusCompactFilters.XRAY_WITH_RESULTS]) {
                filter = {
                    ...filter,
                    ...encounterStatusCompactFiltersMappers[encounterStatusCompactFilters.XRAY_WITH_RESULTS],
                };
            }
            if (compactFilter[encounterStatusCompactFilters.NO_DRUGS]) {
                filter = {
                    ...filter,
                    ...encounterStatusCompactFiltersMappers[encounterStatusCompactFilters.NO_DRUGS],
                };
            }
            if (compactFilter[encounterStatusCompactFilters.INCOMPLETE_ENCOUNTER]) {
                filter = {
                    ...filter,
                    ...encounterStatusCompactFiltersMappers[encounterStatusCompactFilters.INCOMPLETE_ENCOUNTER],
                };
            } else {
                filter = {
                    ...filter,
                    ...encounterStatusCompactFiltersMappers[encounterStatusCompactFilters.ALL_ENCOUNTER],
                };
            }
        }
        return filter;
    };


    getCompactFilterState = () => {
        const {
            consultationStatusFilter,
        } = this.props;
        const compactFilter = {
            [encounterStatusCompactFilters.NO_DRUGS]: false,
            [encounterStatusCompactFilters.LAB_ORDER_WITH_RESULTS]: false,
            [encounterStatusCompactFilters.XRAY_WITH_RESULTS]: false,
            [encounterStatusCompactFilters.INCOMPLETE_ENCOUNTER]: false,
        };
        if (isObjectValidAndNotEmpty(consultationStatusFilter)) {
            Object.keys(encounterStatusCompactFiltersMappers).forEach((aKey) => {
                const anEncounterStatusCompactFilter = encounterStatusCompactFiltersMappers[aKey];
                let matched = false;
                Object.keys(anEncounterStatusCompactFilter).forEach((anOrderType) => {
                    if (deepEqual(anEncounterStatusCompactFilter[anOrderType], consultationStatusFilter[anOrderType])) {
                        matched = true;
                    }
                });
                if (matched) {
                    compactFilter[aKey] = true;
                }
            });
        }
        return compactFilter;
    };

    toggleAdvanceFilters = (e) => {
        this.setState({
            anchorEl: e.currentTarget,
        });
    };

    closeAdvanceFilters = () => {
        this.setState({
            anchorEl: null,
        });
    };

    render() {
        const {
            compactFilter,
            anchorEl,
        } = this.state;
        const {
            encounterStatusBitHelper,
            consultationStatusFilter,
            onSelectAConsultationFilter,
        } = this.props;
        console.log('asda90uda-0sdasa', consultationStatusFilter, onSelectAConsultationFilter);
        console.log('asd-aud-a0idasd', anchorEl);
        console.log(encounterStatusBitHelper, compactFilter);
        if (isObjectValidAndNotEmpty(encounterStatusBitHelper)) {
            return (
                <React.Fragment>
                    <div className="flexChildren">
                        {
                            Object.keys(encounterStatusCompactFiltersLabel).map(aFilter => (
                                <ReduxFormCheckbox
                                    key={aFilter}
                                    label={encounterStatusCompactFiltersLabel[aFilter]}
                                    input={{
                                        value: compactFilter[aFilter],
                                        onChange: this.onChangeCompactFilter(aFilter),
                                    }}
                                />
                            ))
                        }
                        <Button onClick={this.toggleAdvanceFilters}>
                            ADVANCED FILTERS
                        </Button>
                        <IconButton
                            onClick={this.onResetCompactFilter}
                        >
                            <Clear />
                        </IconButton>
                        <Popover
                            open={!!(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={this.closeAdvanceFilters}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            style={{ width: '150rem' }}
                        >
                            <Grid container spacing={16} className="p1">
                                <Grid item sm={12} md={12} lg={12}>
                                    <ConsultationStatusFilter
                                        {...this.props}
                                    />
                                </Grid>
                            </Grid>
                        </Popover>
                    </div>
                    <div className="flexChildren" style={{ justifyContent: 'flex-end' }}>
                        {
                            Object.keys(encounterStatusMap).map(aStatus => (
                                <React.Fragment key={aStatus}>
                                    <ConsultationStatusDisplayComponent
                                        status={encounterStatusMap[aStatus]}
                                    />
                                    <label className="p1 font-weight-500">{encounterStatusMapLabel[aStatus]}</label>
                                </React.Fragment>
                            ))
                        }
                        {
                            Object.keys(encounterStatusBitHelper).map(anOrderType => (
                                <React.Fragment key={anOrderType}>
                                    <ConsultationStatusDisplayComponent
                                        label={anOrderType}
                                    />
                                    <label className="p1 font-weight-500">{anOrderType}</label>
                                </React.Fragment>
                            ))
                        }
                    </div>
                </React.Fragment>
            );
        }
        return null;
    }
}

ConsultationStatusMainFilter.propTypes = {
    encounterStatusBitHelper: PropTypes.object,
    consultationStatusFilter: PropTypes.object,
    onSelectAConsultationFilter: PropTypes.func,
    onChangeConsultationFilter: PropTypes.func,
};

ConsultationStatusMainFilter.defaultProps = {
    encounterStatusBitHelper: {},
    consultationStatusFilter: {},
    onSelectAConsultationFilter: () => {},
    onChangeConsultationFilter: () => {},
};

const mapStateToProps = state => ({
    encounterStatusBitHelper:
        getStringFromObject(`appConfiguration.${GLOBAL_PROPERTIES.ENCOUNTER_OT_STATUS_BIT_POS_MAP}`, state),
});

export default connect(mapStateToProps)(ConsultationStatusMainFilter);

