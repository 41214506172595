import initialStates from '../../initialStates';
import { FETCH_CORPORATE_SALES_ACCOUNT_INVOICE_FAILURE, FETCH_CORPORATE_SALES_ACCOUNT_INVOICE_SUCCESS } from './corporateSalesAccountInvoice-actions';

const corporateSalesAccountInvoiceReducers = (state = initialStates.corporateSalesAccountInvoice, action) => {
    let newState;
    switch (action.type) {
    case FETCH_CORPORATE_SALES_ACCOUNT_INVOICE_SUCCESS:
        newState = {
            list: action.data.content,
            first: action.data.first,
            last: action.data.last,
            forwardSeekMap: action.data.forwardSeekMap,
            backwardSeekMap: action.data.backwardSeekMap,
            page: action.page,
            size: action.size,
            filterValues: action.filterValues,
        };
        break;
    case FETCH_CORPORATE_SALES_ACCOUNT_INVOICE_FAILURE:
        newState = { ...initialStates.debitAndCreditLines };
        break;
    default:
        newState = state;
    }
    return newState;
};

export default corporateSalesAccountInvoiceReducers;
