import React from 'react';
import cloneDeep from 'clone-deep';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';
import { Field, withFormik } from 'formik';
import { connect } from 'react-redux';
import { Dialog, DialogActions, DialogContent, Slide } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import { minValue, required } from '../../../constants/FormValidations';
import ActionButton from '../../ActionButton/ActionButton';
import {
    apiCatchBlockFunction,
    formatCurrency,
    getAllowedDefaultAccountTypes,
    roundedValueFixedToTwoDigits,
} from '../../../constants/CommonUtil';
import API from '../../../constants/api';
import {
    clearSelectedPettyCash,
    createPettyCash,
    FETCH_PETTY_CASH_SUCCESS,
} from '../../../redux/modules/pettyCash/pettyCash-actions';
import DateInput from '../../FormFieldComponents/DateInput/DateInput';
import FormikReactSelectMaterial from '../../Formik/FieldComponents/FormikReactSelectMaterial';
import FormikTextField from '../../Formik/FieldComponents/FormikTextField';
import { commonGetApiRequest } from '../../../redux/modules/common/common-actions';
import Print from '../../../containers/RegistrationAppComponents/PrintHTML/PrintHTML';
import { APPLICATION_CONFIG_URL, PAYMENT_METHODS } from '../../../constants/constants';
import { fetchHrEmployeeDetails } from './EmployeeDialogUtils';
import { getStringFromObject } from '../../../constants/lodashUtils';
import { NumberOf } from '../../../constants/numberUtils';
import { isObjectValidAndNotEmpty } from '../../../constants/nullCheckUtils';

const formName = 'pettyCash';

const style = () => ({
    title: {
        fontSize: '1.3rem',
        fontWeight: '400',
        color: 'white',
        padding: '16px 16px',
        backgroundColor: '#469DC7',
        marginBottom: '25px',
    },
    dialogContent: {
        marginTop: '1rem',
        overflow: 'visible',
    },
    dialog: {
        overflow: 'visible',
    },
    paper: {
        overflow: 'visible',
    },
});

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class PettyCashDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: 0,
            print: false,
            printJournal: false,
            journalEntryPrintData: null,
        };
    }

    componentDidMount() {
        const {
            advanceId,
            dispatch,
        } = this.props;
        if (advanceId) {
            dispatch(commonGetApiRequest(
                `${API.PETTYCASH.GET_ONE}${advanceId}`,
                {
                    successAction: FETCH_PETTY_CASH_SUCCESS,
                    failureMessage: 'Failed to fetch Petty Cash',
                },
            ));
        }
    }

    componentDidUpdate(prevProps) {
        const previousEmployeeUuid = getStringFromObject('values.employee.key', prevProps) || '';
        const currentEmployeeUuid = getStringFromObject('values.employee.key', this.props) || '';
        const currentEmployee = getStringFromObject('values.employee', this.props) || null;
        if (previousEmployeeUuid !== currentEmployeeUuid) {
            this.updateSubCompany(currentEmployee);
        }
    }

    componentWillUnmount() {
        this.props.dispatch(clearSelectedPettyCash());
    }

    updateSubCompany = async (employee) => {
        const { setFieldValue, dispatch } = this.props;
        let subCompany = '';
        if (isObjectValidAndNotEmpty(employee)) {
            if (employee.subCompany) {
                ({ subCompany } = employee);
            } else {
                const employeeDetails = await fetchHrEmployeeDetails(employee, dispatch);
                if (isObjectValidAndNotEmpty(employeeDetails)) {
                    ({ subCompany } = employeeDetails);
                }
            }
        }
        setFieldValue('subCompany', subCompany);
    }

    loadRemainingAdvance = async (value) => {
        const { dispatch } = this.props;
        let amount = 0;
        if (isObjectValidAndNotEmpty(value)) {
            try {
                const response = axios.get(`${API.SALARYADVANCE.GET_RESIDUE}?employeeUuid=${value.key}&type=PETTY`);
                amount = NumberOf(response.data);
            } catch (error) {
                apiCatchBlockFunction(error, dispatch);
            }
        }
        this.setState({
            amount,
        });
    };

    validateEmployee = (selected) => {
        const isRequired = required(selected);
        if (isRequired) {
            return isRequired;
        }
        const subCompany = getStringFromObject('subCompany', this.props.values);
        if (!subCompany) {
            return 'Sub Company is not set';
        }
        return undefined;
    };

    fetchJournalEntriesAndPrint = () => {
        const { dispatch, values } = this.props;
        const { journalEntryPrintData } = this.state;
        const accountMoveUuid = getStringFromObject('accountMoveUuid', values);
        if (accountMoveUuid) {
            if (!isObjectValidAndNotEmpty(journalEntryPrintData)) {
                // not fetched.. fetch it first before proceeding
                dispatch(commonGetApiRequest(
                    `${API.ACCOUNT_MOVES.GET_ONE}${accountMoveUuid}`,
                    {
                        successCallback: (res) => {
                            this.setState(p => ({ journalEntryPrintData: res, printJournal: !p.printJournal }));
                        },
                    },
                ));
            } else {
                this.setState(p => ({ printJournal: !p.printJournal }));
            }
        }
    };

    handleEdit = () => {
        const { setFieldValue, values } = this.props;
        setFieldValue('editable', true);
        this.loadRemainingAdvance(getStringFromObject('employee', values));
        this.setState({
            journalEntryPrintData: null,
        });
    };

    handlePrint = () => {
        this.setState(prev => ({ print: !prev.print }));
    };

    reInitializeFormAndFocusUserField = () => {
        console.log('this is new');
        this.props.dispatch(clearSelectedPettyCash());
    };


    handleEmployeeSelect = (value) => {
        this.loadRemainingAdvance(value);
        this.props.setFieldValue('fromAccount', null);
    };

    handleFromAccountSelect = (value) => {
        this.props.setFieldValue('paymentMode', null);
        const filtered = PAYMENT_METHODS.filter(entry => Object.values(entry).some(val => typeof val === 'string' && val.includes(value.userType)));
        console.log('Filtered', filtered);
        if (filtered.length !== 0) {
            this.props.setFieldValue('paymentMode', { text: filtered[0].value, value: filtered[0].value });
        }
    };

    render() {
        const {
            open,
            handleClose,
            classes,
            submitForm,
            currency,
            values,
            company,
        } = this.props;
        const {
            amount,
            print,
            journalEntryPrintData,
            printJournal,
        } = this.state;
        const name = getStringFromObject('number', values);
        const subCompany = getStringFromObject('subCompany', values);
        const editable = getStringFromObject('editable', values);
        const issued = getStringFromObject('state', values) === 'issued';
        // eslint-disable-next-line max-len
        const shouldAllowEdit = !getStringFromObject('reconciled', values) && !getStringFromObject('periodClosed', values);
        console.log('jklsdfhakjsdlh', this.props);

        return (
            <Dialog
                TransitionComponent={Transition}
                open={open}
                maxWidth="md"
                scroll="body"
                aria-labelledby="form-dialog-title"
                className={classes.dialog}
                classes={{
                    paper: classes.paper,
                }}
            >
                <DialogTitle disableTypography className={classes.title}>
                    <Grid container justify="space-between">
                        <div>Petty Cash &nbsp;{name ? `(${name})` : ''}</div>
                        <Close className="cursor-pointer" onClick={handleClose} test-id="petty-cash-close" />
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <form>
                        <Grid container spacing={16} className="mt-1" justify="space-between">
                            <Grid item lg={3} md={4} sm={5}>
                                <Field
                                    testId="date"
                                    name="date"
                                    component={DateInput}
                                    label="Date"
                                    required
                                    disabled={!editable}
                                    validate={required}
                                />
                            </Grid>
                            <Grid item lg={7} md={5} sm={5} justify="center">
                                <Grid container>
                                    {
                                        editable &&
                                        <Grid item>
                                            <div style={{ marginLeft: '75px' }}>
                                                Previous OutStanding:
                                            </div>
                                            <div style={{ fontWeight: 'bold', fontSize: '20px', marginLeft: '75px' }}>
                                                {currency}&nbsp;
                                                {
                                                    formatCurrency(roundedValueFixedToTwoDigits(amount))
                                                }
                                            </div>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item lg={2} md={3} sm={3} >
                                {
                                    !editable && shouldAllowEdit &&
                                    <Grid container justify="flex-end">
                                        <ActionButton
                                            test-id="edit-petty-cash"
                                            disableRipple
                                            primary
                                            onClick={this.handleEdit}
                                        >
                                            Edit
                                        </ActionButton>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid container className="mt-1" spacing={16} justify="space-between">
                            <Grid item lg={5} md={5} sm={5}>
                                <Field
                                    testId="employee"
                                    name="employee"
                                    component={FormikReactSelectMaterial}
                                    dataSourceConfig={{
                                        text: 'value',
                                        value: 'key',
                                    }}
                                    label="Employee name"
                                    placeholder="Choose an Employee"
                                    autocomplete
                                    validate={this.validateEmployee}
                                    required
                                    isDisabled={!editable}
                                    onSelectHandlers={['handleEmployeeSelect']}
                                    actionHandlers={{
                                        handleEmployeeSelect: this.handleEmployeeSelect,
                                    }}
                                    dataSourceApi={`${API.SALARYADVANCE.GET_EMPLOYEE}?name=`}
                                />
                            </Grid>
                            <Grid item lg={5} md={5} sm={5}>
                                <Field
                                    testId="fromaccount"
                                    name="fromAccount"
                                    component={FormikReactSelectMaterial}
                                    label="From Account"
                                    dataSourceConfig={{
                                        text: 'value',
                                        value: 'key',
                                    }}
                                    required
                                    autocomplete
                                    validate={required}
                                    key={subCompany}
                                    isDisabled={!editable || !subCompany}
                                    dataSourceApi={
                                        // eslint-disable-next-line max-len
                                        `${API.SEARCH.ACCOUNTS}?subCompany=${subCompany || ''}&type=${getAllowedDefaultAccountTypes('pettyCashFromAccountTypes', this.props.allowedAccountTypes)}&searchString=`
                                    }
                                    onSelectHandlers={['handleFromAccountSelect']}
                                    actionHandlers={{
                                        handleFromAccountSelect: this.handleFromAccountSelect,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className="mt-1" spacing={16} justify="center">
                            <Grid item lg={3} md={5} sm={5}>
                                <Field
                                    testId="paymentMethod"
                                    name="paymentMode"
                                    component={FormikReactSelectMaterial}
                                    dataSourceConfig={{
                                        text: 'value',
                                        value: 'value',
                                    }}
                                    label="Payment Method"
                                    placeholder="Payment Method"
                                    validate={required}
                                    isDisabled={!editable || !subCompany}
                                    options={PAYMENT_METHODS}
                                />
                            </Grid>
                            <Grid item lg={5} md={5} sm={5}>
                                <Field
                                    testId="totalaccount"
                                    name="totalAmount"
                                    component={FormikTextField}
                                    label="Amount"
                                    type="number"
                                    validate={minValue(1)}
                                    required
                                    disabled={!editable}
                                />
                            </Grid>
                            <Grid container className="mt-1" spacing={16} justify="center">
                                <Grid item lg={5} sm={5} md={5}>
                                    <Field
                                        testId="description"
                                        name="description"
                                        component={FormikTextField}
                                        label="Description"
                                        multiline
                                        fullWidth
                                        rows={5}
                                        rowsMax={5}
                                        required
                                        disabled={!editable}
                                        validate={required}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Print
                            print={print}
                            data={{
                                ...values,
                                company,
                                title: 'Petty Cash',
                            }}
                            url={`${APPLICATION_CONFIG_URL}/HtmlPrint/EmployeePrintouts/AdvanceSalaryPrint.html`}
                            subCompany={subCompany}
                        />
                        <Print
                            url={`${APPLICATION_CONFIG_URL}/HtmlPrint/JournalPrint/JournalPrint.html`}
                            data={journalEntryPrintData}
                            print={printJournal}
                            subCompany={subCompany}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <ActionButton
                        test-id="cancel-petty-cash"
                        disableRipple
                        primary={false}
                        onClick={handleClose}
                    >
                        Cancel
                    </ActionButton>
                    {
                        editable &&
                        <ActionButton
                            testId="save-petty-cash"
                            disableRipple
                            primary
                            onClick={submitForm}
                        >
                            Confirm
                        </ActionButton>
                    }
                    {
                        !editable && issued &&
                        <ActionButton
                            onClick={this.handlePrint}
                        >
                            Print
                        </ActionButton>
                    }
                    {
                        !editable && issued &&
                        <ActionButton
                            onClick={this.fetchJournalEntriesAndPrint}
                        >
                            Print Journal Entries
                        </ActionButton>
                    }
                    {
                       !editable && issued
                       && (
                           <ActionButton
                               onClick={this.reInitializeFormAndFocusUserField}
                           >
                               Create New
                           </ActionButton>
                       )
                   }
                </DialogActions>
            </Dialog>
        );
    }
}

const getInitialValues = (props) => {
    if (isObjectValidAndNotEmpty(props.selectedAdvance)) {
        const editable = !getStringFromObject('state', props.selectedAdvance) === 'issued';
        const amount = NumberOf(getStringFromObject('totalAmount', props.selectedAdvance));
        const amountResidual = NumberOf(getStringFromObject('amountResidual', props.selectedAdvance));
        return ({
            ...props.selectedAdvance,
            reconciled: amountResidual < amount,
            editable,
        });
    }
    return ({ date: new Date(), editable: true });
};

const handleSubmit = (formValues, { props, ...formikBag }) => {
    formikBag.setSubmitting(true);
    const { dispatch } = props;
    dispatch(createPettyCash(
        { ...formValues, type: 'petty', state: 'ISSUED' },
        () => formikBag.setSubmitting(false),
        () => formikBag.setSubmitting(false),
    ));
};

PettyCashDialog.propTypes = {
    advanceId: PropTypes.number,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    currency: PropTypes.string.isRequired,
    company: PropTypes.string,
    values: PropTypes.object,
    setFieldValue: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    allowedAccountTypes: PropTypes.object,
};

PettyCashDialog.defaultProps = {
    advanceId: null,
    company: null,
    values: {},
    allowedAccountTypes: {},
};

const mapStateToProps = state => ({
    currency: getStringFromObject('appConfiguration.currency', state),
    company: getStringFromObject('appConfiguration.companyName', state),
    allowedAccountTypes: getStringFromObject('appConfiguration.allowedAccountTypes', state),
    selectedAdvance: getStringFromObject('selected', state.createPettyCashReducer),
});

export default connect(mapStateToProps)(withFormik({
    mapPropsToValues: props => cloneDeep(getInitialValues(props)),
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: false,
    displayName: formName,
    handleSubmit,
})(withStyles(style)(PettyCashDialog)));
